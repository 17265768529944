import GroupList from './GroupList';
import GroupEdit from './GroupEdit';
import { Group } from '@material-ui/icons';
import GroupCreate from './GroupCreate';

export default {
  list: GroupList,
  edit: GroupEdit,
  create: GroupCreate,
  icon: Group,
};
