/**
 * https://www.pinterest.jp/pin/854065516829060473/visual-search/?x=16&y=16&w=530&h=530
 *
 */

export const darkTheme = {
  palette: {
    primary: {
      main: '#589507',
    },
    type: 'dark', // Switching the dark mode on is a single property value change.
  },
};

export const lightTheme = {
  palette: {
    primary: {
      main: '#589507',
    },
    secondary: {
      light: '#b7db25',
      main: '#589507',
      dark: '#0a2004',
      contrastText: '#eef4f6',
    },
    background: {
      default: '#fcfcfe',
    },
  },
  shape: {
    borderRadius: 10,
  },
  overrides: {
    RaMenuItemLink: {
      root: {
        borderLeft: '3px solid #fff',
      },
      active: {
        borderLeft: '3px solid #b7db25',
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'none',
      },
      root: {
        border: '1px solid #e0e0e3',
        backgroundClip: 'padding-box',
      },
    },
    MuiButton: {
      contained: {
        backgroundColor: '#fff',
        color: '#396f04',
        boxShadow: 'none',
      },
    },
    MuiAppBar: {
      colorSecondary: {
        color: '#808080',
        backgroundColor: '#fff',
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: '#f5f5f5',
      },
      barColorPrimary: {
        backgroundColor: '#d7d7d7',
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        '&$disabled': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      },
    },
  },
};
