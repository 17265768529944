import * as React from 'react';
import { FieldProps } from 'react-admin';
import { Link } from '@material-ui/core';
import { FC } from 'react';

interface LinkFieldProps extends FieldProps {
  href: string;
  text: string;
  newTab: boolean;
}

export const LinkField: FC<LinkFieldProps> = ({ href, text, newTab }) => {
  if (newTab) {
    return (
      <Link href={href} target="_blank">
        {text}
      </Link>
    );
  }
  return <Link href={href}>{text}</Link>;
};
