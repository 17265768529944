import * as React from 'react';
import {
  Edit,
  SimpleForm,
  TextField,
  TextInput,
  DateField,
  useShowController,
  SelectArrayInput,
  ReferenceArrayInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const GroupEdit = (props: any) => {
  const { record } = useShowController<any>(props);
  const classes = useStyles();
  if (!record) return null;
  if (!Array.isArray(record.ref_users)) return null;
  const userIds = record.ref_users.map((o) => o.user_id);
  // console.info(`user: ${userIds}`);
  return (
    <Edit {...props} className={classes.root}>
      <SimpleForm>
        <TextField source="id" />
        <TextInput source="display_name" />
        <TextField source="plan" />
        <DateField label="Created date" source="createdAt" />
        <ReferenceArrayInput
          label="Users"
          source="ref_add_users"
          reference="users"
          fieldkey="id">
          <SelectArrayInput
            optionText="id"
            allowEmpty
            emptyvalue={[]}
            defaultValue={userIds}
          />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default GroupEdit;

const useStyles = makeStyles({
  root: { width: 600, margin: 'auto' },
  spacer: { height: 20 },
});
