import * as React from 'react';
import {
  Create,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  required,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const GroupCreate = (props: any) => {
  const classes = useStyles();
  return (
    <Create {...props} className={classes.root}>
      <SimpleForm>
        {/*TODO 入力バリデーション*/}
        <TextInput source="id" validate={required()} />
        <TextInput source="display_name" />
        <ReferenceArrayInput label="Users" source="ref_users" reference="users">
          <SelectArrayInput optionText="username" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};

export default GroupCreate;

const useStyles = makeStyles({
  root: { width: 600, margin: 'auto' },
  spacer: { height: 20 },
});
