import * as React from 'react';
import { useState, useEffect } from 'react';
import { FieldProps } from 'react-admin';
import { FC } from 'react';
import { getGroupImages, getUserImage } from '../dataProvider/customRest';

// @ts-ignore
export const MultiPhotoField: FC<FieldProps> = ({
  record,
  resource,
  // @ts-ignore
  groupId,
  // @ts-ignore
  reload,
  // @ts-ignore
  userId,
}) => {
  const [images, setImages] = useState({});
  useEffect(() => {
    if (!record) return;
    if (resource === 'users') {
      const referenceKey = `images/thumbnail/users/${userId}`;
      getUserImage({ referenceKey }).then((res) => {
        // @ts-ignore
        setImages(res);
      });
    } else {
      const referenceKey = `images/thumbnail/groups/${groupId}/${resource}/${record.id}`;
      getGroupImages({ referenceKey }).then((res) => {
        // @ts-ignore
        setImages(res);
      });
    }
  }, [record, reload]);
  if (!record) return null;
  const imageList = [];
  for (const k of Object.keys(images)) {
    let ext = k.split('.').pop();
    ext = ext === 'svg' ? 'svg+xml' : ext;
    const filename = k.split('/').pop();
    imageList.push(
      // @ts-ignore
      <li key={k}>
        <p>{filename}</p>
        {/* @ts-ignore */}
        <div>
          <img
            src={`data:image/${ext};base64,${images[k]}`}
            alt="image"
            width="auto"
            height="128px"
            max-width="64px"
            object-fit="contain"
          />
        </div>
      </li>
    );
  }
  return <div>{imageList.length > 0 && <ul>{imageList}</ul>}</div>;
};
