import { TextInput } from 'react-admin';
import React from 'react';

export const PrefixTextInput = (props: any) => {
  const l = props.prefix.length;
  const parse = (v) => props.prefix + v;
  const format = (v) => (v ? v.substr(l) : null);
  return (
    <span>
      <span>{props.prefix}</span>
      &nbsp;
      <TextInput {...props} parse={parse} format={format} />
    </span>
  );
};
