/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateGroupInput = {
  id?: string | null,
  display_name: string,
  owner_user_id: string,
  plan: Plan,
};

export enum Plan {
  FREE = "FREE",
  BASIC = "BASIC",
  PREMIUM = "PREMIUM",
  DISABLED = "DISABLED",
}


export type ModelGroupConditionInput = {
  display_name?: ModelStringInput | null,
  plan?: ModelPlanInput | null,
  and?: Array< ModelGroupConditionInput | null > | null,
  or?: Array< ModelGroupConditionInput | null > | null,
  not?: ModelGroupConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelPlanInput = {
  eq?: Plan | null,
  ne?: Plan | null,
};

export type Group = {
  __typename: "Group",
  id?: string,
  display_name?: string,
  owner_user_id?: string,
  ref_owner?: User,
  plan?: Plan,
  ref_users?: ModelUserGroupConnection,
  createdAt?: string,
  updatedAt?: string,
};

export type User = {
  __typename: "User",
  id?: string,
  line_user_id?: string,
  email?: string,
  username?: string,
  sub?: string,
  ref_line_user?: LineUser,
  nickname?: string | null,
  profile?: string | null,
  image_key?: string | null,
  stripe_customer_id?: string | null,
  last4?: string | null,
  active_subscription_items?: Array< string | null > | null,
  ref_groups?: ModelUserGroupConnection,
  createdAt?: string,
  updatedAt?: string,
};

export type LineUser = {
  __typename: "LineUser",
  id?: string,
  enabled?: boolean,
  line_user_name?: string | null,
  profile?: string | null,
  createdAt?: string,
  updatedAt?: string,
};

export type ModelUserGroupConnection = {
  __typename: "ModelUserGroupConnection",
  items?:  Array<UserGroup | null >,
  nextToken?: string | null,
};

export type UserGroup = {
  __typename: "UserGroup",
  id?: string,
  group_id?: string,
  user_id?: string,
  ref_user?: User,
  ref_group?: Group,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateGroupInput = {
  id: string,
  display_name?: string | null,
  owner_user_id?: string | null,
  plan?: Plan | null,
};

export type DeleteGroupInput = {
  id: string,
};

export type CreateUserInput = {
  id?: string | null,
  line_user_id: string,
  email: string,
  username: string,
  sub: string,
  nickname?: string | null,
  profile?: string | null,
  image_key?: string | null,
  stripe_customer_id?: string | null,
  last4?: string | null,
  active_subscription_items?: Array< string | null > | null,
};

export type ModelUserConditionInput = {
  line_user_id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  username?: ModelStringInput | null,
  sub?: ModelStringInput | null,
  nickname?: ModelStringInput | null,
  profile?: ModelStringInput | null,
  image_key?: ModelStringInput | null,
  stripe_customer_id?: ModelStringInput | null,
  last4?: ModelStringInput | null,
  active_subscription_items?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type UpdateUserInput = {
  id: string,
  line_user_id?: string | null,
  email?: string | null,
  username?: string | null,
  sub?: string | null,
  nickname?: string | null,
  profile?: string | null,
  image_key?: string | null,
  stripe_customer_id?: string | null,
  last4?: string | null,
  active_subscription_items?: Array< string | null > | null,
};

export type DeleteUserInput = {
  id: string,
};

export type CreateUserGroupInput = {
  id?: string | null,
  group_id: string,
  user_id: string,
};

export type ModelUserGroupConditionInput = {
  and?: Array< ModelUserGroupConditionInput | null > | null,
  or?: Array< ModelUserGroupConditionInput | null > | null,
  not?: ModelUserGroupConditionInput | null,
};

export type UpdateUserGroupInput = {
  id: string,
  group_id?: string | null,
  user_id?: string | null,
};

export type DeleteUserGroupInput = {
  id: string,
};

export type CreateLineUserInput = {
  id?: string | null,
  enabled: boolean,
  line_user_name?: string | null,
  profile?: string | null,
};

export type ModelLineUserConditionInput = {
  enabled?: ModelBooleanInput | null,
  line_user_name?: ModelStringInput | null,
  profile?: ModelStringInput | null,
  and?: Array< ModelLineUserConditionInput | null > | null,
  or?: Array< ModelLineUserConditionInput | null > | null,
  not?: ModelLineUserConditionInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateLineUserInput = {
  id: string,
  enabled?: boolean | null,
  line_user_name?: string | null,
  profile?: string | null,
};

export type DeleteLineUserInput = {
  id: string,
};

export type CreateGrowingInput = {
  id?: string | null,
  lifeform: string,
  family: string,
  species: string,
  name_ja?: string | null,
  family_ja?: string | null,
  link_ja?: string | null,
  name_en?: string | null,
  link_en?: string | null,
  description?: string | null,
};

export type ModelGrowingConditionInput = {
  lifeform?: ModelStringInput | null,
  family?: ModelStringInput | null,
  species?: ModelStringInput | null,
  name_ja?: ModelStringInput | null,
  family_ja?: ModelStringInput | null,
  link_ja?: ModelStringInput | null,
  name_en?: ModelStringInput | null,
  link_en?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelGrowingConditionInput | null > | null,
  or?: Array< ModelGrowingConditionInput | null > | null,
  not?: ModelGrowingConditionInput | null,
};

export type Growing = {
  __typename: "Growing",
  id?: string,
  lifeform?: string,
  family?: string,
  species?: string,
  name_ja?: string | null,
  family_ja?: string | null,
  link_ja?: string | null,
  name_en?: string | null,
  link_en?: string | null,
  description?: string | null,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateGrowingInput = {
  id: string,
  lifeform?: string | null,
  family?: string | null,
  species?: string | null,
  name_ja?: string | null,
  family_ja?: string | null,
  link_ja?: string | null,
  name_en?: string | null,
  link_en?: string | null,
  description?: string | null,
};

export type DeleteGrowingInput = {
  id: string,
};

export type CreateFieldNoteInput = {
  group_id: string,
  id?: string | null,
  note_date_time?: string | null,
  note?: string | null,
  image_key?: string | null,
  cultivation_id: string,
  field_work_id: string,
};

export type ModelFieldNoteConditionInput = {
  note_date_time?: ModelStringInput | null,
  note?: ModelStringInput | null,
  image_key?: ModelStringInput | null,
  cultivation_id?: ModelIDInput | null,
  field_work_id?: ModelIDInput | null,
  and?: Array< ModelFieldNoteConditionInput | null > | null,
  or?: Array< ModelFieldNoteConditionInput | null > | null,
  not?: ModelFieldNoteConditionInput | null,
};

export type FieldNote = {
  __typename: "FieldNote",
  group_id?: string,
  id?: string,
  note_date_time?: string | null,
  note?: string | null,
  image_key?: string | null,
  cultivation_id?: string,
  ref_cultivation?: Cultivation,
  field_work_id?: string,
  ref_field_work?: FieldWork,
  createdAt?: string,
  updatedAt?: string,
};

export type Cultivation = {
  __typename: "Cultivation",
  group_id?: string,
  ref_group?: Group,
  id?: string,
  description?: string | null,
  start_date?: string | null,
  growing_id?: string,
  ref_growing?: Growing,
  ref_waterings?: ModelWateringConnection,
  ref_field_works?: ModelFieldWorkConnection,
  ref_datasets?: ModelDatasetConnection,
  ref_field_notes?: ModelFieldNoteConnection,
  createdAt?: string,
  updatedAt?: string,
};

export type ModelWateringConnection = {
  __typename: "ModelWateringConnection",
  items?:  Array<Watering | null >,
  nextToken?: string | null,
};

export type Watering = {
  __typename: "Watering",
  group_id?: string,
  id?: string,
  display_name?: string | null,
  watering_type?: WateringType | null,
  enabled?: boolean,
  span_min?: number | null,
  span_max?: number | null,
  period_start_time?: string | null,
  period_end_time?: string | null,
  fixed_span?: number | null,
  every?: number | null,
  target_metric?: string | null,
  target_value?: number | null,
  kp?: number | null,
  ki?: number | null,
  kd?: number | null,
  _last_time?: number | null,
  _last_output?: number | null,
  _last_input?: number | null,
  cultivation_id?: string,
  ref_cultivation?: Cultivation,
  ref_devices?: ModelDeviceWateringConnection,
  createdAt?: string,
  updatedAt?: string,
};

export enum WateringType {
  FIXED = "FIXED",
  PID = "PID",
}


export type ModelDeviceWateringConnection = {
  __typename: "ModelDeviceWateringConnection",
  items?:  Array<DeviceWatering | null >,
  nextToken?: string | null,
};

export type DeviceWatering = {
  __typename: "DeviceWatering",
  group_id?: string,
  device_id?: string,
  watering_id?: string,
  ref_device?: Device,
  createdAt?: string,
  updatedAt?: string,
};

export type Device = {
  __typename: "Device",
  group_id?: string,
  id?: string,
  display_name?: string | null,
  device_type?: DeviceType | null,
  description?: string | null,
  enabled?: boolean,
  certificate?: string | null,
  private_key?: string | null,
  endpoint?: string | null,
  createdAt?: string,
  updatedAt?: string,
};

export enum DeviceType {
  ARDUINO = "ARDUINO",
  PYTHON = "PYTHON",
}


export type ModelFieldWorkConnection = {
  __typename: "ModelFieldWorkConnection",
  items?:  Array<FieldWork | null >,
  nextToken?: string | null,
};

export type FieldWork = {
  __typename: "FieldWork",
  group_id?: string,
  id?: string,
  work_type?: WorkType,
  display_name?: string | null,
  cultivation_id?: string,
  ref_cultivation?: Cultivation,
  plan_date_start?: string | null,
  plan_date_end?: string | null,
  plan_description?: string | null,
  amount?: number | null,
  unit?: Unit | null,
  ref_field_notes?: ModelFieldNoteConnection,
  createdAt?: string,
  updatedAt?: string,
};

export enum WorkType {
  ORGANIC_FERTILIZATION = "ORGANIC_FERTILIZATION",
  LIME_FERTILIZATION = "LIME_FERTILIZATION",
  BASAL_DRESSING = "BASAL_DRESSING",
  SOWING = "SOWING",
  TRANSPLANT = "TRANSPLANT",
  TOP_DRESSING = "TOP_DRESSING",
  HARVEST = "HARVEST",
  OBSERVATION = "OBSERVATION",
  OTHERS = "OTHERS",
}


export enum Unit {
  COUNT = "COUNT",
  GRAM = "GRAM",
  KILOGRAM = "KILOGRAM",
}


export type ModelFieldNoteConnection = {
  __typename: "ModelFieldNoteConnection",
  items?:  Array<FieldNote | null >,
  nextToken?: string | null,
};

export type ModelDatasetConnection = {
  __typename: "ModelDatasetConnection",
  items?:  Array<Dataset | null >,
  nextToken?: string | null,
};

export type Dataset = {
  __typename: "Dataset",
  group_id?: string,
  id?: string,
  data_type?: DataType | null,
  timezone?: string | null,
  column_map?:  Array<ColumnMap | null > | null,
  description?: string | null,
  cultivation_id?: string,
  ref_cultivation?: Cultivation,
  ref_devices?: ModelDeviceDatasetConnection,
  createdAt?: string,
  updatedAt?: string,
};

export enum DataType {
  SENSOR_DATA_TYPE_1 = "SENSOR_DATA_TYPE_1",
  SENSOR_DATA_ENV_2 = "SENSOR_DATA_ENV_2",
  IMAGE_TYPE_1 = "IMAGE_TYPE_1",
}


export type ColumnMap = {
  __typename: "ColumnMap",
  key?: string,
  name?: string,
};

export type ModelDeviceDatasetConnection = {
  __typename: "ModelDeviceDatasetConnection",
  items?:  Array<DeviceDataset | null >,
  nextToken?: string | null,
};

export type DeviceDataset = {
  __typename: "DeviceDataset",
  group_id?: string,
  device_id?: string,
  dataset_id?: string,
  ref_device?: Device,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateFieldNoteInput = {
  group_id: string,
  id: string,
  note_date_time?: string | null,
  note?: string | null,
  image_key?: string | null,
  cultivation_id?: string | null,
  field_work_id?: string | null,
};

export type DeleteFieldNoteInput = {
  group_id: string,
  id: string,
};

export type CreateFieldWorkInput = {
  group_id: string,
  id?: string | null,
  work_type: WorkType,
  display_name?: string | null,
  cultivation_id: string,
  plan_date_start?: string | null,
  plan_date_end?: string | null,
  plan_description?: string | null,
  amount?: number | null,
  unit?: Unit | null,
};

export type ModelFieldWorkConditionInput = {
  work_type?: ModelWorkTypeInput | null,
  display_name?: ModelStringInput | null,
  cultivation_id?: ModelIDInput | null,
  plan_date_start?: ModelStringInput | null,
  plan_date_end?: ModelStringInput | null,
  plan_description?: ModelStringInput | null,
  amount?: ModelFloatInput | null,
  unit?: ModelUnitInput | null,
  and?: Array< ModelFieldWorkConditionInput | null > | null,
  or?: Array< ModelFieldWorkConditionInput | null > | null,
  not?: ModelFieldWorkConditionInput | null,
};

export type ModelWorkTypeInput = {
  eq?: WorkType | null,
  ne?: WorkType | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelUnitInput = {
  eq?: Unit | null,
  ne?: Unit | null,
};

export type UpdateFieldWorkInput = {
  group_id: string,
  id: string,
  work_type?: WorkType | null,
  display_name?: string | null,
  cultivation_id?: string | null,
  plan_date_start?: string | null,
  plan_date_end?: string | null,
  plan_description?: string | null,
  amount?: number | null,
  unit?: Unit | null,
};

export type DeleteFieldWorkInput = {
  group_id: string,
  id: string,
};

export type CreateCultivationInput = {
  group_id: string,
  id?: string | null,
  description?: string | null,
  start_date?: string | null,
  growing_id: string,
};

export type ModelCultivationConditionInput = {
  description?: ModelStringInput | null,
  start_date?: ModelStringInput | null,
  growing_id?: ModelIDInput | null,
  and?: Array< ModelCultivationConditionInput | null > | null,
  or?: Array< ModelCultivationConditionInput | null > | null,
  not?: ModelCultivationConditionInput | null,
};

export type UpdateCultivationInput = {
  group_id: string,
  id: string,
  description?: string | null,
  start_date?: string | null,
  growing_id?: string | null,
};

export type DeleteCultivationInput = {
  group_id: string,
  id: string,
};

export type CreateWateringInput = {
  group_id: string,
  id?: string | null,
  display_name?: string | null,
  watering_type?: WateringType | null,
  enabled: boolean,
  span_min?: number | null,
  span_max?: number | null,
  period_start_time?: string | null,
  period_end_time?: string | null,
  fixed_span?: number | null,
  every?: number | null,
  target_metric?: string | null,
  target_value?: number | null,
  kp?: number | null,
  ki?: number | null,
  kd?: number | null,
  _last_time?: number | null,
  _last_output?: number | null,
  _last_input?: number | null,
  cultivation_id: string,
};

export type ModelWateringConditionInput = {
  display_name?: ModelStringInput | null,
  watering_type?: ModelWateringTypeInput | null,
  enabled?: ModelBooleanInput | null,
  span_min?: ModelIntInput | null,
  span_max?: ModelIntInput | null,
  period_start_time?: ModelStringInput | null,
  period_end_time?: ModelStringInput | null,
  fixed_span?: ModelIntInput | null,
  every?: ModelIntInput | null,
  target_metric?: ModelStringInput | null,
  target_value?: ModelFloatInput | null,
  kp?: ModelFloatInput | null,
  ki?: ModelFloatInput | null,
  kd?: ModelFloatInput | null,
  _last_time?: ModelFloatInput | null,
  _last_output?: ModelFloatInput | null,
  _last_input?: ModelFloatInput | null,
  cultivation_id?: ModelIDInput | null,
  and?: Array< ModelWateringConditionInput | null > | null,
  or?: Array< ModelWateringConditionInput | null > | null,
  not?: ModelWateringConditionInput | null,
};

export type ModelWateringTypeInput = {
  eq?: WateringType | null,
  ne?: WateringType | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateWateringInput = {
  group_id: string,
  id: string,
  display_name?: string | null,
  watering_type?: WateringType | null,
  enabled?: boolean | null,
  span_min?: number | null,
  span_max?: number | null,
  period_start_time?: string | null,
  period_end_time?: string | null,
  fixed_span?: number | null,
  every?: number | null,
  target_metric?: string | null,
  target_value?: number | null,
  kp?: number | null,
  ki?: number | null,
  kd?: number | null,
  _last_time?: number | null,
  _last_output?: number | null,
  _last_input?: number | null,
  cultivation_id?: string | null,
};

export type DeleteWateringInput = {
  group_id: string,
  id: string,
};

export type CreateDatasetInput = {
  group_id: string,
  id?: string | null,
  data_type?: DataType | null,
  timezone?: string | null,
  column_map?: Array< ColumnMapInput | null > | null,
  description?: string | null,
  cultivation_id: string,
};

export type ColumnMapInput = {
  key: string,
  name: string,
};

export type ModelDatasetConditionInput = {
  data_type?: ModelDataTypeInput | null,
  timezone?: ModelStringInput | null,
  description?: ModelStringInput | null,
  cultivation_id?: ModelIDInput | null,
  and?: Array< ModelDatasetConditionInput | null > | null,
  or?: Array< ModelDatasetConditionInput | null > | null,
  not?: ModelDatasetConditionInput | null,
};

export type ModelDataTypeInput = {
  eq?: DataType | null,
  ne?: DataType | null,
};

export type UpdateDatasetInput = {
  group_id: string,
  id: string,
  data_type?: DataType | null,
  timezone?: string | null,
  column_map?: Array< ColumnMapInput | null > | null,
  description?: string | null,
  cultivation_id?: string | null,
};

export type DeleteDatasetInput = {
  group_id: string,
  id: string,
};

export type CreateDeviceInput = {
  group_id: string,
  id?: string | null,
  display_name?: string | null,
  device_type?: DeviceType | null,
  description?: string | null,
  enabled: boolean,
  certificate?: string | null,
  private_key?: string | null,
  endpoint?: string | null,
};

export type ModelDeviceConditionInput = {
  display_name?: ModelStringInput | null,
  device_type?: ModelDeviceTypeInput | null,
  description?: ModelStringInput | null,
  enabled?: ModelBooleanInput | null,
  certificate?: ModelStringInput | null,
  private_key?: ModelStringInput | null,
  endpoint?: ModelStringInput | null,
  and?: Array< ModelDeviceConditionInput | null > | null,
  or?: Array< ModelDeviceConditionInput | null > | null,
  not?: ModelDeviceConditionInput | null,
};

export type ModelDeviceTypeInput = {
  eq?: DeviceType | null,
  ne?: DeviceType | null,
};

export type UpdateDeviceInput = {
  group_id: string,
  id: string,
  display_name?: string | null,
  device_type?: DeviceType | null,
  description?: string | null,
  enabled?: boolean | null,
  certificate?: string | null,
  private_key?: string | null,
  endpoint?: string | null,
};

export type DeleteDeviceInput = {
  group_id: string,
  id: string,
};

export type CreateDeviceDatasetInput = {
  group_id: string,
  device_id: string,
  dataset_id: string,
};

export type ModelDeviceDatasetConditionInput = {
  and?: Array< ModelDeviceDatasetConditionInput | null > | null,
  or?: Array< ModelDeviceDatasetConditionInput | null > | null,
  not?: ModelDeviceDatasetConditionInput | null,
};

export type UpdateDeviceDatasetInput = {
  group_id: string,
  device_id: string,
  dataset_id: string,
};

export type DeleteDeviceDatasetInput = {
  group_id: string,
  device_id: string,
  dataset_id: string,
};

export type CreateDeviceWateringInput = {
  group_id: string,
  device_id: string,
  watering_id: string,
};

export type ModelDeviceWateringConditionInput = {
  and?: Array< ModelDeviceWateringConditionInput | null > | null,
  or?: Array< ModelDeviceWateringConditionInput | null > | null,
  not?: ModelDeviceWateringConditionInput | null,
};

export type UpdateDeviceWateringInput = {
  group_id: string,
  device_id: string,
  watering_id: string,
};

export type DeleteDeviceWateringInput = {
  group_id: string,
  device_id: string,
  watering_id: string,
};

export type CreateSensorDataInput = {
  group_id: string,
  dataset_id: string,
  keytime: number,
  ttl: number,
  year: number,
  month: number,
  date: string,
  hour: number,
  datetime: string,
  v0?: number | null,
  v1?: number | null,
  v2?: number | null,
  v3?: number | null,
  v4?: number | null,
  v5?: number | null,
  v6?: number | null,
  v7?: number | null,
  v8?: number | null,
  v9?: number | null,
};

export type ModelSensorDataConditionInput = {
  ttl?: ModelIntInput | null,
  year?: ModelIntInput | null,
  month?: ModelIntInput | null,
  date?: ModelStringInput | null,
  hour?: ModelIntInput | null,
  datetime?: ModelStringInput | null,
  v0?: ModelFloatInput | null,
  v1?: ModelFloatInput | null,
  v2?: ModelFloatInput | null,
  v3?: ModelFloatInput | null,
  v4?: ModelFloatInput | null,
  v5?: ModelFloatInput | null,
  v6?: ModelFloatInput | null,
  v7?: ModelFloatInput | null,
  v8?: ModelFloatInput | null,
  v9?: ModelFloatInput | null,
  and?: Array< ModelSensorDataConditionInput | null > | null,
  or?: Array< ModelSensorDataConditionInput | null > | null,
  not?: ModelSensorDataConditionInput | null,
};

export type SensorData = {
  __typename: "SensorData",
  group_id?: string,
  dataset_id?: string,
  keytime?: number,
  ttl?: number,
  year?: number,
  month?: number,
  date?: string,
  hour?: number,
  datetime?: string,
  v0?: number | null,
  v1?: number | null,
  v2?: number | null,
  v3?: number | null,
  v4?: number | null,
  v5?: number | null,
  v6?: number | null,
  v7?: number | null,
  v8?: number | null,
  v9?: number | null,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateSensorDataInput = {
  group_id?: string | null,
  dataset_id: string,
  keytime: number,
  ttl?: number | null,
  year?: number | null,
  month?: number | null,
  date?: string | null,
  hour?: number | null,
  datetime?: string | null,
  v0?: number | null,
  v1?: number | null,
  v2?: number | null,
  v3?: number | null,
  v4?: number | null,
  v5?: number | null,
  v6?: number | null,
  v7?: number | null,
  v8?: number | null,
  v9?: number | null,
};

export type DeleteSensorDataInput = {
  dataset_id: string,
  keytime: number,
};

export type CreateWateringHistoryInput = {
  group_id: string,
  watering_id: string,
  keytime: number,
  ttl: number,
  year: number,
  month: number,
  date: string,
  hour: number,
  datetime: string,
  span: number,
};

export type ModelWateringHistoryConditionInput = {
  ttl?: ModelIntInput | null,
  year?: ModelIntInput | null,
  month?: ModelIntInput | null,
  date?: ModelStringInput | null,
  hour?: ModelIntInput | null,
  datetime?: ModelStringInput | null,
  span?: ModelIntInput | null,
  and?: Array< ModelWateringHistoryConditionInput | null > | null,
  or?: Array< ModelWateringHistoryConditionInput | null > | null,
  not?: ModelWateringHistoryConditionInput | null,
};

export type WateringHistory = {
  __typename: "WateringHistory",
  group_id?: string,
  watering_id?: string,
  keytime?: number,
  ttl?: number,
  year?: number,
  month?: number,
  date?: string,
  hour?: number,
  datetime?: string,
  span?: number,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateWateringHistoryInput = {
  group_id?: string | null,
  watering_id: string,
  keytime: number,
  ttl?: number | null,
  year?: number | null,
  month?: number | null,
  date?: string | null,
  hour?: number | null,
  datetime?: string | null,
  span?: number | null,
};

export type DeleteWateringHistoryInput = {
  watering_id: string,
  keytime: number,
};

export type CreateControlPlaneInput = {
  group_id: string,
  id?: string | null,
  display_name: string,
  status: ControlPlaneStatus,
  ssm_activation_code?: string | null,
  ssm_activation_id?: string | null,
  info?: string | null,
};

export enum ControlPlaneStatus {
  ACTIVE = "ACTIVE",
  DISABLED = "DISABLED",
  LOGICAL_DELETE = "LOGICAL_DELETE",
}


export type ModelControlPlaneConditionInput = {
  display_name?: ModelStringInput | null,
  status?: ModelControlPlaneStatusInput | null,
  ssm_activation_code?: ModelStringInput | null,
  ssm_activation_id?: ModelStringInput | null,
  info?: ModelStringInput | null,
  and?: Array< ModelControlPlaneConditionInput | null > | null,
  or?: Array< ModelControlPlaneConditionInput | null > | null,
  not?: ModelControlPlaneConditionInput | null,
};

export type ModelControlPlaneStatusInput = {
  eq?: ControlPlaneStatus | null,
  ne?: ControlPlaneStatus | null,
};

export type ControlPlane = {
  __typename: "ControlPlane",
  group_id?: string,
  ref_group?: Group,
  id?: string,
  display_name?: string,
  status?: ControlPlaneStatus,
  ssm_activation_code?: string | null,
  ssm_activation_id?: string | null,
  info?: string | null,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateControlPlaneInput = {
  group_id: string,
  id: string,
  display_name?: string | null,
  status?: ControlPlaneStatus | null,
  ssm_activation_code?: string | null,
  ssm_activation_id?: string | null,
  info?: string | null,
};

export type DeleteControlPlaneInput = {
  group_id: string,
  id: string,
};

export type CreateUsageInput = {
  group_id: string,
  price_id: string,
  keytime: number,
  year: number,
  month: number,
  date: string,
  datetime: string,
  volume: number,
};

export type ModelUsageConditionInput = {
  year?: ModelIntInput | null,
  month?: ModelIntInput | null,
  date?: ModelStringInput | null,
  datetime?: ModelStringInput | null,
  volume?: ModelIntInput | null,
  and?: Array< ModelUsageConditionInput | null > | null,
  or?: Array< ModelUsageConditionInput | null > | null,
  not?: ModelUsageConditionInput | null,
};

export type Usage = {
  __typename: "Usage",
  group_id?: string,
  price_id?: string,
  keytime?: number,
  year?: number,
  month?: number,
  date?: string,
  datetime?: string,
  volume?: number,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateUsageInput = {
  group_id: string,
  price_id: string,
  keytime: number,
  year?: number | null,
  month?: number | null,
  date?: string | null,
  datetime?: string | null,
  volume?: number | null,
};

export type DeleteUsageInput = {
  group_id: string,
  price_id: string,
  keytime: number,
};

export type ModelGroupFilterInput = {
  id?: ModelIDInput | null,
  display_name?: ModelStringInput | null,
  owner_user_id?: ModelIDInput | null,
  plan?: ModelPlanInput | null,
  and?: Array< ModelGroupFilterInput | null > | null,
  or?: Array< ModelGroupFilterInput | null > | null,
  not?: ModelGroupFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelGroupConnection = {
  __typename: "ModelGroupConnection",
  items?:  Array<Group | null >,
  nextToken?: string | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  line_user_id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  username?: ModelStringInput | null,
  sub?: ModelStringInput | null,
  nickname?: ModelStringInput | null,
  profile?: ModelStringInput | null,
  image_key?: ModelStringInput | null,
  stripe_customer_id?: ModelStringInput | null,
  last4?: ModelStringInput | null,
  active_subscription_items?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items?:  Array<User | null >,
  nextToken?: string | null,
};

export type ModelUserGroupFilterInput = {
  id?: ModelIDInput | null,
  group_id?: ModelIDInput | null,
  user_id?: ModelIDInput | null,
  and?: Array< ModelUserGroupFilterInput | null > | null,
  or?: Array< ModelUserGroupFilterInput | null > | null,
  not?: ModelUserGroupFilterInput | null,
};

export type ModelLineUserFilterInput = {
  id?: ModelIDInput | null,
  enabled?: ModelBooleanInput | null,
  line_user_name?: ModelStringInput | null,
  profile?: ModelStringInput | null,
  and?: Array< ModelLineUserFilterInput | null > | null,
  or?: Array< ModelLineUserFilterInput | null > | null,
  not?: ModelLineUserFilterInput | null,
};

export type ModelLineUserConnection = {
  __typename: "ModelLineUserConnection",
  items?:  Array<LineUser | null >,
  nextToken?: string | null,
};

export type ModelGrowingFilterInput = {
  id?: ModelIDInput | null,
  lifeform?: ModelStringInput | null,
  family?: ModelStringInput | null,
  species?: ModelStringInput | null,
  name_ja?: ModelStringInput | null,
  family_ja?: ModelStringInput | null,
  link_ja?: ModelStringInput | null,
  name_en?: ModelStringInput | null,
  link_en?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelGrowingFilterInput | null > | null,
  or?: Array< ModelGrowingFilterInput | null > | null,
  not?: ModelGrowingFilterInput | null,
};

export type ModelGrowingConnection = {
  __typename: "ModelGrowingConnection",
  items?:  Array<Growing | null >,
  nextToken?: string | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelFieldNoteFilterInput = {
  group_id?: ModelIDInput | null,
  id?: ModelIDInput | null,
  note_date_time?: ModelStringInput | null,
  note?: ModelStringInput | null,
  image_key?: ModelStringInput | null,
  cultivation_id?: ModelIDInput | null,
  field_work_id?: ModelIDInput | null,
  and?: Array< ModelFieldNoteFilterInput | null > | null,
  or?: Array< ModelFieldNoteFilterInput | null > | null,
  not?: ModelFieldNoteFilterInput | null,
};

export type ModelFieldWorkFilterInput = {
  group_id?: ModelIDInput | null,
  id?: ModelIDInput | null,
  work_type?: ModelWorkTypeInput | null,
  display_name?: ModelStringInput | null,
  cultivation_id?: ModelIDInput | null,
  plan_date_start?: ModelStringInput | null,
  plan_date_end?: ModelStringInput | null,
  plan_description?: ModelStringInput | null,
  amount?: ModelFloatInput | null,
  unit?: ModelUnitInput | null,
  and?: Array< ModelFieldWorkFilterInput | null > | null,
  or?: Array< ModelFieldWorkFilterInput | null > | null,
  not?: ModelFieldWorkFilterInput | null,
};

export type ModelCultivationFilterInput = {
  group_id?: ModelIDInput | null,
  id?: ModelIDInput | null,
  description?: ModelStringInput | null,
  start_date?: ModelStringInput | null,
  growing_id?: ModelIDInput | null,
  and?: Array< ModelCultivationFilterInput | null > | null,
  or?: Array< ModelCultivationFilterInput | null > | null,
  not?: ModelCultivationFilterInput | null,
};

export type ModelCultivationConnection = {
  __typename: "ModelCultivationConnection",
  items?:  Array<Cultivation | null >,
  nextToken?: string | null,
};

export type ModelWateringFilterInput = {
  group_id?: ModelIDInput | null,
  id?: ModelIDInput | null,
  display_name?: ModelStringInput | null,
  watering_type?: ModelWateringTypeInput | null,
  enabled?: ModelBooleanInput | null,
  span_min?: ModelIntInput | null,
  span_max?: ModelIntInput | null,
  period_start_time?: ModelStringInput | null,
  period_end_time?: ModelStringInput | null,
  fixed_span?: ModelIntInput | null,
  every?: ModelIntInput | null,
  target_metric?: ModelStringInput | null,
  target_value?: ModelFloatInput | null,
  kp?: ModelFloatInput | null,
  ki?: ModelFloatInput | null,
  kd?: ModelFloatInput | null,
  _last_time?: ModelFloatInput | null,
  _last_output?: ModelFloatInput | null,
  _last_input?: ModelFloatInput | null,
  cultivation_id?: ModelIDInput | null,
  and?: Array< ModelWateringFilterInput | null > | null,
  or?: Array< ModelWateringFilterInput | null > | null,
  not?: ModelWateringFilterInput | null,
};

export type ModelDatasetFilterInput = {
  group_id?: ModelIDInput | null,
  id?: ModelIDInput | null,
  data_type?: ModelDataTypeInput | null,
  timezone?: ModelStringInput | null,
  description?: ModelStringInput | null,
  cultivation_id?: ModelIDInput | null,
  and?: Array< ModelDatasetFilterInput | null > | null,
  or?: Array< ModelDatasetFilterInput | null > | null,
  not?: ModelDatasetFilterInput | null,
};

export type ModelDeviceFilterInput = {
  group_id?: ModelIDInput | null,
  id?: ModelIDInput | null,
  display_name?: ModelStringInput | null,
  device_type?: ModelDeviceTypeInput | null,
  description?: ModelStringInput | null,
  enabled?: ModelBooleanInput | null,
  certificate?: ModelStringInput | null,
  private_key?: ModelStringInput | null,
  endpoint?: ModelStringInput | null,
  and?: Array< ModelDeviceFilterInput | null > | null,
  or?: Array< ModelDeviceFilterInput | null > | null,
  not?: ModelDeviceFilterInput | null,
};

export type ModelDeviceConnection = {
  __typename: "ModelDeviceConnection",
  items?:  Array<Device | null >,
  nextToken?: string | null,
};

export type ModelDeviceDatasetPrimaryCompositeKeyConditionInput = {
  eq?: ModelDeviceDatasetPrimaryCompositeKeyInput | null,
  le?: ModelDeviceDatasetPrimaryCompositeKeyInput | null,
  lt?: ModelDeviceDatasetPrimaryCompositeKeyInput | null,
  ge?: ModelDeviceDatasetPrimaryCompositeKeyInput | null,
  gt?: ModelDeviceDatasetPrimaryCompositeKeyInput | null,
  between?: Array< ModelDeviceDatasetPrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelDeviceDatasetPrimaryCompositeKeyInput | null,
};

export type ModelDeviceDatasetPrimaryCompositeKeyInput = {
  device_id?: string | null,
  dataset_id?: string | null,
};

export type ModelDeviceDatasetFilterInput = {
  group_id?: ModelIDInput | null,
  device_id?: ModelIDInput | null,
  dataset_id?: ModelIDInput | null,
  and?: Array< ModelDeviceDatasetFilterInput | null > | null,
  or?: Array< ModelDeviceDatasetFilterInput | null > | null,
  not?: ModelDeviceDatasetFilterInput | null,
};

export type ModelDeviceWateringPrimaryCompositeKeyConditionInput = {
  eq?: ModelDeviceWateringPrimaryCompositeKeyInput | null,
  le?: ModelDeviceWateringPrimaryCompositeKeyInput | null,
  lt?: ModelDeviceWateringPrimaryCompositeKeyInput | null,
  ge?: ModelDeviceWateringPrimaryCompositeKeyInput | null,
  gt?: ModelDeviceWateringPrimaryCompositeKeyInput | null,
  between?: Array< ModelDeviceWateringPrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelDeviceWateringPrimaryCompositeKeyInput | null,
};

export type ModelDeviceWateringPrimaryCompositeKeyInput = {
  device_id?: string | null,
  watering_id?: string | null,
};

export type ModelDeviceWateringFilterInput = {
  group_id?: ModelIDInput | null,
  device_id?: ModelIDInput | null,
  watering_id?: ModelIDInput | null,
  and?: Array< ModelDeviceWateringFilterInput | null > | null,
  or?: Array< ModelDeviceWateringFilterInput | null > | null,
  not?: ModelDeviceWateringFilterInput | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelSensorDataFilterInput = {
  group_id?: ModelIDInput | null,
  dataset_id?: ModelIDInput | null,
  keytime?: ModelIntInput | null,
  ttl?: ModelIntInput | null,
  year?: ModelIntInput | null,
  month?: ModelIntInput | null,
  date?: ModelStringInput | null,
  hour?: ModelIntInput | null,
  datetime?: ModelStringInput | null,
  v0?: ModelFloatInput | null,
  v1?: ModelFloatInput | null,
  v2?: ModelFloatInput | null,
  v3?: ModelFloatInput | null,
  v4?: ModelFloatInput | null,
  v5?: ModelFloatInput | null,
  v6?: ModelFloatInput | null,
  v7?: ModelFloatInput | null,
  v8?: ModelFloatInput | null,
  v9?: ModelFloatInput | null,
  and?: Array< ModelSensorDataFilterInput | null > | null,
  or?: Array< ModelSensorDataFilterInput | null > | null,
  not?: ModelSensorDataFilterInput | null,
};

export type ModelSensorDataConnection = {
  __typename: "ModelSensorDataConnection",
  items?:  Array<SensorData | null >,
  nextToken?: string | null,
};

export type ModelWateringHistoryFilterInput = {
  group_id?: ModelIDInput | null,
  watering_id?: ModelIDInput | null,
  keytime?: ModelIntInput | null,
  ttl?: ModelIntInput | null,
  year?: ModelIntInput | null,
  month?: ModelIntInput | null,
  date?: ModelStringInput | null,
  hour?: ModelIntInput | null,
  datetime?: ModelStringInput | null,
  span?: ModelIntInput | null,
  and?: Array< ModelWateringHistoryFilterInput | null > | null,
  or?: Array< ModelWateringHistoryFilterInput | null > | null,
  not?: ModelWateringHistoryFilterInput | null,
};

export type ModelWateringHistoryConnection = {
  __typename: "ModelWateringHistoryConnection",
  items?:  Array<WateringHistory | null >,
  nextToken?: string | null,
};

export type ModelControlPlaneFilterInput = {
  group_id?: ModelIDInput | null,
  id?: ModelIDInput | null,
  display_name?: ModelStringInput | null,
  status?: ModelControlPlaneStatusInput | null,
  ssm_activation_code?: ModelStringInput | null,
  ssm_activation_id?: ModelStringInput | null,
  info?: ModelStringInput | null,
  and?: Array< ModelControlPlaneFilterInput | null > | null,
  or?: Array< ModelControlPlaneFilterInput | null > | null,
  not?: ModelControlPlaneFilterInput | null,
};

export type ModelControlPlaneConnection = {
  __typename: "ModelControlPlaneConnection",
  items?:  Array<ControlPlane | null >,
  nextToken?: string | null,
};

export type ModelUsagePrimaryCompositeKeyConditionInput = {
  eq?: ModelUsagePrimaryCompositeKeyInput | null,
  le?: ModelUsagePrimaryCompositeKeyInput | null,
  lt?: ModelUsagePrimaryCompositeKeyInput | null,
  ge?: ModelUsagePrimaryCompositeKeyInput | null,
  gt?: ModelUsagePrimaryCompositeKeyInput | null,
  between?: Array< ModelUsagePrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelUsagePrimaryCompositeKeyInput | null,
};

export type ModelUsagePrimaryCompositeKeyInput = {
  price_id?: string | null,
  keytime?: number | null,
};

export type ModelUsageFilterInput = {
  group_id?: ModelIDInput | null,
  price_id?: ModelIDInput | null,
  keytime?: ModelIntInput | null,
  year?: ModelIntInput | null,
  month?: ModelIntInput | null,
  date?: ModelStringInput | null,
  datetime?: ModelStringInput | null,
  volume?: ModelIntInput | null,
  and?: Array< ModelUsageFilterInput | null > | null,
  or?: Array< ModelUsageFilterInput | null > | null,
  not?: ModelUsageFilterInput | null,
};

export type ModelUsageConnection = {
  __typename: "ModelUsageConnection",
  items?:  Array<Usage | null >,
  nextToken?: string | null,
};

export type CreateGroupMutationVariables = {
  input?: CreateGroupInput,
  condition?: ModelGroupConditionInput | null,
};

export type CreateGroupMutation = {
  createGroup?:  {
    __typename: "Group",
    id: string,
    display_name: string,
    owner_user_id: string,
    ref_owner?:  {
      __typename: "User",
      id: string,
      line_user_id: string,
      email: string,
      username: string,
      sub: string,
      ref_line_user?:  {
        __typename: "LineUser",
        id: string,
        enabled: boolean,
        line_user_name?: string | null,
        profile?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      nickname?: string | null,
      profile?: string | null,
      image_key?: string | null,
      stripe_customer_id?: string | null,
      last4?: string | null,
      active_subscription_items?: Array< string | null > | null,
      ref_groups?:  {
        __typename: "ModelUserGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    plan: Plan,
    ref_users?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        group_id: string,
        user_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateGroupMutationVariables = {
  input?: UpdateGroupInput,
  condition?: ModelGroupConditionInput | null,
};

export type UpdateGroupMutation = {
  updateGroup?:  {
    __typename: "Group",
    id: string,
    display_name: string,
    owner_user_id: string,
    ref_owner?:  {
      __typename: "User",
      id: string,
      line_user_id: string,
      email: string,
      username: string,
      sub: string,
      ref_line_user?:  {
        __typename: "LineUser",
        id: string,
        enabled: boolean,
        line_user_name?: string | null,
        profile?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      nickname?: string | null,
      profile?: string | null,
      image_key?: string | null,
      stripe_customer_id?: string | null,
      last4?: string | null,
      active_subscription_items?: Array< string | null > | null,
      ref_groups?:  {
        __typename: "ModelUserGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    plan: Plan,
    ref_users?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        group_id: string,
        user_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteGroupMutationVariables = {
  input?: DeleteGroupInput,
  condition?: ModelGroupConditionInput | null,
};

export type DeleteGroupMutation = {
  deleteGroup?:  {
    __typename: "Group",
    id: string,
    display_name: string,
    owner_user_id: string,
    ref_owner?:  {
      __typename: "User",
      id: string,
      line_user_id: string,
      email: string,
      username: string,
      sub: string,
      ref_line_user?:  {
        __typename: "LineUser",
        id: string,
        enabled: boolean,
        line_user_name?: string | null,
        profile?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      nickname?: string | null,
      profile?: string | null,
      image_key?: string | null,
      stripe_customer_id?: string | null,
      last4?: string | null,
      active_subscription_items?: Array< string | null > | null,
      ref_groups?:  {
        __typename: "ModelUserGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    plan: Plan,
    ref_users?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        group_id: string,
        user_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserMutationVariables = {
  input?: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    line_user_id: string,
    email: string,
    username: string,
    sub: string,
    ref_line_user?:  {
      __typename: "LineUser",
      id: string,
      enabled: boolean,
      line_user_name?: string | null,
      profile?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    nickname?: string | null,
    profile?: string | null,
    image_key?: string | null,
    stripe_customer_id?: string | null,
    last4?: string | null,
    active_subscription_items?: Array< string | null > | null,
    ref_groups?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        group_id: string,
        user_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserMutationVariables = {
  input?: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    line_user_id: string,
    email: string,
    username: string,
    sub: string,
    ref_line_user?:  {
      __typename: "LineUser",
      id: string,
      enabled: boolean,
      line_user_name?: string | null,
      profile?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    nickname?: string | null,
    profile?: string | null,
    image_key?: string | null,
    stripe_customer_id?: string | null,
    last4?: string | null,
    active_subscription_items?: Array< string | null > | null,
    ref_groups?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        group_id: string,
        user_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserMutationVariables = {
  input?: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    line_user_id: string,
    email: string,
    username: string,
    sub: string,
    ref_line_user?:  {
      __typename: "LineUser",
      id: string,
      enabled: boolean,
      line_user_name?: string | null,
      profile?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    nickname?: string | null,
    profile?: string | null,
    image_key?: string | null,
    stripe_customer_id?: string | null,
    last4?: string | null,
    active_subscription_items?: Array< string | null > | null,
    ref_groups?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        group_id: string,
        user_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserGroupMutationVariables = {
  input?: CreateUserGroupInput,
  condition?: ModelUserGroupConditionInput | null,
};

export type CreateUserGroupMutation = {
  createUserGroup?:  {
    __typename: "UserGroup",
    id: string,
    group_id: string,
    user_id: string,
    ref_user?:  {
      __typename: "User",
      id: string,
      line_user_id: string,
      email: string,
      username: string,
      sub: string,
      ref_line_user?:  {
        __typename: "LineUser",
        id: string,
        enabled: boolean,
        line_user_name?: string | null,
        profile?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      nickname?: string | null,
      profile?: string | null,
      image_key?: string | null,
      stripe_customer_id?: string | null,
      last4?: string | null,
      active_subscription_items?: Array< string | null > | null,
      ref_groups?:  {
        __typename: "ModelUserGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ref_group?:  {
      __typename: "Group",
      id: string,
      display_name: string,
      owner_user_id: string,
      ref_owner?:  {
        __typename: "User",
        id: string,
        line_user_id: string,
        email: string,
        username: string,
        sub: string,
        nickname?: string | null,
        profile?: string | null,
        image_key?: string | null,
        stripe_customer_id?: string | null,
        last4?: string | null,
        active_subscription_items?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      plan: Plan,
      ref_users?:  {
        __typename: "ModelUserGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserGroupMutationVariables = {
  input?: UpdateUserGroupInput,
  condition?: ModelUserGroupConditionInput | null,
};

export type UpdateUserGroupMutation = {
  updateUserGroup?:  {
    __typename: "UserGroup",
    id: string,
    group_id: string,
    user_id: string,
    ref_user?:  {
      __typename: "User",
      id: string,
      line_user_id: string,
      email: string,
      username: string,
      sub: string,
      ref_line_user?:  {
        __typename: "LineUser",
        id: string,
        enabled: boolean,
        line_user_name?: string | null,
        profile?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      nickname?: string | null,
      profile?: string | null,
      image_key?: string | null,
      stripe_customer_id?: string | null,
      last4?: string | null,
      active_subscription_items?: Array< string | null > | null,
      ref_groups?:  {
        __typename: "ModelUserGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ref_group?:  {
      __typename: "Group",
      id: string,
      display_name: string,
      owner_user_id: string,
      ref_owner?:  {
        __typename: "User",
        id: string,
        line_user_id: string,
        email: string,
        username: string,
        sub: string,
        nickname?: string | null,
        profile?: string | null,
        image_key?: string | null,
        stripe_customer_id?: string | null,
        last4?: string | null,
        active_subscription_items?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      plan: Plan,
      ref_users?:  {
        __typename: "ModelUserGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserGroupMutationVariables = {
  input?: DeleteUserGroupInput,
  condition?: ModelUserGroupConditionInput | null,
};

export type DeleteUserGroupMutation = {
  deleteUserGroup?:  {
    __typename: "UserGroup",
    id: string,
    group_id: string,
    user_id: string,
    ref_user?:  {
      __typename: "User",
      id: string,
      line_user_id: string,
      email: string,
      username: string,
      sub: string,
      ref_line_user?:  {
        __typename: "LineUser",
        id: string,
        enabled: boolean,
        line_user_name?: string | null,
        profile?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      nickname?: string | null,
      profile?: string | null,
      image_key?: string | null,
      stripe_customer_id?: string | null,
      last4?: string | null,
      active_subscription_items?: Array< string | null > | null,
      ref_groups?:  {
        __typename: "ModelUserGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ref_group?:  {
      __typename: "Group",
      id: string,
      display_name: string,
      owner_user_id: string,
      ref_owner?:  {
        __typename: "User",
        id: string,
        line_user_id: string,
        email: string,
        username: string,
        sub: string,
        nickname?: string | null,
        profile?: string | null,
        image_key?: string | null,
        stripe_customer_id?: string | null,
        last4?: string | null,
        active_subscription_items?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      plan: Plan,
      ref_users?:  {
        __typename: "ModelUserGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateLineUserMutationVariables = {
  input?: CreateLineUserInput,
  condition?: ModelLineUserConditionInput | null,
};

export type CreateLineUserMutation = {
  createLineUser?:  {
    __typename: "LineUser",
    id: string,
    enabled: boolean,
    line_user_name?: string | null,
    profile?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateLineUserMutationVariables = {
  input?: UpdateLineUserInput,
  condition?: ModelLineUserConditionInput | null,
};

export type UpdateLineUserMutation = {
  updateLineUser?:  {
    __typename: "LineUser",
    id: string,
    enabled: boolean,
    line_user_name?: string | null,
    profile?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteLineUserMutationVariables = {
  input?: DeleteLineUserInput,
  condition?: ModelLineUserConditionInput | null,
};

export type DeleteLineUserMutation = {
  deleteLineUser?:  {
    __typename: "LineUser",
    id: string,
    enabled: boolean,
    line_user_name?: string | null,
    profile?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateGrowingMutationVariables = {
  input?: CreateGrowingInput,
  condition?: ModelGrowingConditionInput | null,
};

export type CreateGrowingMutation = {
  createGrowing?:  {
    __typename: "Growing",
    id: string,
    lifeform: string,
    family: string,
    species: string,
    name_ja?: string | null,
    family_ja?: string | null,
    link_ja?: string | null,
    name_en?: string | null,
    link_en?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateGrowingMutationVariables = {
  input?: UpdateGrowingInput,
  condition?: ModelGrowingConditionInput | null,
};

export type UpdateGrowingMutation = {
  updateGrowing?:  {
    __typename: "Growing",
    id: string,
    lifeform: string,
    family: string,
    species: string,
    name_ja?: string | null,
    family_ja?: string | null,
    link_ja?: string | null,
    name_en?: string | null,
    link_en?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteGrowingMutationVariables = {
  input?: DeleteGrowingInput,
  condition?: ModelGrowingConditionInput | null,
};

export type DeleteGrowingMutation = {
  deleteGrowing?:  {
    __typename: "Growing",
    id: string,
    lifeform: string,
    family: string,
    species: string,
    name_ja?: string | null,
    family_ja?: string | null,
    link_ja?: string | null,
    name_en?: string | null,
    link_en?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateFieldNoteMutationVariables = {
  input?: CreateFieldNoteInput,
  condition?: ModelFieldNoteConditionInput | null,
};

export type CreateFieldNoteMutation = {
  createFieldNote?:  {
    __typename: "FieldNote",
    group_id: string,
    id: string,
    note_date_time?: string | null,
    note?: string | null,
    image_key?: string | null,
    cultivation_id: string,
    ref_cultivation?:  {
      __typename: "Cultivation",
      group_id: string,
      ref_group?:  {
        __typename: "Group",
        id: string,
        display_name: string,
        owner_user_id: string,
        plan: Plan,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      description?: string | null,
      start_date?: string | null,
      growing_id: string,
      ref_growing?:  {
        __typename: "Growing",
        id: string,
        lifeform: string,
        family: string,
        species: string,
        name_ja?: string | null,
        family_ja?: string | null,
        link_ja?: string | null,
        name_en?: string | null,
        link_en?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ref_waterings?:  {
        __typename: "ModelWateringConnection",
        nextToken?: string | null,
      } | null,
      ref_field_works?:  {
        __typename: "ModelFieldWorkConnection",
        nextToken?: string | null,
      } | null,
      ref_datasets?:  {
        __typename: "ModelDatasetConnection",
        nextToken?: string | null,
      } | null,
      ref_field_notes?:  {
        __typename: "ModelFieldNoteConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    field_work_id: string,
    ref_field_work?:  {
      __typename: "FieldWork",
      group_id: string,
      id: string,
      work_type: WorkType,
      display_name?: string | null,
      cultivation_id: string,
      ref_cultivation?:  {
        __typename: "Cultivation",
        group_id: string,
        id: string,
        description?: string | null,
        start_date?: string | null,
        growing_id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      plan_date_start?: string | null,
      plan_date_end?: string | null,
      plan_description?: string | null,
      amount?: number | null,
      unit?: Unit | null,
      ref_field_notes?:  {
        __typename: "ModelFieldNoteConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateFieldNoteMutationVariables = {
  input?: UpdateFieldNoteInput,
  condition?: ModelFieldNoteConditionInput | null,
};

export type UpdateFieldNoteMutation = {
  updateFieldNote?:  {
    __typename: "FieldNote",
    group_id: string,
    id: string,
    note_date_time?: string | null,
    note?: string | null,
    image_key?: string | null,
    cultivation_id: string,
    ref_cultivation?:  {
      __typename: "Cultivation",
      group_id: string,
      ref_group?:  {
        __typename: "Group",
        id: string,
        display_name: string,
        owner_user_id: string,
        plan: Plan,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      description?: string | null,
      start_date?: string | null,
      growing_id: string,
      ref_growing?:  {
        __typename: "Growing",
        id: string,
        lifeform: string,
        family: string,
        species: string,
        name_ja?: string | null,
        family_ja?: string | null,
        link_ja?: string | null,
        name_en?: string | null,
        link_en?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ref_waterings?:  {
        __typename: "ModelWateringConnection",
        nextToken?: string | null,
      } | null,
      ref_field_works?:  {
        __typename: "ModelFieldWorkConnection",
        nextToken?: string | null,
      } | null,
      ref_datasets?:  {
        __typename: "ModelDatasetConnection",
        nextToken?: string | null,
      } | null,
      ref_field_notes?:  {
        __typename: "ModelFieldNoteConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    field_work_id: string,
    ref_field_work?:  {
      __typename: "FieldWork",
      group_id: string,
      id: string,
      work_type: WorkType,
      display_name?: string | null,
      cultivation_id: string,
      ref_cultivation?:  {
        __typename: "Cultivation",
        group_id: string,
        id: string,
        description?: string | null,
        start_date?: string | null,
        growing_id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      plan_date_start?: string | null,
      plan_date_end?: string | null,
      plan_description?: string | null,
      amount?: number | null,
      unit?: Unit | null,
      ref_field_notes?:  {
        __typename: "ModelFieldNoteConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteFieldNoteMutationVariables = {
  input?: DeleteFieldNoteInput,
  condition?: ModelFieldNoteConditionInput | null,
};

export type DeleteFieldNoteMutation = {
  deleteFieldNote?:  {
    __typename: "FieldNote",
    group_id: string,
    id: string,
    note_date_time?: string | null,
    note?: string | null,
    image_key?: string | null,
    cultivation_id: string,
    ref_cultivation?:  {
      __typename: "Cultivation",
      group_id: string,
      ref_group?:  {
        __typename: "Group",
        id: string,
        display_name: string,
        owner_user_id: string,
        plan: Plan,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      description?: string | null,
      start_date?: string | null,
      growing_id: string,
      ref_growing?:  {
        __typename: "Growing",
        id: string,
        lifeform: string,
        family: string,
        species: string,
        name_ja?: string | null,
        family_ja?: string | null,
        link_ja?: string | null,
        name_en?: string | null,
        link_en?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ref_waterings?:  {
        __typename: "ModelWateringConnection",
        nextToken?: string | null,
      } | null,
      ref_field_works?:  {
        __typename: "ModelFieldWorkConnection",
        nextToken?: string | null,
      } | null,
      ref_datasets?:  {
        __typename: "ModelDatasetConnection",
        nextToken?: string | null,
      } | null,
      ref_field_notes?:  {
        __typename: "ModelFieldNoteConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    field_work_id: string,
    ref_field_work?:  {
      __typename: "FieldWork",
      group_id: string,
      id: string,
      work_type: WorkType,
      display_name?: string | null,
      cultivation_id: string,
      ref_cultivation?:  {
        __typename: "Cultivation",
        group_id: string,
        id: string,
        description?: string | null,
        start_date?: string | null,
        growing_id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      plan_date_start?: string | null,
      plan_date_end?: string | null,
      plan_description?: string | null,
      amount?: number | null,
      unit?: Unit | null,
      ref_field_notes?:  {
        __typename: "ModelFieldNoteConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateFieldWorkMutationVariables = {
  input?: CreateFieldWorkInput,
  condition?: ModelFieldWorkConditionInput | null,
};

export type CreateFieldWorkMutation = {
  createFieldWork?:  {
    __typename: "FieldWork",
    group_id: string,
    id: string,
    work_type: WorkType,
    display_name?: string | null,
    cultivation_id: string,
    ref_cultivation?:  {
      __typename: "Cultivation",
      group_id: string,
      ref_group?:  {
        __typename: "Group",
        id: string,
        display_name: string,
        owner_user_id: string,
        plan: Plan,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      description?: string | null,
      start_date?: string | null,
      growing_id: string,
      ref_growing?:  {
        __typename: "Growing",
        id: string,
        lifeform: string,
        family: string,
        species: string,
        name_ja?: string | null,
        family_ja?: string | null,
        link_ja?: string | null,
        name_en?: string | null,
        link_en?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ref_waterings?:  {
        __typename: "ModelWateringConnection",
        nextToken?: string | null,
      } | null,
      ref_field_works?:  {
        __typename: "ModelFieldWorkConnection",
        nextToken?: string | null,
      } | null,
      ref_datasets?:  {
        __typename: "ModelDatasetConnection",
        nextToken?: string | null,
      } | null,
      ref_field_notes?:  {
        __typename: "ModelFieldNoteConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    plan_date_start?: string | null,
    plan_date_end?: string | null,
    plan_description?: string | null,
    amount?: number | null,
    unit?: Unit | null,
    ref_field_notes?:  {
      __typename: "ModelFieldNoteConnection",
      items:  Array< {
        __typename: "FieldNote",
        group_id: string,
        id: string,
        note_date_time?: string | null,
        note?: string | null,
        image_key?: string | null,
        cultivation_id: string,
        field_work_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateFieldWorkMutationVariables = {
  input?: UpdateFieldWorkInput,
  condition?: ModelFieldWorkConditionInput | null,
};

export type UpdateFieldWorkMutation = {
  updateFieldWork?:  {
    __typename: "FieldWork",
    group_id: string,
    id: string,
    work_type: WorkType,
    display_name?: string | null,
    cultivation_id: string,
    ref_cultivation?:  {
      __typename: "Cultivation",
      group_id: string,
      ref_group?:  {
        __typename: "Group",
        id: string,
        display_name: string,
        owner_user_id: string,
        plan: Plan,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      description?: string | null,
      start_date?: string | null,
      growing_id: string,
      ref_growing?:  {
        __typename: "Growing",
        id: string,
        lifeform: string,
        family: string,
        species: string,
        name_ja?: string | null,
        family_ja?: string | null,
        link_ja?: string | null,
        name_en?: string | null,
        link_en?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ref_waterings?:  {
        __typename: "ModelWateringConnection",
        nextToken?: string | null,
      } | null,
      ref_field_works?:  {
        __typename: "ModelFieldWorkConnection",
        nextToken?: string | null,
      } | null,
      ref_datasets?:  {
        __typename: "ModelDatasetConnection",
        nextToken?: string | null,
      } | null,
      ref_field_notes?:  {
        __typename: "ModelFieldNoteConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    plan_date_start?: string | null,
    plan_date_end?: string | null,
    plan_description?: string | null,
    amount?: number | null,
    unit?: Unit | null,
    ref_field_notes?:  {
      __typename: "ModelFieldNoteConnection",
      items:  Array< {
        __typename: "FieldNote",
        group_id: string,
        id: string,
        note_date_time?: string | null,
        note?: string | null,
        image_key?: string | null,
        cultivation_id: string,
        field_work_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteFieldWorkMutationVariables = {
  input?: DeleteFieldWorkInput,
  condition?: ModelFieldWorkConditionInput | null,
};

export type DeleteFieldWorkMutation = {
  deleteFieldWork?:  {
    __typename: "FieldWork",
    group_id: string,
    id: string,
    work_type: WorkType,
    display_name?: string | null,
    cultivation_id: string,
    ref_cultivation?:  {
      __typename: "Cultivation",
      group_id: string,
      ref_group?:  {
        __typename: "Group",
        id: string,
        display_name: string,
        owner_user_id: string,
        plan: Plan,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      description?: string | null,
      start_date?: string | null,
      growing_id: string,
      ref_growing?:  {
        __typename: "Growing",
        id: string,
        lifeform: string,
        family: string,
        species: string,
        name_ja?: string | null,
        family_ja?: string | null,
        link_ja?: string | null,
        name_en?: string | null,
        link_en?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ref_waterings?:  {
        __typename: "ModelWateringConnection",
        nextToken?: string | null,
      } | null,
      ref_field_works?:  {
        __typename: "ModelFieldWorkConnection",
        nextToken?: string | null,
      } | null,
      ref_datasets?:  {
        __typename: "ModelDatasetConnection",
        nextToken?: string | null,
      } | null,
      ref_field_notes?:  {
        __typename: "ModelFieldNoteConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    plan_date_start?: string | null,
    plan_date_end?: string | null,
    plan_description?: string | null,
    amount?: number | null,
    unit?: Unit | null,
    ref_field_notes?:  {
      __typename: "ModelFieldNoteConnection",
      items:  Array< {
        __typename: "FieldNote",
        group_id: string,
        id: string,
        note_date_time?: string | null,
        note?: string | null,
        image_key?: string | null,
        cultivation_id: string,
        field_work_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCultivationMutationVariables = {
  input?: CreateCultivationInput,
  condition?: ModelCultivationConditionInput | null,
};

export type CreateCultivationMutation = {
  createCultivation?:  {
    __typename: "Cultivation",
    group_id: string,
    ref_group?:  {
      __typename: "Group",
      id: string,
      display_name: string,
      owner_user_id: string,
      ref_owner?:  {
        __typename: "User",
        id: string,
        line_user_id: string,
        email: string,
        username: string,
        sub: string,
        nickname?: string | null,
        profile?: string | null,
        image_key?: string | null,
        stripe_customer_id?: string | null,
        last4?: string | null,
        active_subscription_items?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      plan: Plan,
      ref_users?:  {
        __typename: "ModelUserGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    id: string,
    description?: string | null,
    start_date?: string | null,
    growing_id: string,
    ref_growing?:  {
      __typename: "Growing",
      id: string,
      lifeform: string,
      family: string,
      species: string,
      name_ja?: string | null,
      family_ja?: string | null,
      link_ja?: string | null,
      name_en?: string | null,
      link_en?: string | null,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ref_waterings?:  {
      __typename: "ModelWateringConnection",
      items:  Array< {
        __typename: "Watering",
        group_id: string,
        id: string,
        display_name?: string | null,
        watering_type?: WateringType | null,
        enabled: boolean,
        span_min?: number | null,
        span_max?: number | null,
        period_start_time?: string | null,
        period_end_time?: string | null,
        fixed_span?: number | null,
        every?: number | null,
        target_metric?: string | null,
        target_value?: number | null,
        kp?: number | null,
        ki?: number | null,
        kd?: number | null,
        _last_time?: number | null,
        _last_output?: number | null,
        _last_input?: number | null,
        cultivation_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ref_field_works?:  {
      __typename: "ModelFieldWorkConnection",
      items:  Array< {
        __typename: "FieldWork",
        group_id: string,
        id: string,
        work_type: WorkType,
        display_name?: string | null,
        cultivation_id: string,
        plan_date_start?: string | null,
        plan_date_end?: string | null,
        plan_description?: string | null,
        amount?: number | null,
        unit?: Unit | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ref_datasets?:  {
      __typename: "ModelDatasetConnection",
      items:  Array< {
        __typename: "Dataset",
        group_id: string,
        id: string,
        data_type?: DataType | null,
        timezone?: string | null,
        description?: string | null,
        cultivation_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ref_field_notes?:  {
      __typename: "ModelFieldNoteConnection",
      items:  Array< {
        __typename: "FieldNote",
        group_id: string,
        id: string,
        note_date_time?: string | null,
        note?: string | null,
        image_key?: string | null,
        cultivation_id: string,
        field_work_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCultivationMutationVariables = {
  input?: UpdateCultivationInput,
  condition?: ModelCultivationConditionInput | null,
};

export type UpdateCultivationMutation = {
  updateCultivation?:  {
    __typename: "Cultivation",
    group_id: string,
    ref_group?:  {
      __typename: "Group",
      id: string,
      display_name: string,
      owner_user_id: string,
      ref_owner?:  {
        __typename: "User",
        id: string,
        line_user_id: string,
        email: string,
        username: string,
        sub: string,
        nickname?: string | null,
        profile?: string | null,
        image_key?: string | null,
        stripe_customer_id?: string | null,
        last4?: string | null,
        active_subscription_items?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      plan: Plan,
      ref_users?:  {
        __typename: "ModelUserGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    id: string,
    description?: string | null,
    start_date?: string | null,
    growing_id: string,
    ref_growing?:  {
      __typename: "Growing",
      id: string,
      lifeform: string,
      family: string,
      species: string,
      name_ja?: string | null,
      family_ja?: string | null,
      link_ja?: string | null,
      name_en?: string | null,
      link_en?: string | null,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ref_waterings?:  {
      __typename: "ModelWateringConnection",
      items:  Array< {
        __typename: "Watering",
        group_id: string,
        id: string,
        display_name?: string | null,
        watering_type?: WateringType | null,
        enabled: boolean,
        span_min?: number | null,
        span_max?: number | null,
        period_start_time?: string | null,
        period_end_time?: string | null,
        fixed_span?: number | null,
        every?: number | null,
        target_metric?: string | null,
        target_value?: number | null,
        kp?: number | null,
        ki?: number | null,
        kd?: number | null,
        _last_time?: number | null,
        _last_output?: number | null,
        _last_input?: number | null,
        cultivation_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ref_field_works?:  {
      __typename: "ModelFieldWorkConnection",
      items:  Array< {
        __typename: "FieldWork",
        group_id: string,
        id: string,
        work_type: WorkType,
        display_name?: string | null,
        cultivation_id: string,
        plan_date_start?: string | null,
        plan_date_end?: string | null,
        plan_description?: string | null,
        amount?: number | null,
        unit?: Unit | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ref_datasets?:  {
      __typename: "ModelDatasetConnection",
      items:  Array< {
        __typename: "Dataset",
        group_id: string,
        id: string,
        data_type?: DataType | null,
        timezone?: string | null,
        description?: string | null,
        cultivation_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ref_field_notes?:  {
      __typename: "ModelFieldNoteConnection",
      items:  Array< {
        __typename: "FieldNote",
        group_id: string,
        id: string,
        note_date_time?: string | null,
        note?: string | null,
        image_key?: string | null,
        cultivation_id: string,
        field_work_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCultivationMutationVariables = {
  input?: DeleteCultivationInput,
  condition?: ModelCultivationConditionInput | null,
};

export type DeleteCultivationMutation = {
  deleteCultivation?:  {
    __typename: "Cultivation",
    group_id: string,
    ref_group?:  {
      __typename: "Group",
      id: string,
      display_name: string,
      owner_user_id: string,
      ref_owner?:  {
        __typename: "User",
        id: string,
        line_user_id: string,
        email: string,
        username: string,
        sub: string,
        nickname?: string | null,
        profile?: string | null,
        image_key?: string | null,
        stripe_customer_id?: string | null,
        last4?: string | null,
        active_subscription_items?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      plan: Plan,
      ref_users?:  {
        __typename: "ModelUserGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    id: string,
    description?: string | null,
    start_date?: string | null,
    growing_id: string,
    ref_growing?:  {
      __typename: "Growing",
      id: string,
      lifeform: string,
      family: string,
      species: string,
      name_ja?: string | null,
      family_ja?: string | null,
      link_ja?: string | null,
      name_en?: string | null,
      link_en?: string | null,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ref_waterings?:  {
      __typename: "ModelWateringConnection",
      items:  Array< {
        __typename: "Watering",
        group_id: string,
        id: string,
        display_name?: string | null,
        watering_type?: WateringType | null,
        enabled: boolean,
        span_min?: number | null,
        span_max?: number | null,
        period_start_time?: string | null,
        period_end_time?: string | null,
        fixed_span?: number | null,
        every?: number | null,
        target_metric?: string | null,
        target_value?: number | null,
        kp?: number | null,
        ki?: number | null,
        kd?: number | null,
        _last_time?: number | null,
        _last_output?: number | null,
        _last_input?: number | null,
        cultivation_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ref_field_works?:  {
      __typename: "ModelFieldWorkConnection",
      items:  Array< {
        __typename: "FieldWork",
        group_id: string,
        id: string,
        work_type: WorkType,
        display_name?: string | null,
        cultivation_id: string,
        plan_date_start?: string | null,
        plan_date_end?: string | null,
        plan_description?: string | null,
        amount?: number | null,
        unit?: Unit | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ref_datasets?:  {
      __typename: "ModelDatasetConnection",
      items:  Array< {
        __typename: "Dataset",
        group_id: string,
        id: string,
        data_type?: DataType | null,
        timezone?: string | null,
        description?: string | null,
        cultivation_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ref_field_notes?:  {
      __typename: "ModelFieldNoteConnection",
      items:  Array< {
        __typename: "FieldNote",
        group_id: string,
        id: string,
        note_date_time?: string | null,
        note?: string | null,
        image_key?: string | null,
        cultivation_id: string,
        field_work_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateWateringMutationVariables = {
  input?: CreateWateringInput,
  condition?: ModelWateringConditionInput | null,
};

export type CreateWateringMutation = {
  createWatering?:  {
    __typename: "Watering",
    group_id: string,
    id: string,
    display_name?: string | null,
    watering_type?: WateringType | null,
    enabled: boolean,
    span_min?: number | null,
    span_max?: number | null,
    period_start_time?: string | null,
    period_end_time?: string | null,
    fixed_span?: number | null,
    every?: number | null,
    target_metric?: string | null,
    target_value?: number | null,
    kp?: number | null,
    ki?: number | null,
    kd?: number | null,
    _last_time?: number | null,
    _last_output?: number | null,
    _last_input?: number | null,
    cultivation_id: string,
    ref_cultivation?:  {
      __typename: "Cultivation",
      group_id: string,
      ref_group?:  {
        __typename: "Group",
        id: string,
        display_name: string,
        owner_user_id: string,
        plan: Plan,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      description?: string | null,
      start_date?: string | null,
      growing_id: string,
      ref_growing?:  {
        __typename: "Growing",
        id: string,
        lifeform: string,
        family: string,
        species: string,
        name_ja?: string | null,
        family_ja?: string | null,
        link_ja?: string | null,
        name_en?: string | null,
        link_en?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ref_waterings?:  {
        __typename: "ModelWateringConnection",
        nextToken?: string | null,
      } | null,
      ref_field_works?:  {
        __typename: "ModelFieldWorkConnection",
        nextToken?: string | null,
      } | null,
      ref_datasets?:  {
        __typename: "ModelDatasetConnection",
        nextToken?: string | null,
      } | null,
      ref_field_notes?:  {
        __typename: "ModelFieldNoteConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ref_devices?:  {
      __typename: "ModelDeviceWateringConnection",
      items:  Array< {
        __typename: "DeviceWatering",
        group_id: string,
        device_id: string,
        watering_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateWateringMutationVariables = {
  input?: UpdateWateringInput,
  condition?: ModelWateringConditionInput | null,
};

export type UpdateWateringMutation = {
  updateWatering?:  {
    __typename: "Watering",
    group_id: string,
    id: string,
    display_name?: string | null,
    watering_type?: WateringType | null,
    enabled: boolean,
    span_min?: number | null,
    span_max?: number | null,
    period_start_time?: string | null,
    period_end_time?: string | null,
    fixed_span?: number | null,
    every?: number | null,
    target_metric?: string | null,
    target_value?: number | null,
    kp?: number | null,
    ki?: number | null,
    kd?: number | null,
    _last_time?: number | null,
    _last_output?: number | null,
    _last_input?: number | null,
    cultivation_id: string,
    ref_cultivation?:  {
      __typename: "Cultivation",
      group_id: string,
      ref_group?:  {
        __typename: "Group",
        id: string,
        display_name: string,
        owner_user_id: string,
        plan: Plan,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      description?: string | null,
      start_date?: string | null,
      growing_id: string,
      ref_growing?:  {
        __typename: "Growing",
        id: string,
        lifeform: string,
        family: string,
        species: string,
        name_ja?: string | null,
        family_ja?: string | null,
        link_ja?: string | null,
        name_en?: string | null,
        link_en?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ref_waterings?:  {
        __typename: "ModelWateringConnection",
        nextToken?: string | null,
      } | null,
      ref_field_works?:  {
        __typename: "ModelFieldWorkConnection",
        nextToken?: string | null,
      } | null,
      ref_datasets?:  {
        __typename: "ModelDatasetConnection",
        nextToken?: string | null,
      } | null,
      ref_field_notes?:  {
        __typename: "ModelFieldNoteConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ref_devices?:  {
      __typename: "ModelDeviceWateringConnection",
      items:  Array< {
        __typename: "DeviceWatering",
        group_id: string,
        device_id: string,
        watering_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteWateringMutationVariables = {
  input?: DeleteWateringInput,
  condition?: ModelWateringConditionInput | null,
};

export type DeleteWateringMutation = {
  deleteWatering?:  {
    __typename: "Watering",
    group_id: string,
    id: string,
    display_name?: string | null,
    watering_type?: WateringType | null,
    enabled: boolean,
    span_min?: number | null,
    span_max?: number | null,
    period_start_time?: string | null,
    period_end_time?: string | null,
    fixed_span?: number | null,
    every?: number | null,
    target_metric?: string | null,
    target_value?: number | null,
    kp?: number | null,
    ki?: number | null,
    kd?: number | null,
    _last_time?: number | null,
    _last_output?: number | null,
    _last_input?: number | null,
    cultivation_id: string,
    ref_cultivation?:  {
      __typename: "Cultivation",
      group_id: string,
      ref_group?:  {
        __typename: "Group",
        id: string,
        display_name: string,
        owner_user_id: string,
        plan: Plan,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      description?: string | null,
      start_date?: string | null,
      growing_id: string,
      ref_growing?:  {
        __typename: "Growing",
        id: string,
        lifeform: string,
        family: string,
        species: string,
        name_ja?: string | null,
        family_ja?: string | null,
        link_ja?: string | null,
        name_en?: string | null,
        link_en?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ref_waterings?:  {
        __typename: "ModelWateringConnection",
        nextToken?: string | null,
      } | null,
      ref_field_works?:  {
        __typename: "ModelFieldWorkConnection",
        nextToken?: string | null,
      } | null,
      ref_datasets?:  {
        __typename: "ModelDatasetConnection",
        nextToken?: string | null,
      } | null,
      ref_field_notes?:  {
        __typename: "ModelFieldNoteConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ref_devices?:  {
      __typename: "ModelDeviceWateringConnection",
      items:  Array< {
        __typename: "DeviceWatering",
        group_id: string,
        device_id: string,
        watering_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDatasetMutationVariables = {
  input?: CreateDatasetInput,
  condition?: ModelDatasetConditionInput | null,
};

export type CreateDatasetMutation = {
  createDataset?:  {
    __typename: "Dataset",
    group_id: string,
    id: string,
    data_type?: DataType | null,
    timezone?: string | null,
    column_map?:  Array< {
      __typename: "ColumnMap",
      key: string,
      name: string,
    } | null > | null,
    description?: string | null,
    cultivation_id: string,
    ref_cultivation?:  {
      __typename: "Cultivation",
      group_id: string,
      ref_group?:  {
        __typename: "Group",
        id: string,
        display_name: string,
        owner_user_id: string,
        plan: Plan,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      description?: string | null,
      start_date?: string | null,
      growing_id: string,
      ref_growing?:  {
        __typename: "Growing",
        id: string,
        lifeform: string,
        family: string,
        species: string,
        name_ja?: string | null,
        family_ja?: string | null,
        link_ja?: string | null,
        name_en?: string | null,
        link_en?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ref_waterings?:  {
        __typename: "ModelWateringConnection",
        nextToken?: string | null,
      } | null,
      ref_field_works?:  {
        __typename: "ModelFieldWorkConnection",
        nextToken?: string | null,
      } | null,
      ref_datasets?:  {
        __typename: "ModelDatasetConnection",
        nextToken?: string | null,
      } | null,
      ref_field_notes?:  {
        __typename: "ModelFieldNoteConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ref_devices?:  {
      __typename: "ModelDeviceDatasetConnection",
      items:  Array< {
        __typename: "DeviceDataset",
        group_id: string,
        device_id: string,
        dataset_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDatasetMutationVariables = {
  input?: UpdateDatasetInput,
  condition?: ModelDatasetConditionInput | null,
};

export type UpdateDatasetMutation = {
  updateDataset?:  {
    __typename: "Dataset",
    group_id: string,
    id: string,
    data_type?: DataType | null,
    timezone?: string | null,
    column_map?:  Array< {
      __typename: "ColumnMap",
      key: string,
      name: string,
    } | null > | null,
    description?: string | null,
    cultivation_id: string,
    ref_cultivation?:  {
      __typename: "Cultivation",
      group_id: string,
      ref_group?:  {
        __typename: "Group",
        id: string,
        display_name: string,
        owner_user_id: string,
        plan: Plan,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      description?: string | null,
      start_date?: string | null,
      growing_id: string,
      ref_growing?:  {
        __typename: "Growing",
        id: string,
        lifeform: string,
        family: string,
        species: string,
        name_ja?: string | null,
        family_ja?: string | null,
        link_ja?: string | null,
        name_en?: string | null,
        link_en?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ref_waterings?:  {
        __typename: "ModelWateringConnection",
        nextToken?: string | null,
      } | null,
      ref_field_works?:  {
        __typename: "ModelFieldWorkConnection",
        nextToken?: string | null,
      } | null,
      ref_datasets?:  {
        __typename: "ModelDatasetConnection",
        nextToken?: string | null,
      } | null,
      ref_field_notes?:  {
        __typename: "ModelFieldNoteConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ref_devices?:  {
      __typename: "ModelDeviceDatasetConnection",
      items:  Array< {
        __typename: "DeviceDataset",
        group_id: string,
        device_id: string,
        dataset_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDatasetMutationVariables = {
  input?: DeleteDatasetInput,
  condition?: ModelDatasetConditionInput | null,
};

export type DeleteDatasetMutation = {
  deleteDataset?:  {
    __typename: "Dataset",
    group_id: string,
    id: string,
    data_type?: DataType | null,
    timezone?: string | null,
    column_map?:  Array< {
      __typename: "ColumnMap",
      key: string,
      name: string,
    } | null > | null,
    description?: string | null,
    cultivation_id: string,
    ref_cultivation?:  {
      __typename: "Cultivation",
      group_id: string,
      ref_group?:  {
        __typename: "Group",
        id: string,
        display_name: string,
        owner_user_id: string,
        plan: Plan,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      description?: string | null,
      start_date?: string | null,
      growing_id: string,
      ref_growing?:  {
        __typename: "Growing",
        id: string,
        lifeform: string,
        family: string,
        species: string,
        name_ja?: string | null,
        family_ja?: string | null,
        link_ja?: string | null,
        name_en?: string | null,
        link_en?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ref_waterings?:  {
        __typename: "ModelWateringConnection",
        nextToken?: string | null,
      } | null,
      ref_field_works?:  {
        __typename: "ModelFieldWorkConnection",
        nextToken?: string | null,
      } | null,
      ref_datasets?:  {
        __typename: "ModelDatasetConnection",
        nextToken?: string | null,
      } | null,
      ref_field_notes?:  {
        __typename: "ModelFieldNoteConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ref_devices?:  {
      __typename: "ModelDeviceDatasetConnection",
      items:  Array< {
        __typename: "DeviceDataset",
        group_id: string,
        device_id: string,
        dataset_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDeviceMutationVariables = {
  input?: CreateDeviceInput,
  condition?: ModelDeviceConditionInput | null,
};

export type CreateDeviceMutation = {
  createDevice?:  {
    __typename: "Device",
    group_id: string,
    id: string,
    display_name?: string | null,
    device_type?: DeviceType | null,
    description?: string | null,
    enabled: boolean,
    certificate?: string | null,
    private_key?: string | null,
    endpoint?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDeviceMutationVariables = {
  input?: UpdateDeviceInput,
  condition?: ModelDeviceConditionInput | null,
};

export type UpdateDeviceMutation = {
  updateDevice?:  {
    __typename: "Device",
    group_id: string,
    id: string,
    display_name?: string | null,
    device_type?: DeviceType | null,
    description?: string | null,
    enabled: boolean,
    certificate?: string | null,
    private_key?: string | null,
    endpoint?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDeviceMutationVariables = {
  input?: DeleteDeviceInput,
  condition?: ModelDeviceConditionInput | null,
};

export type DeleteDeviceMutation = {
  deleteDevice?:  {
    __typename: "Device",
    group_id: string,
    id: string,
    display_name?: string | null,
    device_type?: DeviceType | null,
    description?: string | null,
    enabled: boolean,
    certificate?: string | null,
    private_key?: string | null,
    endpoint?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDeviceDatasetMutationVariables = {
  input?: CreateDeviceDatasetInput,
  condition?: ModelDeviceDatasetConditionInput | null,
};

export type CreateDeviceDatasetMutation = {
  createDeviceDataset?:  {
    __typename: "DeviceDataset",
    group_id: string,
    device_id: string,
    dataset_id: string,
    ref_device?:  {
      __typename: "Device",
      group_id: string,
      id: string,
      display_name?: string | null,
      device_type?: DeviceType | null,
      description?: string | null,
      enabled: boolean,
      certificate?: string | null,
      private_key?: string | null,
      endpoint?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDeviceDatasetMutationVariables = {
  input?: UpdateDeviceDatasetInput,
  condition?: ModelDeviceDatasetConditionInput | null,
};

export type UpdateDeviceDatasetMutation = {
  updateDeviceDataset?:  {
    __typename: "DeviceDataset",
    group_id: string,
    device_id: string,
    dataset_id: string,
    ref_device?:  {
      __typename: "Device",
      group_id: string,
      id: string,
      display_name?: string | null,
      device_type?: DeviceType | null,
      description?: string | null,
      enabled: boolean,
      certificate?: string | null,
      private_key?: string | null,
      endpoint?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDeviceDatasetMutationVariables = {
  input?: DeleteDeviceDatasetInput,
  condition?: ModelDeviceDatasetConditionInput | null,
};

export type DeleteDeviceDatasetMutation = {
  deleteDeviceDataset?:  {
    __typename: "DeviceDataset",
    group_id: string,
    device_id: string,
    dataset_id: string,
    ref_device?:  {
      __typename: "Device",
      group_id: string,
      id: string,
      display_name?: string | null,
      device_type?: DeviceType | null,
      description?: string | null,
      enabled: boolean,
      certificate?: string | null,
      private_key?: string | null,
      endpoint?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDeviceWateringMutationVariables = {
  input?: CreateDeviceWateringInput,
  condition?: ModelDeviceWateringConditionInput | null,
};

export type CreateDeviceWateringMutation = {
  createDeviceWatering?:  {
    __typename: "DeviceWatering",
    group_id: string,
    device_id: string,
    watering_id: string,
    ref_device?:  {
      __typename: "Device",
      group_id: string,
      id: string,
      display_name?: string | null,
      device_type?: DeviceType | null,
      description?: string | null,
      enabled: boolean,
      certificate?: string | null,
      private_key?: string | null,
      endpoint?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDeviceWateringMutationVariables = {
  input?: UpdateDeviceWateringInput,
  condition?: ModelDeviceWateringConditionInput | null,
};

export type UpdateDeviceWateringMutation = {
  updateDeviceWatering?:  {
    __typename: "DeviceWatering",
    group_id: string,
    device_id: string,
    watering_id: string,
    ref_device?:  {
      __typename: "Device",
      group_id: string,
      id: string,
      display_name?: string | null,
      device_type?: DeviceType | null,
      description?: string | null,
      enabled: boolean,
      certificate?: string | null,
      private_key?: string | null,
      endpoint?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDeviceWateringMutationVariables = {
  input?: DeleteDeviceWateringInput,
  condition?: ModelDeviceWateringConditionInput | null,
};

export type DeleteDeviceWateringMutation = {
  deleteDeviceWatering?:  {
    __typename: "DeviceWatering",
    group_id: string,
    device_id: string,
    watering_id: string,
    ref_device?:  {
      __typename: "Device",
      group_id: string,
      id: string,
      display_name?: string | null,
      device_type?: DeviceType | null,
      description?: string | null,
      enabled: boolean,
      certificate?: string | null,
      private_key?: string | null,
      endpoint?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSensorDataMutationVariables = {
  input?: CreateSensorDataInput,
  condition?: ModelSensorDataConditionInput | null,
};

export type CreateSensorDataMutation = {
  createSensorData?:  {
    __typename: "SensorData",
    group_id: string,
    dataset_id: string,
    keytime: number,
    ttl: number,
    year: number,
    month: number,
    date: string,
    hour: number,
    datetime: string,
    v0?: number | null,
    v1?: number | null,
    v2?: number | null,
    v3?: number | null,
    v4?: number | null,
    v5?: number | null,
    v6?: number | null,
    v7?: number | null,
    v8?: number | null,
    v9?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSensorDataMutationVariables = {
  input?: UpdateSensorDataInput,
  condition?: ModelSensorDataConditionInput | null,
};

export type UpdateSensorDataMutation = {
  updateSensorData?:  {
    __typename: "SensorData",
    group_id: string,
    dataset_id: string,
    keytime: number,
    ttl: number,
    year: number,
    month: number,
    date: string,
    hour: number,
    datetime: string,
    v0?: number | null,
    v1?: number | null,
    v2?: number | null,
    v3?: number | null,
    v4?: number | null,
    v5?: number | null,
    v6?: number | null,
    v7?: number | null,
    v8?: number | null,
    v9?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSensorDataMutationVariables = {
  input?: DeleteSensorDataInput,
  condition?: ModelSensorDataConditionInput | null,
};

export type DeleteSensorDataMutation = {
  deleteSensorData?:  {
    __typename: "SensorData",
    group_id: string,
    dataset_id: string,
    keytime: number,
    ttl: number,
    year: number,
    month: number,
    date: string,
    hour: number,
    datetime: string,
    v0?: number | null,
    v1?: number | null,
    v2?: number | null,
    v3?: number | null,
    v4?: number | null,
    v5?: number | null,
    v6?: number | null,
    v7?: number | null,
    v8?: number | null,
    v9?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateWateringHistoryMutationVariables = {
  input?: CreateWateringHistoryInput,
  condition?: ModelWateringHistoryConditionInput | null,
};

export type CreateWateringHistoryMutation = {
  createWateringHistory?:  {
    __typename: "WateringHistory",
    group_id: string,
    watering_id: string,
    keytime: number,
    ttl: number,
    year: number,
    month: number,
    date: string,
    hour: number,
    datetime: string,
    span: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateWateringHistoryMutationVariables = {
  input?: UpdateWateringHistoryInput,
  condition?: ModelWateringHistoryConditionInput | null,
};

export type UpdateWateringHistoryMutation = {
  updateWateringHistory?:  {
    __typename: "WateringHistory",
    group_id: string,
    watering_id: string,
    keytime: number,
    ttl: number,
    year: number,
    month: number,
    date: string,
    hour: number,
    datetime: string,
    span: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteWateringHistoryMutationVariables = {
  input?: DeleteWateringHistoryInput,
  condition?: ModelWateringHistoryConditionInput | null,
};

export type DeleteWateringHistoryMutation = {
  deleteWateringHistory?:  {
    __typename: "WateringHistory",
    group_id: string,
    watering_id: string,
    keytime: number,
    ttl: number,
    year: number,
    month: number,
    date: string,
    hour: number,
    datetime: string,
    span: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateControlPlaneMutationVariables = {
  input?: CreateControlPlaneInput,
  condition?: ModelControlPlaneConditionInput | null,
};

export type CreateControlPlaneMutation = {
  createControlPlane?:  {
    __typename: "ControlPlane",
    group_id: string,
    ref_group?:  {
      __typename: "Group",
      id: string,
      display_name: string,
      owner_user_id: string,
      ref_owner?:  {
        __typename: "User",
        id: string,
        line_user_id: string,
        email: string,
        username: string,
        sub: string,
        nickname?: string | null,
        profile?: string | null,
        image_key?: string | null,
        stripe_customer_id?: string | null,
        last4?: string | null,
        active_subscription_items?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      plan: Plan,
      ref_users?:  {
        __typename: "ModelUserGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    id: string,
    display_name: string,
    status: ControlPlaneStatus,
    ssm_activation_code?: string | null,
    ssm_activation_id?: string | null,
    info?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateControlPlaneMutationVariables = {
  input?: UpdateControlPlaneInput,
  condition?: ModelControlPlaneConditionInput | null,
};

export type UpdateControlPlaneMutation = {
  updateControlPlane?:  {
    __typename: "ControlPlane",
    group_id: string,
    ref_group?:  {
      __typename: "Group",
      id: string,
      display_name: string,
      owner_user_id: string,
      ref_owner?:  {
        __typename: "User",
        id: string,
        line_user_id: string,
        email: string,
        username: string,
        sub: string,
        nickname?: string | null,
        profile?: string | null,
        image_key?: string | null,
        stripe_customer_id?: string | null,
        last4?: string | null,
        active_subscription_items?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      plan: Plan,
      ref_users?:  {
        __typename: "ModelUserGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    id: string,
    display_name: string,
    status: ControlPlaneStatus,
    ssm_activation_code?: string | null,
    ssm_activation_id?: string | null,
    info?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteControlPlaneMutationVariables = {
  input?: DeleteControlPlaneInput,
  condition?: ModelControlPlaneConditionInput | null,
};

export type DeleteControlPlaneMutation = {
  deleteControlPlane?:  {
    __typename: "ControlPlane",
    group_id: string,
    ref_group?:  {
      __typename: "Group",
      id: string,
      display_name: string,
      owner_user_id: string,
      ref_owner?:  {
        __typename: "User",
        id: string,
        line_user_id: string,
        email: string,
        username: string,
        sub: string,
        nickname?: string | null,
        profile?: string | null,
        image_key?: string | null,
        stripe_customer_id?: string | null,
        last4?: string | null,
        active_subscription_items?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      plan: Plan,
      ref_users?:  {
        __typename: "ModelUserGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    id: string,
    display_name: string,
    status: ControlPlaneStatus,
    ssm_activation_code?: string | null,
    ssm_activation_id?: string | null,
    info?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUsageMutationVariables = {
  input?: CreateUsageInput,
  condition?: ModelUsageConditionInput | null,
};

export type CreateUsageMutation = {
  createUsage?:  {
    __typename: "Usage",
    group_id: string,
    price_id: string,
    keytime: number,
    year: number,
    month: number,
    date: string,
    datetime: string,
    volume: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUsageMutationVariables = {
  input?: UpdateUsageInput,
  condition?: ModelUsageConditionInput | null,
};

export type UpdateUsageMutation = {
  updateUsage?:  {
    __typename: "Usage",
    group_id: string,
    price_id: string,
    keytime: number,
    year: number,
    month: number,
    date: string,
    datetime: string,
    volume: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUsageMutationVariables = {
  input?: DeleteUsageInput,
  condition?: ModelUsageConditionInput | null,
};

export type DeleteUsageMutation = {
  deleteUsage?:  {
    __typename: "Usage",
    group_id: string,
    price_id: string,
    keytime: number,
    year: number,
    month: number,
    date: string,
    datetime: string,
    volume: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetGroupQueryVariables = {
  id?: string,
};

export type GetGroupQuery = {
  getGroup?:  {
    __typename: "Group",
    id: string,
    display_name: string,
    owner_user_id: string,
    ref_owner?:  {
      __typename: "User",
      id: string,
      line_user_id: string,
      email: string,
      username: string,
      sub: string,
      ref_line_user?:  {
        __typename: "LineUser",
        id: string,
        enabled: boolean,
        line_user_name?: string | null,
        profile?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      nickname?: string | null,
      profile?: string | null,
      image_key?: string | null,
      stripe_customer_id?: string | null,
      last4?: string | null,
      active_subscription_items?: Array< string | null > | null,
      ref_groups?:  {
        __typename: "ModelUserGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    plan: Plan,
    ref_users?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        group_id: string,
        user_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListGroupsQueryVariables = {
  id?: string | null,
  filter?: ModelGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListGroupsQuery = {
  listGroups?:  {
    __typename: "ModelGroupConnection",
    items:  Array< {
      __typename: "Group",
      id: string,
      display_name: string,
      owner_user_id: string,
      ref_owner?:  {
        __typename: "User",
        id: string,
        line_user_id: string,
        email: string,
        username: string,
        sub: string,
        nickname?: string | null,
        profile?: string | null,
        image_key?: string | null,
        stripe_customer_id?: string | null,
        last4?: string | null,
        active_subscription_items?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      plan: Plan,
      ref_users?:  {
        __typename: "ModelUserGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id?: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    line_user_id: string,
    email: string,
    username: string,
    sub: string,
    ref_line_user?:  {
      __typename: "LineUser",
      id: string,
      enabled: boolean,
      line_user_name?: string | null,
      profile?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    nickname?: string | null,
    profile?: string | null,
    image_key?: string | null,
    stripe_customer_id?: string | null,
    last4?: string | null,
    active_subscription_items?: Array< string | null > | null,
    ref_groups?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        group_id: string,
        user_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUsersQueryVariables = {
  id?: string | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      line_user_id: string,
      email: string,
      username: string,
      sub: string,
      ref_line_user?:  {
        __typename: "LineUser",
        id: string,
        enabled: boolean,
        line_user_name?: string | null,
        profile?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      nickname?: string | null,
      profile?: string | null,
      image_key?: string | null,
      stripe_customer_id?: string | null,
      last4?: string | null,
      active_subscription_items?: Array< string | null > | null,
      ref_groups?:  {
        __typename: "ModelUserGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserGroupQueryVariables = {
  id?: string,
};

export type GetUserGroupQuery = {
  getUserGroup?:  {
    __typename: "UserGroup",
    id: string,
    group_id: string,
    user_id: string,
    ref_user?:  {
      __typename: "User",
      id: string,
      line_user_id: string,
      email: string,
      username: string,
      sub: string,
      ref_line_user?:  {
        __typename: "LineUser",
        id: string,
        enabled: boolean,
        line_user_name?: string | null,
        profile?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      nickname?: string | null,
      profile?: string | null,
      image_key?: string | null,
      stripe_customer_id?: string | null,
      last4?: string | null,
      active_subscription_items?: Array< string | null > | null,
      ref_groups?:  {
        __typename: "ModelUserGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ref_group?:  {
      __typename: "Group",
      id: string,
      display_name: string,
      owner_user_id: string,
      ref_owner?:  {
        __typename: "User",
        id: string,
        line_user_id: string,
        email: string,
        username: string,
        sub: string,
        nickname?: string | null,
        profile?: string | null,
        image_key?: string | null,
        stripe_customer_id?: string | null,
        last4?: string | null,
        active_subscription_items?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      plan: Plan,
      ref_users?:  {
        __typename: "ModelUserGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserGroupsQueryVariables = {
  id?: string | null,
  filter?: ModelUserGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUserGroupsQuery = {
  listUserGroups?:  {
    __typename: "ModelUserGroupConnection",
    items:  Array< {
      __typename: "UserGroup",
      id: string,
      group_id: string,
      user_id: string,
      ref_user?:  {
        __typename: "User",
        id: string,
        line_user_id: string,
        email: string,
        username: string,
        sub: string,
        nickname?: string | null,
        profile?: string | null,
        image_key?: string | null,
        stripe_customer_id?: string | null,
        last4?: string | null,
        active_subscription_items?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ref_group?:  {
        __typename: "Group",
        id: string,
        display_name: string,
        owner_user_id: string,
        plan: Plan,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLineUserQueryVariables = {
  id?: string,
};

export type GetLineUserQuery = {
  getLineUser?:  {
    __typename: "LineUser",
    id: string,
    enabled: boolean,
    line_user_name?: string | null,
    profile?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListLineUsersQueryVariables = {
  id?: string | null,
  filter?: ModelLineUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListLineUsersQuery = {
  listLineUsers?:  {
    __typename: "ModelLineUserConnection",
    items:  Array< {
      __typename: "LineUser",
      id: string,
      enabled: boolean,
      line_user_name?: string | null,
      profile?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGrowingQueryVariables = {
  id?: string,
};

export type GetGrowingQuery = {
  getGrowing?:  {
    __typename: "Growing",
    id: string,
    lifeform: string,
    family: string,
    species: string,
    name_ja?: string | null,
    family_ja?: string | null,
    link_ja?: string | null,
    name_en?: string | null,
    link_en?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListGrowingsQueryVariables = {
  id?: string | null,
  filter?: ModelGrowingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListGrowingsQuery = {
  listGrowings?:  {
    __typename: "ModelGrowingConnection",
    items:  Array< {
      __typename: "Growing",
      id: string,
      lifeform: string,
      family: string,
      species: string,
      name_ja?: string | null,
      family_ja?: string | null,
      link_ja?: string | null,
      name_en?: string | null,
      link_en?: string | null,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFieldNoteQueryVariables = {
  group_id?: string,
  id?: string,
};

export type GetFieldNoteQuery = {
  getFieldNote?:  {
    __typename: "FieldNote",
    group_id: string,
    id: string,
    note_date_time?: string | null,
    note?: string | null,
    image_key?: string | null,
    cultivation_id: string,
    ref_cultivation?:  {
      __typename: "Cultivation",
      group_id: string,
      ref_group?:  {
        __typename: "Group",
        id: string,
        display_name: string,
        owner_user_id: string,
        plan: Plan,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      description?: string | null,
      start_date?: string | null,
      growing_id: string,
      ref_growing?:  {
        __typename: "Growing",
        id: string,
        lifeform: string,
        family: string,
        species: string,
        name_ja?: string | null,
        family_ja?: string | null,
        link_ja?: string | null,
        name_en?: string | null,
        link_en?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ref_waterings?:  {
        __typename: "ModelWateringConnection",
        nextToken?: string | null,
      } | null,
      ref_field_works?:  {
        __typename: "ModelFieldWorkConnection",
        nextToken?: string | null,
      } | null,
      ref_datasets?:  {
        __typename: "ModelDatasetConnection",
        nextToken?: string | null,
      } | null,
      ref_field_notes?:  {
        __typename: "ModelFieldNoteConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    field_work_id: string,
    ref_field_work?:  {
      __typename: "FieldWork",
      group_id: string,
      id: string,
      work_type: WorkType,
      display_name?: string | null,
      cultivation_id: string,
      ref_cultivation?:  {
        __typename: "Cultivation",
        group_id: string,
        id: string,
        description?: string | null,
        start_date?: string | null,
        growing_id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      plan_date_start?: string | null,
      plan_date_end?: string | null,
      plan_description?: string | null,
      amount?: number | null,
      unit?: Unit | null,
      ref_field_notes?:  {
        __typename: "ModelFieldNoteConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListFieldNotesQueryVariables = {
  group_id?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelFieldNoteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListFieldNotesQuery = {
  listFieldNotes?:  {
    __typename: "ModelFieldNoteConnection",
    items:  Array< {
      __typename: "FieldNote",
      group_id: string,
      id: string,
      note_date_time?: string | null,
      note?: string | null,
      image_key?: string | null,
      cultivation_id: string,
      ref_cultivation?:  {
        __typename: "Cultivation",
        group_id: string,
        id: string,
        description?: string | null,
        start_date?: string | null,
        growing_id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      field_work_id: string,
      ref_field_work?:  {
        __typename: "FieldWork",
        group_id: string,
        id: string,
        work_type: WorkType,
        display_name?: string | null,
        cultivation_id: string,
        plan_date_start?: string | null,
        plan_date_end?: string | null,
        plan_description?: string | null,
        amount?: number | null,
        unit?: Unit | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFieldWorkQueryVariables = {
  group_id?: string,
  id?: string,
};

export type GetFieldWorkQuery = {
  getFieldWork?:  {
    __typename: "FieldWork",
    group_id: string,
    id: string,
    work_type: WorkType,
    display_name?: string | null,
    cultivation_id: string,
    ref_cultivation?:  {
      __typename: "Cultivation",
      group_id: string,
      ref_group?:  {
        __typename: "Group",
        id: string,
        display_name: string,
        owner_user_id: string,
        plan: Plan,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      description?: string | null,
      start_date?: string | null,
      growing_id: string,
      ref_growing?:  {
        __typename: "Growing",
        id: string,
        lifeform: string,
        family: string,
        species: string,
        name_ja?: string | null,
        family_ja?: string | null,
        link_ja?: string | null,
        name_en?: string | null,
        link_en?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ref_waterings?:  {
        __typename: "ModelWateringConnection",
        nextToken?: string | null,
      } | null,
      ref_field_works?:  {
        __typename: "ModelFieldWorkConnection",
        nextToken?: string | null,
      } | null,
      ref_datasets?:  {
        __typename: "ModelDatasetConnection",
        nextToken?: string | null,
      } | null,
      ref_field_notes?:  {
        __typename: "ModelFieldNoteConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    plan_date_start?: string | null,
    plan_date_end?: string | null,
    plan_description?: string | null,
    amount?: number | null,
    unit?: Unit | null,
    ref_field_notes?:  {
      __typename: "ModelFieldNoteConnection",
      items:  Array< {
        __typename: "FieldNote",
        group_id: string,
        id: string,
        note_date_time?: string | null,
        note?: string | null,
        image_key?: string | null,
        cultivation_id: string,
        field_work_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListFieldWorksQueryVariables = {
  group_id?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelFieldWorkFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListFieldWorksQuery = {
  listFieldWorks?:  {
    __typename: "ModelFieldWorkConnection",
    items:  Array< {
      __typename: "FieldWork",
      group_id: string,
      id: string,
      work_type: WorkType,
      display_name?: string | null,
      cultivation_id: string,
      ref_cultivation?:  {
        __typename: "Cultivation",
        group_id: string,
        id: string,
        description?: string | null,
        start_date?: string | null,
        growing_id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      plan_date_start?: string | null,
      plan_date_end?: string | null,
      plan_description?: string | null,
      amount?: number | null,
      unit?: Unit | null,
      ref_field_notes?:  {
        __typename: "ModelFieldNoteConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCultivationQueryVariables = {
  group_id?: string,
  id?: string,
};

export type GetCultivationQuery = {
  getCultivation?:  {
    __typename: "Cultivation",
    group_id: string,
    ref_group?:  {
      __typename: "Group",
      id: string,
      display_name: string,
      owner_user_id: string,
      ref_owner?:  {
        __typename: "User",
        id: string,
        line_user_id: string,
        email: string,
        username: string,
        sub: string,
        nickname?: string | null,
        profile?: string | null,
        image_key?: string | null,
        stripe_customer_id?: string | null,
        last4?: string | null,
        active_subscription_items?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      plan: Plan,
      ref_users?:  {
        __typename: "ModelUserGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    id: string,
    description?: string | null,
    start_date?: string | null,
    growing_id: string,
    ref_growing?:  {
      __typename: "Growing",
      id: string,
      lifeform: string,
      family: string,
      species: string,
      name_ja?: string | null,
      family_ja?: string | null,
      link_ja?: string | null,
      name_en?: string | null,
      link_en?: string | null,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ref_waterings?:  {
      __typename: "ModelWateringConnection",
      items:  Array< {
        __typename: "Watering",
        group_id: string,
        id: string,
        display_name?: string | null,
        watering_type?: WateringType | null,
        enabled: boolean,
        span_min?: number | null,
        span_max?: number | null,
        period_start_time?: string | null,
        period_end_time?: string | null,
        fixed_span?: number | null,
        every?: number | null,
        target_metric?: string | null,
        target_value?: number | null,
        kp?: number | null,
        ki?: number | null,
        kd?: number | null,
        _last_time?: number | null,
        _last_output?: number | null,
        _last_input?: number | null,
        cultivation_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ref_field_works?:  {
      __typename: "ModelFieldWorkConnection",
      items:  Array< {
        __typename: "FieldWork",
        group_id: string,
        id: string,
        work_type: WorkType,
        display_name?: string | null,
        cultivation_id: string,
        plan_date_start?: string | null,
        plan_date_end?: string | null,
        plan_description?: string | null,
        amount?: number | null,
        unit?: Unit | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ref_datasets?:  {
      __typename: "ModelDatasetConnection",
      items:  Array< {
        __typename: "Dataset",
        group_id: string,
        id: string,
        data_type?: DataType | null,
        timezone?: string | null,
        description?: string | null,
        cultivation_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ref_field_notes?:  {
      __typename: "ModelFieldNoteConnection",
      items:  Array< {
        __typename: "FieldNote",
        group_id: string,
        id: string,
        note_date_time?: string | null,
        note?: string | null,
        image_key?: string | null,
        cultivation_id: string,
        field_work_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCultivationsQueryVariables = {
  group_id?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelCultivationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCultivationsQuery = {
  listCultivations?:  {
    __typename: "ModelCultivationConnection",
    items:  Array< {
      __typename: "Cultivation",
      group_id: string,
      ref_group?:  {
        __typename: "Group",
        id: string,
        display_name: string,
        owner_user_id: string,
        plan: Plan,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      description?: string | null,
      start_date?: string | null,
      growing_id: string,
      ref_growing?:  {
        __typename: "Growing",
        id: string,
        lifeform: string,
        family: string,
        species: string,
        name_ja?: string | null,
        family_ja?: string | null,
        link_ja?: string | null,
        name_en?: string | null,
        link_en?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ref_waterings?:  {
        __typename: "ModelWateringConnection",
        nextToken?: string | null,
      } | null,
      ref_field_works?:  {
        __typename: "ModelFieldWorkConnection",
        nextToken?: string | null,
      } | null,
      ref_datasets?:  {
        __typename: "ModelDatasetConnection",
        nextToken?: string | null,
      } | null,
      ref_field_notes?:  {
        __typename: "ModelFieldNoteConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetWateringQueryVariables = {
  group_id?: string,
  id?: string,
};

export type GetWateringQuery = {
  getWatering?:  {
    __typename: "Watering",
    group_id: string,
    id: string,
    display_name?: string | null,
    watering_type?: WateringType | null,
    enabled: boolean,
    span_min?: number | null,
    span_max?: number | null,
    period_start_time?: string | null,
    period_end_time?: string | null,
    fixed_span?: number | null,
    every?: number | null,
    target_metric?: string | null,
    target_value?: number | null,
    kp?: number | null,
    ki?: number | null,
    kd?: number | null,
    _last_time?: number | null,
    _last_output?: number | null,
    _last_input?: number | null,
    cultivation_id: string,
    ref_cultivation?:  {
      __typename: "Cultivation",
      group_id: string,
      ref_group?:  {
        __typename: "Group",
        id: string,
        display_name: string,
        owner_user_id: string,
        plan: Plan,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      description?: string | null,
      start_date?: string | null,
      growing_id: string,
      ref_growing?:  {
        __typename: "Growing",
        id: string,
        lifeform: string,
        family: string,
        species: string,
        name_ja?: string | null,
        family_ja?: string | null,
        link_ja?: string | null,
        name_en?: string | null,
        link_en?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ref_waterings?:  {
        __typename: "ModelWateringConnection",
        nextToken?: string | null,
      } | null,
      ref_field_works?:  {
        __typename: "ModelFieldWorkConnection",
        nextToken?: string | null,
      } | null,
      ref_datasets?:  {
        __typename: "ModelDatasetConnection",
        nextToken?: string | null,
      } | null,
      ref_field_notes?:  {
        __typename: "ModelFieldNoteConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ref_devices?:  {
      __typename: "ModelDeviceWateringConnection",
      items:  Array< {
        __typename: "DeviceWatering",
        group_id: string,
        device_id: string,
        watering_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListWateringsQueryVariables = {
  group_id?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelWateringFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListWateringsQuery = {
  listWaterings?:  {
    __typename: "ModelWateringConnection",
    items:  Array< {
      __typename: "Watering",
      group_id: string,
      id: string,
      display_name?: string | null,
      watering_type?: WateringType | null,
      enabled: boolean,
      span_min?: number | null,
      span_max?: number | null,
      period_start_time?: string | null,
      period_end_time?: string | null,
      fixed_span?: number | null,
      every?: number | null,
      target_metric?: string | null,
      target_value?: number | null,
      kp?: number | null,
      ki?: number | null,
      kd?: number | null,
      _last_time?: number | null,
      _last_output?: number | null,
      _last_input?: number | null,
      cultivation_id: string,
      ref_cultivation?:  {
        __typename: "Cultivation",
        group_id: string,
        id: string,
        description?: string | null,
        start_date?: string | null,
        growing_id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      ref_devices?:  {
        __typename: "ModelDeviceWateringConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDatasetQueryVariables = {
  group_id?: string,
  id?: string,
};

export type GetDatasetQuery = {
  getDataset?:  {
    __typename: "Dataset",
    group_id: string,
    id: string,
    data_type?: DataType | null,
    timezone?: string | null,
    column_map?:  Array< {
      __typename: "ColumnMap",
      key: string,
      name: string,
    } | null > | null,
    description?: string | null,
    cultivation_id: string,
    ref_cultivation?:  {
      __typename: "Cultivation",
      group_id: string,
      ref_group?:  {
        __typename: "Group",
        id: string,
        display_name: string,
        owner_user_id: string,
        plan: Plan,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      description?: string | null,
      start_date?: string | null,
      growing_id: string,
      ref_growing?:  {
        __typename: "Growing",
        id: string,
        lifeform: string,
        family: string,
        species: string,
        name_ja?: string | null,
        family_ja?: string | null,
        link_ja?: string | null,
        name_en?: string | null,
        link_en?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ref_waterings?:  {
        __typename: "ModelWateringConnection",
        nextToken?: string | null,
      } | null,
      ref_field_works?:  {
        __typename: "ModelFieldWorkConnection",
        nextToken?: string | null,
      } | null,
      ref_datasets?:  {
        __typename: "ModelDatasetConnection",
        nextToken?: string | null,
      } | null,
      ref_field_notes?:  {
        __typename: "ModelFieldNoteConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ref_devices?:  {
      __typename: "ModelDeviceDatasetConnection",
      items:  Array< {
        __typename: "DeviceDataset",
        group_id: string,
        device_id: string,
        dataset_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDatasetsQueryVariables = {
  group_id?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelDatasetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDatasetsQuery = {
  listDatasets?:  {
    __typename: "ModelDatasetConnection",
    items:  Array< {
      __typename: "Dataset",
      group_id: string,
      id: string,
      data_type?: DataType | null,
      timezone?: string | null,
      column_map?:  Array< {
        __typename: "ColumnMap",
        key: string,
        name: string,
      } | null > | null,
      description?: string | null,
      cultivation_id: string,
      ref_cultivation?:  {
        __typename: "Cultivation",
        group_id: string,
        id: string,
        description?: string | null,
        start_date?: string | null,
        growing_id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      ref_devices?:  {
        __typename: "ModelDeviceDatasetConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDeviceQueryVariables = {
  group_id?: string,
  id?: string,
};

export type GetDeviceQuery = {
  getDevice?:  {
    __typename: "Device",
    group_id: string,
    id: string,
    display_name?: string | null,
    device_type?: DeviceType | null,
    description?: string | null,
    enabled: boolean,
    certificate?: string | null,
    private_key?: string | null,
    endpoint?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDevicesQueryVariables = {
  group_id?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelDeviceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDevicesQuery = {
  listDevices?:  {
    __typename: "ModelDeviceConnection",
    items:  Array< {
      __typename: "Device",
      group_id: string,
      id: string,
      display_name?: string | null,
      device_type?: DeviceType | null,
      description?: string | null,
      enabled: boolean,
      certificate?: string | null,
      private_key?: string | null,
      endpoint?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDeviceDatasetQueryVariables = {
  group_id?: string,
  device_id?: string,
  dataset_id?: string,
};

export type GetDeviceDatasetQuery = {
  getDeviceDataset?:  {
    __typename: "DeviceDataset",
    group_id: string,
    device_id: string,
    dataset_id: string,
    ref_device?:  {
      __typename: "Device",
      group_id: string,
      id: string,
      display_name?: string | null,
      device_type?: DeviceType | null,
      description?: string | null,
      enabled: boolean,
      certificate?: string | null,
      private_key?: string | null,
      endpoint?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDeviceDatasetsQueryVariables = {
  group_id?: string | null,
  device_idDataset_id?: ModelDeviceDatasetPrimaryCompositeKeyConditionInput | null,
  filter?: ModelDeviceDatasetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDeviceDatasetsQuery = {
  listDeviceDatasets?:  {
    __typename: "ModelDeviceDatasetConnection",
    items:  Array< {
      __typename: "DeviceDataset",
      group_id: string,
      device_id: string,
      dataset_id: string,
      ref_device?:  {
        __typename: "Device",
        group_id: string,
        id: string,
        display_name?: string | null,
        device_type?: DeviceType | null,
        description?: string | null,
        enabled: boolean,
        certificate?: string | null,
        private_key?: string | null,
        endpoint?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDeviceWateringQueryVariables = {
  group_id?: string,
  device_id?: string,
  watering_id?: string,
};

export type GetDeviceWateringQuery = {
  getDeviceWatering?:  {
    __typename: "DeviceWatering",
    group_id: string,
    device_id: string,
    watering_id: string,
    ref_device?:  {
      __typename: "Device",
      group_id: string,
      id: string,
      display_name?: string | null,
      device_type?: DeviceType | null,
      description?: string | null,
      enabled: boolean,
      certificate?: string | null,
      private_key?: string | null,
      endpoint?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDeviceWateringsQueryVariables = {
  group_id?: string | null,
  device_idWatering_id?: ModelDeviceWateringPrimaryCompositeKeyConditionInput | null,
  filter?: ModelDeviceWateringFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDeviceWateringsQuery = {
  listDeviceWaterings?:  {
    __typename: "ModelDeviceWateringConnection",
    items:  Array< {
      __typename: "DeviceWatering",
      group_id: string,
      device_id: string,
      watering_id: string,
      ref_device?:  {
        __typename: "Device",
        group_id: string,
        id: string,
        display_name?: string | null,
        device_type?: DeviceType | null,
        description?: string | null,
        enabled: boolean,
        certificate?: string | null,
        private_key?: string | null,
        endpoint?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSensorDataQueryVariables = {
  dataset_id?: string,
  keytime?: number,
};

export type GetSensorDataQuery = {
  getSensorData?:  {
    __typename: "SensorData",
    group_id: string,
    dataset_id: string,
    keytime: number,
    ttl: number,
    year: number,
    month: number,
    date: string,
    hour: number,
    datetime: string,
    v0?: number | null,
    v1?: number | null,
    v2?: number | null,
    v3?: number | null,
    v4?: number | null,
    v5?: number | null,
    v6?: number | null,
    v7?: number | null,
    v8?: number | null,
    v9?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSensorDatasQueryVariables = {
  dataset_id?: string | null,
  keytime?: ModelIntKeyConditionInput | null,
  filter?: ModelSensorDataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListSensorDatasQuery = {
  listSensorDatas?:  {
    __typename: "ModelSensorDataConnection",
    items:  Array< {
      __typename: "SensorData",
      group_id: string,
      dataset_id: string,
      keytime: number,
      ttl: number,
      year: number,
      month: number,
      date: string,
      hour: number,
      datetime: string,
      v0?: number | null,
      v1?: number | null,
      v2?: number | null,
      v3?: number | null,
      v4?: number | null,
      v5?: number | null,
      v6?: number | null,
      v7?: number | null,
      v8?: number | null,
      v9?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetWateringHistoryQueryVariables = {
  watering_id?: string,
  keytime?: number,
};

export type GetWateringHistoryQuery = {
  getWateringHistory?:  {
    __typename: "WateringHistory",
    group_id: string,
    watering_id: string,
    keytime: number,
    ttl: number,
    year: number,
    month: number,
    date: string,
    hour: number,
    datetime: string,
    span: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListWateringHistorysQueryVariables = {
  watering_id?: string | null,
  keytime?: ModelIntKeyConditionInput | null,
  filter?: ModelWateringHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListWateringHistorysQuery = {
  listWateringHistorys?:  {
    __typename: "ModelWateringHistoryConnection",
    items:  Array< {
      __typename: "WateringHistory",
      group_id: string,
      watering_id: string,
      keytime: number,
      ttl: number,
      year: number,
      month: number,
      date: string,
      hour: number,
      datetime: string,
      span: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetControlPlaneQueryVariables = {
  group_id?: string,
  id?: string,
};

export type GetControlPlaneQuery = {
  getControlPlane?:  {
    __typename: "ControlPlane",
    group_id: string,
    ref_group?:  {
      __typename: "Group",
      id: string,
      display_name: string,
      owner_user_id: string,
      ref_owner?:  {
        __typename: "User",
        id: string,
        line_user_id: string,
        email: string,
        username: string,
        sub: string,
        nickname?: string | null,
        profile?: string | null,
        image_key?: string | null,
        stripe_customer_id?: string | null,
        last4?: string | null,
        active_subscription_items?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      plan: Plan,
      ref_users?:  {
        __typename: "ModelUserGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    id: string,
    display_name: string,
    status: ControlPlaneStatus,
    ssm_activation_code?: string | null,
    ssm_activation_id?: string | null,
    info?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListControlPlanesQueryVariables = {
  group_id?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelControlPlaneFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListControlPlanesQuery = {
  listControlPlanes?:  {
    __typename: "ModelControlPlaneConnection",
    items:  Array< {
      __typename: "ControlPlane",
      group_id: string,
      ref_group?:  {
        __typename: "Group",
        id: string,
        display_name: string,
        owner_user_id: string,
        plan: Plan,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      display_name: string,
      status: ControlPlaneStatus,
      ssm_activation_code?: string | null,
      ssm_activation_id?: string | null,
      info?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUsageQueryVariables = {
  group_id?: string,
  price_id?: string,
  keytime?: number,
};

export type GetUsageQuery = {
  getUsage?:  {
    __typename: "Usage",
    group_id: string,
    price_id: string,
    keytime: number,
    year: number,
    month: number,
    date: string,
    datetime: string,
    volume: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUsagesQueryVariables = {
  group_id?: string | null,
  price_idKeytime?: ModelUsagePrimaryCompositeKeyConditionInput | null,
  filter?: ModelUsageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUsagesQuery = {
  listUsages?:  {
    __typename: "ModelUsageConnection",
    items:  Array< {
      __typename: "Usage",
      group_id: string,
      price_id: string,
      keytime: number,
      year: number,
      month: number,
      date: string,
      datetime: string,
      volume: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateGroupSubscriptionVariables = {
  owner_user_id?: string,
};

export type OnCreateGroupSubscription = {
  onCreateGroup?:  {
    __typename: "Group",
    id: string,
    display_name: string,
    owner_user_id: string,
    ref_owner?:  {
      __typename: "User",
      id: string,
      line_user_id: string,
      email: string,
      username: string,
      sub: string,
      ref_line_user?:  {
        __typename: "LineUser",
        id: string,
        enabled: boolean,
        line_user_name?: string | null,
        profile?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      nickname?: string | null,
      profile?: string | null,
      image_key?: string | null,
      stripe_customer_id?: string | null,
      last4?: string | null,
      active_subscription_items?: Array< string | null > | null,
      ref_groups?:  {
        __typename: "ModelUserGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    plan: Plan,
    ref_users?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        group_id: string,
        user_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateGroupSubscriptionVariables = {
  owner_user_id?: string,
};

export type OnUpdateGroupSubscription = {
  onUpdateGroup?:  {
    __typename: "Group",
    id: string,
    display_name: string,
    owner_user_id: string,
    ref_owner?:  {
      __typename: "User",
      id: string,
      line_user_id: string,
      email: string,
      username: string,
      sub: string,
      ref_line_user?:  {
        __typename: "LineUser",
        id: string,
        enabled: boolean,
        line_user_name?: string | null,
        profile?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      nickname?: string | null,
      profile?: string | null,
      image_key?: string | null,
      stripe_customer_id?: string | null,
      last4?: string | null,
      active_subscription_items?: Array< string | null > | null,
      ref_groups?:  {
        __typename: "ModelUserGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    plan: Plan,
    ref_users?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        group_id: string,
        user_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteGroupSubscriptionVariables = {
  owner_user_id?: string,
};

export type OnDeleteGroupSubscription = {
  onDeleteGroup?:  {
    __typename: "Group",
    id: string,
    display_name: string,
    owner_user_id: string,
    ref_owner?:  {
      __typename: "User",
      id: string,
      line_user_id: string,
      email: string,
      username: string,
      sub: string,
      ref_line_user?:  {
        __typename: "LineUser",
        id: string,
        enabled: boolean,
        line_user_name?: string | null,
        profile?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      nickname?: string | null,
      profile?: string | null,
      image_key?: string | null,
      stripe_customer_id?: string | null,
      last4?: string | null,
      active_subscription_items?: Array< string | null > | null,
      ref_groups?:  {
        __typename: "ModelUserGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    plan: Plan,
    ref_users?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        group_id: string,
        user_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    line_user_id: string,
    email: string,
    username: string,
    sub: string,
    ref_line_user?:  {
      __typename: "LineUser",
      id: string,
      enabled: boolean,
      line_user_name?: string | null,
      profile?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    nickname?: string | null,
    profile?: string | null,
    image_key?: string | null,
    stripe_customer_id?: string | null,
    last4?: string | null,
    active_subscription_items?: Array< string | null > | null,
    ref_groups?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        group_id: string,
        user_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    line_user_id: string,
    email: string,
    username: string,
    sub: string,
    ref_line_user?:  {
      __typename: "LineUser",
      id: string,
      enabled: boolean,
      line_user_name?: string | null,
      profile?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    nickname?: string | null,
    profile?: string | null,
    image_key?: string | null,
    stripe_customer_id?: string | null,
    last4?: string | null,
    active_subscription_items?: Array< string | null > | null,
    ref_groups?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        group_id: string,
        user_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    line_user_id: string,
    email: string,
    username: string,
    sub: string,
    ref_line_user?:  {
      __typename: "LineUser",
      id: string,
      enabled: boolean,
      line_user_name?: string | null,
      profile?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    nickname?: string | null,
    profile?: string | null,
    image_key?: string | null,
    stripe_customer_id?: string | null,
    last4?: string | null,
    active_subscription_items?: Array< string | null > | null,
    ref_groups?:  {
      __typename: "ModelUserGroupConnection",
      items:  Array< {
        __typename: "UserGroup",
        id: string,
        group_id: string,
        user_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserGroupSubscriptionVariables = {
  user_id?: string,
};

export type OnCreateUserGroupSubscription = {
  onCreateUserGroup?:  {
    __typename: "UserGroup",
    id: string,
    group_id: string,
    user_id: string,
    ref_user?:  {
      __typename: "User",
      id: string,
      line_user_id: string,
      email: string,
      username: string,
      sub: string,
      ref_line_user?:  {
        __typename: "LineUser",
        id: string,
        enabled: boolean,
        line_user_name?: string | null,
        profile?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      nickname?: string | null,
      profile?: string | null,
      image_key?: string | null,
      stripe_customer_id?: string | null,
      last4?: string | null,
      active_subscription_items?: Array< string | null > | null,
      ref_groups?:  {
        __typename: "ModelUserGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ref_group?:  {
      __typename: "Group",
      id: string,
      display_name: string,
      owner_user_id: string,
      ref_owner?:  {
        __typename: "User",
        id: string,
        line_user_id: string,
        email: string,
        username: string,
        sub: string,
        nickname?: string | null,
        profile?: string | null,
        image_key?: string | null,
        stripe_customer_id?: string | null,
        last4?: string | null,
        active_subscription_items?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      plan: Plan,
      ref_users?:  {
        __typename: "ModelUserGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserGroupSubscriptionVariables = {
  user_id?: string,
};

export type OnUpdateUserGroupSubscription = {
  onUpdateUserGroup?:  {
    __typename: "UserGroup",
    id: string,
    group_id: string,
    user_id: string,
    ref_user?:  {
      __typename: "User",
      id: string,
      line_user_id: string,
      email: string,
      username: string,
      sub: string,
      ref_line_user?:  {
        __typename: "LineUser",
        id: string,
        enabled: boolean,
        line_user_name?: string | null,
        profile?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      nickname?: string | null,
      profile?: string | null,
      image_key?: string | null,
      stripe_customer_id?: string | null,
      last4?: string | null,
      active_subscription_items?: Array< string | null > | null,
      ref_groups?:  {
        __typename: "ModelUserGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ref_group?:  {
      __typename: "Group",
      id: string,
      display_name: string,
      owner_user_id: string,
      ref_owner?:  {
        __typename: "User",
        id: string,
        line_user_id: string,
        email: string,
        username: string,
        sub: string,
        nickname?: string | null,
        profile?: string | null,
        image_key?: string | null,
        stripe_customer_id?: string | null,
        last4?: string | null,
        active_subscription_items?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      plan: Plan,
      ref_users?:  {
        __typename: "ModelUserGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserGroupSubscriptionVariables = {
  user_id?: string,
};

export type OnDeleteUserGroupSubscription = {
  onDeleteUserGroup?:  {
    __typename: "UserGroup",
    id: string,
    group_id: string,
    user_id: string,
    ref_user?:  {
      __typename: "User",
      id: string,
      line_user_id: string,
      email: string,
      username: string,
      sub: string,
      ref_line_user?:  {
        __typename: "LineUser",
        id: string,
        enabled: boolean,
        line_user_name?: string | null,
        profile?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      nickname?: string | null,
      profile?: string | null,
      image_key?: string | null,
      stripe_customer_id?: string | null,
      last4?: string | null,
      active_subscription_items?: Array< string | null > | null,
      ref_groups?:  {
        __typename: "ModelUserGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ref_group?:  {
      __typename: "Group",
      id: string,
      display_name: string,
      owner_user_id: string,
      ref_owner?:  {
        __typename: "User",
        id: string,
        line_user_id: string,
        email: string,
        username: string,
        sub: string,
        nickname?: string | null,
        profile?: string | null,
        image_key?: string | null,
        stripe_customer_id?: string | null,
        last4?: string | null,
        active_subscription_items?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      plan: Plan,
      ref_users?:  {
        __typename: "ModelUserGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateLineUserSubscription = {
  onCreateLineUser?:  {
    __typename: "LineUser",
    id: string,
    enabled: boolean,
    line_user_name?: string | null,
    profile?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateLineUserSubscription = {
  onUpdateLineUser?:  {
    __typename: "LineUser",
    id: string,
    enabled: boolean,
    line_user_name?: string | null,
    profile?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteLineUserSubscription = {
  onDeleteLineUser?:  {
    __typename: "LineUser",
    id: string,
    enabled: boolean,
    line_user_name?: string | null,
    profile?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateGrowingSubscription = {
  onCreateGrowing?:  {
    __typename: "Growing",
    id: string,
    lifeform: string,
    family: string,
    species: string,
    name_ja?: string | null,
    family_ja?: string | null,
    link_ja?: string | null,
    name_en?: string | null,
    link_en?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateGrowingSubscription = {
  onUpdateGrowing?:  {
    __typename: "Growing",
    id: string,
    lifeform: string,
    family: string,
    species: string,
    name_ja?: string | null,
    family_ja?: string | null,
    link_ja?: string | null,
    name_en?: string | null,
    link_en?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteGrowingSubscription = {
  onDeleteGrowing?:  {
    __typename: "Growing",
    id: string,
    lifeform: string,
    family: string,
    species: string,
    name_ja?: string | null,
    family_ja?: string | null,
    link_ja?: string | null,
    name_en?: string | null,
    link_en?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateFieldNoteSubscription = {
  onCreateFieldNote?:  {
    __typename: "FieldNote",
    group_id: string,
    id: string,
    note_date_time?: string | null,
    note?: string | null,
    image_key?: string | null,
    cultivation_id: string,
    ref_cultivation?:  {
      __typename: "Cultivation",
      group_id: string,
      ref_group?:  {
        __typename: "Group",
        id: string,
        display_name: string,
        owner_user_id: string,
        plan: Plan,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      description?: string | null,
      start_date?: string | null,
      growing_id: string,
      ref_growing?:  {
        __typename: "Growing",
        id: string,
        lifeform: string,
        family: string,
        species: string,
        name_ja?: string | null,
        family_ja?: string | null,
        link_ja?: string | null,
        name_en?: string | null,
        link_en?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ref_waterings?:  {
        __typename: "ModelWateringConnection",
        nextToken?: string | null,
      } | null,
      ref_field_works?:  {
        __typename: "ModelFieldWorkConnection",
        nextToken?: string | null,
      } | null,
      ref_datasets?:  {
        __typename: "ModelDatasetConnection",
        nextToken?: string | null,
      } | null,
      ref_field_notes?:  {
        __typename: "ModelFieldNoteConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    field_work_id: string,
    ref_field_work?:  {
      __typename: "FieldWork",
      group_id: string,
      id: string,
      work_type: WorkType,
      display_name?: string | null,
      cultivation_id: string,
      ref_cultivation?:  {
        __typename: "Cultivation",
        group_id: string,
        id: string,
        description?: string | null,
        start_date?: string | null,
        growing_id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      plan_date_start?: string | null,
      plan_date_end?: string | null,
      plan_description?: string | null,
      amount?: number | null,
      unit?: Unit | null,
      ref_field_notes?:  {
        __typename: "ModelFieldNoteConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateFieldNoteSubscription = {
  onUpdateFieldNote?:  {
    __typename: "FieldNote",
    group_id: string,
    id: string,
    note_date_time?: string | null,
    note?: string | null,
    image_key?: string | null,
    cultivation_id: string,
    ref_cultivation?:  {
      __typename: "Cultivation",
      group_id: string,
      ref_group?:  {
        __typename: "Group",
        id: string,
        display_name: string,
        owner_user_id: string,
        plan: Plan,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      description?: string | null,
      start_date?: string | null,
      growing_id: string,
      ref_growing?:  {
        __typename: "Growing",
        id: string,
        lifeform: string,
        family: string,
        species: string,
        name_ja?: string | null,
        family_ja?: string | null,
        link_ja?: string | null,
        name_en?: string | null,
        link_en?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ref_waterings?:  {
        __typename: "ModelWateringConnection",
        nextToken?: string | null,
      } | null,
      ref_field_works?:  {
        __typename: "ModelFieldWorkConnection",
        nextToken?: string | null,
      } | null,
      ref_datasets?:  {
        __typename: "ModelDatasetConnection",
        nextToken?: string | null,
      } | null,
      ref_field_notes?:  {
        __typename: "ModelFieldNoteConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    field_work_id: string,
    ref_field_work?:  {
      __typename: "FieldWork",
      group_id: string,
      id: string,
      work_type: WorkType,
      display_name?: string | null,
      cultivation_id: string,
      ref_cultivation?:  {
        __typename: "Cultivation",
        group_id: string,
        id: string,
        description?: string | null,
        start_date?: string | null,
        growing_id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      plan_date_start?: string | null,
      plan_date_end?: string | null,
      plan_description?: string | null,
      amount?: number | null,
      unit?: Unit | null,
      ref_field_notes?:  {
        __typename: "ModelFieldNoteConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteFieldNoteSubscription = {
  onDeleteFieldNote?:  {
    __typename: "FieldNote",
    group_id: string,
    id: string,
    note_date_time?: string | null,
    note?: string | null,
    image_key?: string | null,
    cultivation_id: string,
    ref_cultivation?:  {
      __typename: "Cultivation",
      group_id: string,
      ref_group?:  {
        __typename: "Group",
        id: string,
        display_name: string,
        owner_user_id: string,
        plan: Plan,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      description?: string | null,
      start_date?: string | null,
      growing_id: string,
      ref_growing?:  {
        __typename: "Growing",
        id: string,
        lifeform: string,
        family: string,
        species: string,
        name_ja?: string | null,
        family_ja?: string | null,
        link_ja?: string | null,
        name_en?: string | null,
        link_en?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ref_waterings?:  {
        __typename: "ModelWateringConnection",
        nextToken?: string | null,
      } | null,
      ref_field_works?:  {
        __typename: "ModelFieldWorkConnection",
        nextToken?: string | null,
      } | null,
      ref_datasets?:  {
        __typename: "ModelDatasetConnection",
        nextToken?: string | null,
      } | null,
      ref_field_notes?:  {
        __typename: "ModelFieldNoteConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    field_work_id: string,
    ref_field_work?:  {
      __typename: "FieldWork",
      group_id: string,
      id: string,
      work_type: WorkType,
      display_name?: string | null,
      cultivation_id: string,
      ref_cultivation?:  {
        __typename: "Cultivation",
        group_id: string,
        id: string,
        description?: string | null,
        start_date?: string | null,
        growing_id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      plan_date_start?: string | null,
      plan_date_end?: string | null,
      plan_description?: string | null,
      amount?: number | null,
      unit?: Unit | null,
      ref_field_notes?:  {
        __typename: "ModelFieldNoteConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateFieldWorkSubscription = {
  onCreateFieldWork?:  {
    __typename: "FieldWork",
    group_id: string,
    id: string,
    work_type: WorkType,
    display_name?: string | null,
    cultivation_id: string,
    ref_cultivation?:  {
      __typename: "Cultivation",
      group_id: string,
      ref_group?:  {
        __typename: "Group",
        id: string,
        display_name: string,
        owner_user_id: string,
        plan: Plan,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      description?: string | null,
      start_date?: string | null,
      growing_id: string,
      ref_growing?:  {
        __typename: "Growing",
        id: string,
        lifeform: string,
        family: string,
        species: string,
        name_ja?: string | null,
        family_ja?: string | null,
        link_ja?: string | null,
        name_en?: string | null,
        link_en?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ref_waterings?:  {
        __typename: "ModelWateringConnection",
        nextToken?: string | null,
      } | null,
      ref_field_works?:  {
        __typename: "ModelFieldWorkConnection",
        nextToken?: string | null,
      } | null,
      ref_datasets?:  {
        __typename: "ModelDatasetConnection",
        nextToken?: string | null,
      } | null,
      ref_field_notes?:  {
        __typename: "ModelFieldNoteConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    plan_date_start?: string | null,
    plan_date_end?: string | null,
    plan_description?: string | null,
    amount?: number | null,
    unit?: Unit | null,
    ref_field_notes?:  {
      __typename: "ModelFieldNoteConnection",
      items:  Array< {
        __typename: "FieldNote",
        group_id: string,
        id: string,
        note_date_time?: string | null,
        note?: string | null,
        image_key?: string | null,
        cultivation_id: string,
        field_work_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateFieldWorkSubscription = {
  onUpdateFieldWork?:  {
    __typename: "FieldWork",
    group_id: string,
    id: string,
    work_type: WorkType,
    display_name?: string | null,
    cultivation_id: string,
    ref_cultivation?:  {
      __typename: "Cultivation",
      group_id: string,
      ref_group?:  {
        __typename: "Group",
        id: string,
        display_name: string,
        owner_user_id: string,
        plan: Plan,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      description?: string | null,
      start_date?: string | null,
      growing_id: string,
      ref_growing?:  {
        __typename: "Growing",
        id: string,
        lifeform: string,
        family: string,
        species: string,
        name_ja?: string | null,
        family_ja?: string | null,
        link_ja?: string | null,
        name_en?: string | null,
        link_en?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ref_waterings?:  {
        __typename: "ModelWateringConnection",
        nextToken?: string | null,
      } | null,
      ref_field_works?:  {
        __typename: "ModelFieldWorkConnection",
        nextToken?: string | null,
      } | null,
      ref_datasets?:  {
        __typename: "ModelDatasetConnection",
        nextToken?: string | null,
      } | null,
      ref_field_notes?:  {
        __typename: "ModelFieldNoteConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    plan_date_start?: string | null,
    plan_date_end?: string | null,
    plan_description?: string | null,
    amount?: number | null,
    unit?: Unit | null,
    ref_field_notes?:  {
      __typename: "ModelFieldNoteConnection",
      items:  Array< {
        __typename: "FieldNote",
        group_id: string,
        id: string,
        note_date_time?: string | null,
        note?: string | null,
        image_key?: string | null,
        cultivation_id: string,
        field_work_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteFieldWorkSubscription = {
  onDeleteFieldWork?:  {
    __typename: "FieldWork",
    group_id: string,
    id: string,
    work_type: WorkType,
    display_name?: string | null,
    cultivation_id: string,
    ref_cultivation?:  {
      __typename: "Cultivation",
      group_id: string,
      ref_group?:  {
        __typename: "Group",
        id: string,
        display_name: string,
        owner_user_id: string,
        plan: Plan,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      description?: string | null,
      start_date?: string | null,
      growing_id: string,
      ref_growing?:  {
        __typename: "Growing",
        id: string,
        lifeform: string,
        family: string,
        species: string,
        name_ja?: string | null,
        family_ja?: string | null,
        link_ja?: string | null,
        name_en?: string | null,
        link_en?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ref_waterings?:  {
        __typename: "ModelWateringConnection",
        nextToken?: string | null,
      } | null,
      ref_field_works?:  {
        __typename: "ModelFieldWorkConnection",
        nextToken?: string | null,
      } | null,
      ref_datasets?:  {
        __typename: "ModelDatasetConnection",
        nextToken?: string | null,
      } | null,
      ref_field_notes?:  {
        __typename: "ModelFieldNoteConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    plan_date_start?: string | null,
    plan_date_end?: string | null,
    plan_description?: string | null,
    amount?: number | null,
    unit?: Unit | null,
    ref_field_notes?:  {
      __typename: "ModelFieldNoteConnection",
      items:  Array< {
        __typename: "FieldNote",
        group_id: string,
        id: string,
        note_date_time?: string | null,
        note?: string | null,
        image_key?: string | null,
        cultivation_id: string,
        field_work_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCultivationSubscription = {
  onCreateCultivation?:  {
    __typename: "Cultivation",
    group_id: string,
    ref_group?:  {
      __typename: "Group",
      id: string,
      display_name: string,
      owner_user_id: string,
      ref_owner?:  {
        __typename: "User",
        id: string,
        line_user_id: string,
        email: string,
        username: string,
        sub: string,
        nickname?: string | null,
        profile?: string | null,
        image_key?: string | null,
        stripe_customer_id?: string | null,
        last4?: string | null,
        active_subscription_items?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      plan: Plan,
      ref_users?:  {
        __typename: "ModelUserGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    id: string,
    description?: string | null,
    start_date?: string | null,
    growing_id: string,
    ref_growing?:  {
      __typename: "Growing",
      id: string,
      lifeform: string,
      family: string,
      species: string,
      name_ja?: string | null,
      family_ja?: string | null,
      link_ja?: string | null,
      name_en?: string | null,
      link_en?: string | null,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ref_waterings?:  {
      __typename: "ModelWateringConnection",
      items:  Array< {
        __typename: "Watering",
        group_id: string,
        id: string,
        display_name?: string | null,
        watering_type?: WateringType | null,
        enabled: boolean,
        span_min?: number | null,
        span_max?: number | null,
        period_start_time?: string | null,
        period_end_time?: string | null,
        fixed_span?: number | null,
        every?: number | null,
        target_metric?: string | null,
        target_value?: number | null,
        kp?: number | null,
        ki?: number | null,
        kd?: number | null,
        _last_time?: number | null,
        _last_output?: number | null,
        _last_input?: number | null,
        cultivation_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ref_field_works?:  {
      __typename: "ModelFieldWorkConnection",
      items:  Array< {
        __typename: "FieldWork",
        group_id: string,
        id: string,
        work_type: WorkType,
        display_name?: string | null,
        cultivation_id: string,
        plan_date_start?: string | null,
        plan_date_end?: string | null,
        plan_description?: string | null,
        amount?: number | null,
        unit?: Unit | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ref_datasets?:  {
      __typename: "ModelDatasetConnection",
      items:  Array< {
        __typename: "Dataset",
        group_id: string,
        id: string,
        data_type?: DataType | null,
        timezone?: string | null,
        description?: string | null,
        cultivation_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ref_field_notes?:  {
      __typename: "ModelFieldNoteConnection",
      items:  Array< {
        __typename: "FieldNote",
        group_id: string,
        id: string,
        note_date_time?: string | null,
        note?: string | null,
        image_key?: string | null,
        cultivation_id: string,
        field_work_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCultivationSubscription = {
  onUpdateCultivation?:  {
    __typename: "Cultivation",
    group_id: string,
    ref_group?:  {
      __typename: "Group",
      id: string,
      display_name: string,
      owner_user_id: string,
      ref_owner?:  {
        __typename: "User",
        id: string,
        line_user_id: string,
        email: string,
        username: string,
        sub: string,
        nickname?: string | null,
        profile?: string | null,
        image_key?: string | null,
        stripe_customer_id?: string | null,
        last4?: string | null,
        active_subscription_items?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      plan: Plan,
      ref_users?:  {
        __typename: "ModelUserGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    id: string,
    description?: string | null,
    start_date?: string | null,
    growing_id: string,
    ref_growing?:  {
      __typename: "Growing",
      id: string,
      lifeform: string,
      family: string,
      species: string,
      name_ja?: string | null,
      family_ja?: string | null,
      link_ja?: string | null,
      name_en?: string | null,
      link_en?: string | null,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ref_waterings?:  {
      __typename: "ModelWateringConnection",
      items:  Array< {
        __typename: "Watering",
        group_id: string,
        id: string,
        display_name?: string | null,
        watering_type?: WateringType | null,
        enabled: boolean,
        span_min?: number | null,
        span_max?: number | null,
        period_start_time?: string | null,
        period_end_time?: string | null,
        fixed_span?: number | null,
        every?: number | null,
        target_metric?: string | null,
        target_value?: number | null,
        kp?: number | null,
        ki?: number | null,
        kd?: number | null,
        _last_time?: number | null,
        _last_output?: number | null,
        _last_input?: number | null,
        cultivation_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ref_field_works?:  {
      __typename: "ModelFieldWorkConnection",
      items:  Array< {
        __typename: "FieldWork",
        group_id: string,
        id: string,
        work_type: WorkType,
        display_name?: string | null,
        cultivation_id: string,
        plan_date_start?: string | null,
        plan_date_end?: string | null,
        plan_description?: string | null,
        amount?: number | null,
        unit?: Unit | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ref_datasets?:  {
      __typename: "ModelDatasetConnection",
      items:  Array< {
        __typename: "Dataset",
        group_id: string,
        id: string,
        data_type?: DataType | null,
        timezone?: string | null,
        description?: string | null,
        cultivation_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ref_field_notes?:  {
      __typename: "ModelFieldNoteConnection",
      items:  Array< {
        __typename: "FieldNote",
        group_id: string,
        id: string,
        note_date_time?: string | null,
        note?: string | null,
        image_key?: string | null,
        cultivation_id: string,
        field_work_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCultivationSubscription = {
  onDeleteCultivation?:  {
    __typename: "Cultivation",
    group_id: string,
    ref_group?:  {
      __typename: "Group",
      id: string,
      display_name: string,
      owner_user_id: string,
      ref_owner?:  {
        __typename: "User",
        id: string,
        line_user_id: string,
        email: string,
        username: string,
        sub: string,
        nickname?: string | null,
        profile?: string | null,
        image_key?: string | null,
        stripe_customer_id?: string | null,
        last4?: string | null,
        active_subscription_items?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      plan: Plan,
      ref_users?:  {
        __typename: "ModelUserGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    id: string,
    description?: string | null,
    start_date?: string | null,
    growing_id: string,
    ref_growing?:  {
      __typename: "Growing",
      id: string,
      lifeform: string,
      family: string,
      species: string,
      name_ja?: string | null,
      family_ja?: string | null,
      link_ja?: string | null,
      name_en?: string | null,
      link_en?: string | null,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ref_waterings?:  {
      __typename: "ModelWateringConnection",
      items:  Array< {
        __typename: "Watering",
        group_id: string,
        id: string,
        display_name?: string | null,
        watering_type?: WateringType | null,
        enabled: boolean,
        span_min?: number | null,
        span_max?: number | null,
        period_start_time?: string | null,
        period_end_time?: string | null,
        fixed_span?: number | null,
        every?: number | null,
        target_metric?: string | null,
        target_value?: number | null,
        kp?: number | null,
        ki?: number | null,
        kd?: number | null,
        _last_time?: number | null,
        _last_output?: number | null,
        _last_input?: number | null,
        cultivation_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ref_field_works?:  {
      __typename: "ModelFieldWorkConnection",
      items:  Array< {
        __typename: "FieldWork",
        group_id: string,
        id: string,
        work_type: WorkType,
        display_name?: string | null,
        cultivation_id: string,
        plan_date_start?: string | null,
        plan_date_end?: string | null,
        plan_description?: string | null,
        amount?: number | null,
        unit?: Unit | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ref_datasets?:  {
      __typename: "ModelDatasetConnection",
      items:  Array< {
        __typename: "Dataset",
        group_id: string,
        id: string,
        data_type?: DataType | null,
        timezone?: string | null,
        description?: string | null,
        cultivation_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ref_field_notes?:  {
      __typename: "ModelFieldNoteConnection",
      items:  Array< {
        __typename: "FieldNote",
        group_id: string,
        id: string,
        note_date_time?: string | null,
        note?: string | null,
        image_key?: string | null,
        cultivation_id: string,
        field_work_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateWateringSubscription = {
  onCreateWatering?:  {
    __typename: "Watering",
    group_id: string,
    id: string,
    display_name?: string | null,
    watering_type?: WateringType | null,
    enabled: boolean,
    span_min?: number | null,
    span_max?: number | null,
    period_start_time?: string | null,
    period_end_time?: string | null,
    fixed_span?: number | null,
    every?: number | null,
    target_metric?: string | null,
    target_value?: number | null,
    kp?: number | null,
    ki?: number | null,
    kd?: number | null,
    _last_time?: number | null,
    _last_output?: number | null,
    _last_input?: number | null,
    cultivation_id: string,
    ref_cultivation?:  {
      __typename: "Cultivation",
      group_id: string,
      ref_group?:  {
        __typename: "Group",
        id: string,
        display_name: string,
        owner_user_id: string,
        plan: Plan,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      description?: string | null,
      start_date?: string | null,
      growing_id: string,
      ref_growing?:  {
        __typename: "Growing",
        id: string,
        lifeform: string,
        family: string,
        species: string,
        name_ja?: string | null,
        family_ja?: string | null,
        link_ja?: string | null,
        name_en?: string | null,
        link_en?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ref_waterings?:  {
        __typename: "ModelWateringConnection",
        nextToken?: string | null,
      } | null,
      ref_field_works?:  {
        __typename: "ModelFieldWorkConnection",
        nextToken?: string | null,
      } | null,
      ref_datasets?:  {
        __typename: "ModelDatasetConnection",
        nextToken?: string | null,
      } | null,
      ref_field_notes?:  {
        __typename: "ModelFieldNoteConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ref_devices?:  {
      __typename: "ModelDeviceWateringConnection",
      items:  Array< {
        __typename: "DeviceWatering",
        group_id: string,
        device_id: string,
        watering_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateWateringSubscription = {
  onUpdateWatering?:  {
    __typename: "Watering",
    group_id: string,
    id: string,
    display_name?: string | null,
    watering_type?: WateringType | null,
    enabled: boolean,
    span_min?: number | null,
    span_max?: number | null,
    period_start_time?: string | null,
    period_end_time?: string | null,
    fixed_span?: number | null,
    every?: number | null,
    target_metric?: string | null,
    target_value?: number | null,
    kp?: number | null,
    ki?: number | null,
    kd?: number | null,
    _last_time?: number | null,
    _last_output?: number | null,
    _last_input?: number | null,
    cultivation_id: string,
    ref_cultivation?:  {
      __typename: "Cultivation",
      group_id: string,
      ref_group?:  {
        __typename: "Group",
        id: string,
        display_name: string,
        owner_user_id: string,
        plan: Plan,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      description?: string | null,
      start_date?: string | null,
      growing_id: string,
      ref_growing?:  {
        __typename: "Growing",
        id: string,
        lifeform: string,
        family: string,
        species: string,
        name_ja?: string | null,
        family_ja?: string | null,
        link_ja?: string | null,
        name_en?: string | null,
        link_en?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ref_waterings?:  {
        __typename: "ModelWateringConnection",
        nextToken?: string | null,
      } | null,
      ref_field_works?:  {
        __typename: "ModelFieldWorkConnection",
        nextToken?: string | null,
      } | null,
      ref_datasets?:  {
        __typename: "ModelDatasetConnection",
        nextToken?: string | null,
      } | null,
      ref_field_notes?:  {
        __typename: "ModelFieldNoteConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ref_devices?:  {
      __typename: "ModelDeviceWateringConnection",
      items:  Array< {
        __typename: "DeviceWatering",
        group_id: string,
        device_id: string,
        watering_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteWateringSubscription = {
  onDeleteWatering?:  {
    __typename: "Watering",
    group_id: string,
    id: string,
    display_name?: string | null,
    watering_type?: WateringType | null,
    enabled: boolean,
    span_min?: number | null,
    span_max?: number | null,
    period_start_time?: string | null,
    period_end_time?: string | null,
    fixed_span?: number | null,
    every?: number | null,
    target_metric?: string | null,
    target_value?: number | null,
    kp?: number | null,
    ki?: number | null,
    kd?: number | null,
    _last_time?: number | null,
    _last_output?: number | null,
    _last_input?: number | null,
    cultivation_id: string,
    ref_cultivation?:  {
      __typename: "Cultivation",
      group_id: string,
      ref_group?:  {
        __typename: "Group",
        id: string,
        display_name: string,
        owner_user_id: string,
        plan: Plan,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      description?: string | null,
      start_date?: string | null,
      growing_id: string,
      ref_growing?:  {
        __typename: "Growing",
        id: string,
        lifeform: string,
        family: string,
        species: string,
        name_ja?: string | null,
        family_ja?: string | null,
        link_ja?: string | null,
        name_en?: string | null,
        link_en?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ref_waterings?:  {
        __typename: "ModelWateringConnection",
        nextToken?: string | null,
      } | null,
      ref_field_works?:  {
        __typename: "ModelFieldWorkConnection",
        nextToken?: string | null,
      } | null,
      ref_datasets?:  {
        __typename: "ModelDatasetConnection",
        nextToken?: string | null,
      } | null,
      ref_field_notes?:  {
        __typename: "ModelFieldNoteConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ref_devices?:  {
      __typename: "ModelDeviceWateringConnection",
      items:  Array< {
        __typename: "DeviceWatering",
        group_id: string,
        device_id: string,
        watering_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDatasetSubscription = {
  onCreateDataset?:  {
    __typename: "Dataset",
    group_id: string,
    id: string,
    data_type?: DataType | null,
    timezone?: string | null,
    column_map?:  Array< {
      __typename: "ColumnMap",
      key: string,
      name: string,
    } | null > | null,
    description?: string | null,
    cultivation_id: string,
    ref_cultivation?:  {
      __typename: "Cultivation",
      group_id: string,
      ref_group?:  {
        __typename: "Group",
        id: string,
        display_name: string,
        owner_user_id: string,
        plan: Plan,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      description?: string | null,
      start_date?: string | null,
      growing_id: string,
      ref_growing?:  {
        __typename: "Growing",
        id: string,
        lifeform: string,
        family: string,
        species: string,
        name_ja?: string | null,
        family_ja?: string | null,
        link_ja?: string | null,
        name_en?: string | null,
        link_en?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ref_waterings?:  {
        __typename: "ModelWateringConnection",
        nextToken?: string | null,
      } | null,
      ref_field_works?:  {
        __typename: "ModelFieldWorkConnection",
        nextToken?: string | null,
      } | null,
      ref_datasets?:  {
        __typename: "ModelDatasetConnection",
        nextToken?: string | null,
      } | null,
      ref_field_notes?:  {
        __typename: "ModelFieldNoteConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ref_devices?:  {
      __typename: "ModelDeviceDatasetConnection",
      items:  Array< {
        __typename: "DeviceDataset",
        group_id: string,
        device_id: string,
        dataset_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDatasetSubscription = {
  onUpdateDataset?:  {
    __typename: "Dataset",
    group_id: string,
    id: string,
    data_type?: DataType | null,
    timezone?: string | null,
    column_map?:  Array< {
      __typename: "ColumnMap",
      key: string,
      name: string,
    } | null > | null,
    description?: string | null,
    cultivation_id: string,
    ref_cultivation?:  {
      __typename: "Cultivation",
      group_id: string,
      ref_group?:  {
        __typename: "Group",
        id: string,
        display_name: string,
        owner_user_id: string,
        plan: Plan,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      description?: string | null,
      start_date?: string | null,
      growing_id: string,
      ref_growing?:  {
        __typename: "Growing",
        id: string,
        lifeform: string,
        family: string,
        species: string,
        name_ja?: string | null,
        family_ja?: string | null,
        link_ja?: string | null,
        name_en?: string | null,
        link_en?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ref_waterings?:  {
        __typename: "ModelWateringConnection",
        nextToken?: string | null,
      } | null,
      ref_field_works?:  {
        __typename: "ModelFieldWorkConnection",
        nextToken?: string | null,
      } | null,
      ref_datasets?:  {
        __typename: "ModelDatasetConnection",
        nextToken?: string | null,
      } | null,
      ref_field_notes?:  {
        __typename: "ModelFieldNoteConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ref_devices?:  {
      __typename: "ModelDeviceDatasetConnection",
      items:  Array< {
        __typename: "DeviceDataset",
        group_id: string,
        device_id: string,
        dataset_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDatasetSubscription = {
  onDeleteDataset?:  {
    __typename: "Dataset",
    group_id: string,
    id: string,
    data_type?: DataType | null,
    timezone?: string | null,
    column_map?:  Array< {
      __typename: "ColumnMap",
      key: string,
      name: string,
    } | null > | null,
    description?: string | null,
    cultivation_id: string,
    ref_cultivation?:  {
      __typename: "Cultivation",
      group_id: string,
      ref_group?:  {
        __typename: "Group",
        id: string,
        display_name: string,
        owner_user_id: string,
        plan: Plan,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      description?: string | null,
      start_date?: string | null,
      growing_id: string,
      ref_growing?:  {
        __typename: "Growing",
        id: string,
        lifeform: string,
        family: string,
        species: string,
        name_ja?: string | null,
        family_ja?: string | null,
        link_ja?: string | null,
        name_en?: string | null,
        link_en?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ref_waterings?:  {
        __typename: "ModelWateringConnection",
        nextToken?: string | null,
      } | null,
      ref_field_works?:  {
        __typename: "ModelFieldWorkConnection",
        nextToken?: string | null,
      } | null,
      ref_datasets?:  {
        __typename: "ModelDatasetConnection",
        nextToken?: string | null,
      } | null,
      ref_field_notes?:  {
        __typename: "ModelFieldNoteConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ref_devices?:  {
      __typename: "ModelDeviceDatasetConnection",
      items:  Array< {
        __typename: "DeviceDataset",
        group_id: string,
        device_id: string,
        dataset_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDeviceSubscription = {
  onCreateDevice?:  {
    __typename: "Device",
    group_id: string,
    id: string,
    display_name?: string | null,
    device_type?: DeviceType | null,
    description?: string | null,
    enabled: boolean,
    certificate?: string | null,
    private_key?: string | null,
    endpoint?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDeviceSubscription = {
  onUpdateDevice?:  {
    __typename: "Device",
    group_id: string,
    id: string,
    display_name?: string | null,
    device_type?: DeviceType | null,
    description?: string | null,
    enabled: boolean,
    certificate?: string | null,
    private_key?: string | null,
    endpoint?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDeviceSubscription = {
  onDeleteDevice?:  {
    __typename: "Device",
    group_id: string,
    id: string,
    display_name?: string | null,
    device_type?: DeviceType | null,
    description?: string | null,
    enabled: boolean,
    certificate?: string | null,
    private_key?: string | null,
    endpoint?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDeviceDatasetSubscription = {
  onCreateDeviceDataset?:  {
    __typename: "DeviceDataset",
    group_id: string,
    device_id: string,
    dataset_id: string,
    ref_device?:  {
      __typename: "Device",
      group_id: string,
      id: string,
      display_name?: string | null,
      device_type?: DeviceType | null,
      description?: string | null,
      enabled: boolean,
      certificate?: string | null,
      private_key?: string | null,
      endpoint?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDeviceDatasetSubscription = {
  onUpdateDeviceDataset?:  {
    __typename: "DeviceDataset",
    group_id: string,
    device_id: string,
    dataset_id: string,
    ref_device?:  {
      __typename: "Device",
      group_id: string,
      id: string,
      display_name?: string | null,
      device_type?: DeviceType | null,
      description?: string | null,
      enabled: boolean,
      certificate?: string | null,
      private_key?: string | null,
      endpoint?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDeviceDatasetSubscription = {
  onDeleteDeviceDataset?:  {
    __typename: "DeviceDataset",
    group_id: string,
    device_id: string,
    dataset_id: string,
    ref_device?:  {
      __typename: "Device",
      group_id: string,
      id: string,
      display_name?: string | null,
      device_type?: DeviceType | null,
      description?: string | null,
      enabled: boolean,
      certificate?: string | null,
      private_key?: string | null,
      endpoint?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDeviceWateringSubscription = {
  onCreateDeviceWatering?:  {
    __typename: "DeviceWatering",
    group_id: string,
    device_id: string,
    watering_id: string,
    ref_device?:  {
      __typename: "Device",
      group_id: string,
      id: string,
      display_name?: string | null,
      device_type?: DeviceType | null,
      description?: string | null,
      enabled: boolean,
      certificate?: string | null,
      private_key?: string | null,
      endpoint?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDeviceWateringSubscription = {
  onUpdateDeviceWatering?:  {
    __typename: "DeviceWatering",
    group_id: string,
    device_id: string,
    watering_id: string,
    ref_device?:  {
      __typename: "Device",
      group_id: string,
      id: string,
      display_name?: string | null,
      device_type?: DeviceType | null,
      description?: string | null,
      enabled: boolean,
      certificate?: string | null,
      private_key?: string | null,
      endpoint?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDeviceWateringSubscription = {
  onDeleteDeviceWatering?:  {
    __typename: "DeviceWatering",
    group_id: string,
    device_id: string,
    watering_id: string,
    ref_device?:  {
      __typename: "Device",
      group_id: string,
      id: string,
      display_name?: string | null,
      device_type?: DeviceType | null,
      description?: string | null,
      enabled: boolean,
      certificate?: string | null,
      private_key?: string | null,
      endpoint?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSensorDataSubscription = {
  onCreateSensorData?:  {
    __typename: "SensorData",
    group_id: string,
    dataset_id: string,
    keytime: number,
    ttl: number,
    year: number,
    month: number,
    date: string,
    hour: number,
    datetime: string,
    v0?: number | null,
    v1?: number | null,
    v2?: number | null,
    v3?: number | null,
    v4?: number | null,
    v5?: number | null,
    v6?: number | null,
    v7?: number | null,
    v8?: number | null,
    v9?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSensorDataSubscription = {
  onUpdateSensorData?:  {
    __typename: "SensorData",
    group_id: string,
    dataset_id: string,
    keytime: number,
    ttl: number,
    year: number,
    month: number,
    date: string,
    hour: number,
    datetime: string,
    v0?: number | null,
    v1?: number | null,
    v2?: number | null,
    v3?: number | null,
    v4?: number | null,
    v5?: number | null,
    v6?: number | null,
    v7?: number | null,
    v8?: number | null,
    v9?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSensorDataSubscription = {
  onDeleteSensorData?:  {
    __typename: "SensorData",
    group_id: string,
    dataset_id: string,
    keytime: number,
    ttl: number,
    year: number,
    month: number,
    date: string,
    hour: number,
    datetime: string,
    v0?: number | null,
    v1?: number | null,
    v2?: number | null,
    v3?: number | null,
    v4?: number | null,
    v5?: number | null,
    v6?: number | null,
    v7?: number | null,
    v8?: number | null,
    v9?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateWateringHistorySubscription = {
  onCreateWateringHistory?:  {
    __typename: "WateringHistory",
    group_id: string,
    watering_id: string,
    keytime: number,
    ttl: number,
    year: number,
    month: number,
    date: string,
    hour: number,
    datetime: string,
    span: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateWateringHistorySubscription = {
  onUpdateWateringHistory?:  {
    __typename: "WateringHistory",
    group_id: string,
    watering_id: string,
    keytime: number,
    ttl: number,
    year: number,
    month: number,
    date: string,
    hour: number,
    datetime: string,
    span: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteWateringHistorySubscription = {
  onDeleteWateringHistory?:  {
    __typename: "WateringHistory",
    group_id: string,
    watering_id: string,
    keytime: number,
    ttl: number,
    year: number,
    month: number,
    date: string,
    hour: number,
    datetime: string,
    span: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateControlPlaneSubscription = {
  onCreateControlPlane?:  {
    __typename: "ControlPlane",
    group_id: string,
    ref_group?:  {
      __typename: "Group",
      id: string,
      display_name: string,
      owner_user_id: string,
      ref_owner?:  {
        __typename: "User",
        id: string,
        line_user_id: string,
        email: string,
        username: string,
        sub: string,
        nickname?: string | null,
        profile?: string | null,
        image_key?: string | null,
        stripe_customer_id?: string | null,
        last4?: string | null,
        active_subscription_items?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      plan: Plan,
      ref_users?:  {
        __typename: "ModelUserGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    id: string,
    display_name: string,
    status: ControlPlaneStatus,
    ssm_activation_code?: string | null,
    ssm_activation_id?: string | null,
    info?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateControlPlaneSubscription = {
  onUpdateControlPlane?:  {
    __typename: "ControlPlane",
    group_id: string,
    ref_group?:  {
      __typename: "Group",
      id: string,
      display_name: string,
      owner_user_id: string,
      ref_owner?:  {
        __typename: "User",
        id: string,
        line_user_id: string,
        email: string,
        username: string,
        sub: string,
        nickname?: string | null,
        profile?: string | null,
        image_key?: string | null,
        stripe_customer_id?: string | null,
        last4?: string | null,
        active_subscription_items?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      plan: Plan,
      ref_users?:  {
        __typename: "ModelUserGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    id: string,
    display_name: string,
    status: ControlPlaneStatus,
    ssm_activation_code?: string | null,
    ssm_activation_id?: string | null,
    info?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteControlPlaneSubscription = {
  onDeleteControlPlane?:  {
    __typename: "ControlPlane",
    group_id: string,
    ref_group?:  {
      __typename: "Group",
      id: string,
      display_name: string,
      owner_user_id: string,
      ref_owner?:  {
        __typename: "User",
        id: string,
        line_user_id: string,
        email: string,
        username: string,
        sub: string,
        nickname?: string | null,
        profile?: string | null,
        image_key?: string | null,
        stripe_customer_id?: string | null,
        last4?: string | null,
        active_subscription_items?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      plan: Plan,
      ref_users?:  {
        __typename: "ModelUserGroupConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    id: string,
    display_name: string,
    status: ControlPlaneStatus,
    ssm_activation_code?: string | null,
    ssm_activation_id?: string | null,
    info?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUsageSubscription = {
  onCreateUsage?:  {
    __typename: "Usage",
    group_id: string,
    price_id: string,
    keytime: number,
    year: number,
    month: number,
    date: string,
    datetime: string,
    volume: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUsageSubscription = {
  onUpdateUsage?:  {
    __typename: "Usage",
    group_id: string,
    price_id: string,
    keytime: number,
    year: number,
    month: number,
    date: string,
    datetime: string,
    volume: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUsageSubscription = {
  onDeleteUsage?:  {
    __typename: "Usage",
    group_id: string,
    price_id: string,
    keytime: number,
    year: number,
    month: number,
    date: string,
    datetime: string,
    volume: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};
