import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Configuration from './configuration/Configuration';
import SensorData from './data/SensorData';
import WateringHistory from './data/WateringHistory';
import ImageData from './data/ImageData';
import Example from './payment/Example';

export default [
  <Route exact path="/login" render={() => <Redirect to="/cultivations" />} />,
  <Route exact path="/configuration" render={() => <Configuration />} />,
  <Route exact path="/data/sensorData/:datasetId" component={SensorData} />,
  <Route exact path="/data/sensorData" component={SensorData} />,
  <Route
    exact
    path="/data/wateringHistory/:wateringId"
    component={WateringHistory}
  />,
  <Route exact path="/data/wateringHistory" component={WateringHistory} />,
  <Route exact path="/data/imageData" component={ImageData} />,
  <Route exact path="/payment/example" component={Example} />,
];
