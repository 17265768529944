import * as React from 'react';
import { FC } from 'react';
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  Filter,
  FilterProps,
  DateInput,
} from 'react-admin';

const GrowingList: FC<ListProps> = (props) => (
  <List {...props} perPage={25} title="Growing">
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name_ja" />
      <TextField source="name_en" />
      <TextField source="species" />
      <TextField source="family" />
      <TextField source="family_ja" />
      <TextField source="lifeform" />
    </Datagrid>
  </List>
);

export default GrowingList;
