import * as React from 'react';
import { FC } from 'react';
import { useState } from 'react';
import { AmplifySignUp } from '@aws-amplify/ui-react';

import { Auth, Hub } from 'aws-amplify';
import {
  AuthState,
  AUTH_STATE_CHANGE_EVENT,
  UI_AUTH_CHANNEL,
} from '@aws-amplify/ui-components';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TermsDialog from './TermsDialog';

// 参考：https://wp-kyoto.net/create-custom-signup-for-amplifu-ui-react/

const dispatchAuthStateChangeEvent = (
  nextAuthState: AuthState,
  data?: object
) => {
  Hub.dispatch(UI_AUTH_CHANNEL, {
    event: AUTH_STATE_CHANGE_EVENT,
    message: nextAuthState,
    data,
  });
};

const CustomSignUp: FC = () => {
  const [userData, updateUserData] = useState<{
    email?: string;
    password?: string;
    terms?: string;
  }>({});

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleUserDataChange = (
    type: 'email' | 'password' | 'terms',
    value: string
  ) => {
    updateUserData({
      ...userData,
      [type]: value,
    });
  };

  const handleSubmit = (e: Event) => {
    // 下記issueが反映されるのが待たれる。。
    // https://github.com/aws-amplify/amplify-js/issues/6440
    e.preventDefault();
    if (!userData.email || !userData.password) return;
    if (!userData.terms || userData.terms !== 'agree@terms') {
      alert('Please agree on terms and conditions to proceed.');
      return;
    }
    Auth.signUp({
      username: userData.email,
      password: userData.password,
      attributes: {
        email: userData.email,
      },
    }).then((data) => {
      dispatchAuthStateChangeEvent(AuthState.ConfirmSignUp, data);
    });
  };

  return (
    <AmplifySignUp
      // headerText=""
      usernameAlias="email"
      slot="sign-up"
      formFields={[
        {
          handleInputChange: (e: any) => {
            handleUserDataChange('email', e.target.value);
          },
          type: 'email',
          label: 'Email address',
          placeholder: 'Enter your email address',
          required: true,
        },
        {
          handleInputChange: (e: any) => {
            handleUserDataChange('password', e.target.value);
          },
          type: 'password',
          label: 'Password',
          placeholder: 'Enter your password',
          required: true,
        },
        {
          handleInputChange: (e: any) => {
            handleUserDataChange('terms', e.target.value);
          },
          type: 'email',
          label: "Type 'agree@terms' if you agree on terms and conditions.",
          placeholder: 'agree@terms',
          required: true,
        },
      ]}
      handleSubmit={handleSubmit}
      submitButtonText="Agree and create account"
    />
  );
};

export default CustomSignUp;
