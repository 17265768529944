import CultivationList from './CultivationList';
import CultivationEdit from './CultivationEdit';
import { Nature } from '@material-ui/icons';
import CultivationCreate from './CultivationCreate';

export default {
  list: CultivationList,
  edit: CultivationEdit,
  create: CultivationCreate,
  icon: Nature,
};
