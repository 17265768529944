import FieldWorkList from './FieldWorkList';
import FieldWorkEdit from './FieldWorkEdit';
import { AccessibilityNew } from '@material-ui/icons';
import FieldWorkCreate from './FieldWorkCreate';

export default {
  list: FieldWorkList,
  edit: FieldWorkEdit,
  create: FieldWorkCreate,
  icon: AccessibilityNew,
};
