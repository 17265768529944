import * as React from 'react';
import { useState } from 'react';
import {
  Edit,
  SimpleForm,
  TextField,
  TextInput,
  DateField,
  ChipField,
  ArrayField,
  ReferenceManyField,
  SingleFieldList,
  Datagrid,
  useShowController,
  FieldProps,
  SelectArrayInput,
  ReferenceArrayInput,
  DateTimeInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import RichTextInput from 'ra-input-rich-text';
import { PhotoInput } from '../../component/PhotoUpload';
import { MultiPhotoField } from '../../component/MultiPhotoField';
import usePromise from 'react-promise';
import { getGroupId } from '../../dataProvider/graphql/utils';

const FieldNoteEdit = (props: any) => {
  const { record } = useShowController<any>(props);
  const classes = useStyles();
  const { value, loading } = usePromise<string>(getGroupId);
  const [images, setImages] = useState('');
  const onChangeImages = (data) => {
    setImages(data);
  };
  if (loading || !value) return null;
  if (!record) return null;
  return (
    <Edit {...props} className={classes.root}>
      <SimpleForm>
        <TextField source="cultivation_id" />
        <TextField source="ref_field_work.display_name" label="Field Work" />
        <DateTimeInput source="note_date_time" />
        <RichTextInput source="note" />
        <PhotoInput
          source="image_key"
          resource="fieldNotes"
          groupId={value}
          onUpload={(e) => {
            onChangeImages(e);
          }}
        />
        <MultiPhotoField
          resource="fieldNotes"
          // @ts-ignore
          groupId={value}
          reload={images}
        />
      </SimpleForm>
    </Edit>
  );
};

export default FieldNoteEdit;

const useStyles = makeStyles({
  root: { width: 600, margin: 'auto' },
  spacer: { height: 20 },
});
