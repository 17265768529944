import * as React from 'react';
import { FC } from 'react';
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  Filter,
  FilterProps,
  DateInput,
} from 'react-admin';

const UserList: FC<ListProps> = (props) => (
  <List {...props} perPage={25} title="Users" bulkActionButtons={false}>
    <Datagrid rowClick="show">
      {/*<Datagrid>*/}
      <TextField source="id" />
      <TextField source="nickname" />
      <TextField source="ref_line_user.line_user_name" />
    </Datagrid>
  </List>
);

export default UserList;
