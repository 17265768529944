import * as React from 'react';
import { FC } from 'react';
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  DateField,
  RichTextField,
} from 'react-admin';

const FieldNoteList: FC<ListProps> = (props) => (
  <List {...props} perPage={25} title="Field Notes">
    <Datagrid rowClick="edit">
      <TextField source="cultivation_id" />
      <TextField source="ref_field_work.display_name" label="Field Work" />
      <DateField source="note_date_time" />
      {/*<RichTextField source="note" />*/}
    </Datagrid>
  </List>
);

export default FieldNoteList;
