import { SelectInput } from 'react-admin';
import React from 'react';
import { appendTimeZero } from './TimeInput';

const every = 60;
const timeArr = Array.from(
  {
    length: (23 * 60) / every,
  },
  (v, cnt) => {
    const i = cnt - 11;
    const h = Math.floor((i * every) / 60);
    const tz = appendTimeZero(h);
    return tz + ':00';
  }
);

const timeOptions = timeArr.map((t) => {
  return { id: t, name: t };
});

export const TimezoneInput = (props: any) => {
  return <SelectInput {...props} choices={timeOptions} defaultValue="+09:00" />;
};
