import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import setting from '../setting';
import {
  regiserPaymentInfo,
  publishClientSecret,
} from '../dataProvider/customRest';
import { MySpinner } from './MySpinner';

/**
 * https://stripe.com/docs/payments/save-and-reuse
 * @param props
 */
export const CheckoutForm = (props: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  if (stripe === null) {
    return <div />;
  }
  if (elements === null) {
    return <div />;
  }

  const handleSubmit = async (event) => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const card = elements.getElement(CardElement);
    if (card === null) {
      return;
    }
    event.preventDefault();
    setLoading(true);

    // server側に問い合わせ、client_secretを取得する（SetupIntentの作成）
    const clientSecret = await publishClientSecret().catch((e) => {
      console.error(e);
      setLoading(false);
      throw e;
    });

    // カード情報の検証、payment_methodのIDの取得（confirmCardSetup）
    const { error, setupIntent } = await stripe
      .confirmCardSetup(clientSecret, {
        payment_method: { card },
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
        throw e;
      });
    if (setupIntent === undefined) {
      console.error(error);
      return;
    }
    // console.info(`setupIntent: ${JSON.stringify(setupIntent)}`);

    // サーバ側で、payment_methodとCustomerとの紐づけ
    const paymentInfo = await regiserPaymentInfo(setupIntent).catch((e) => {
      console.error(e);
      setLoading(false);
      throw e;
    });
    // console.info(`paymentInfo: ${JSON.stringify(paymentInfo)}`);
    setLoading(false);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <CardElement
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
        />
        <button type="submit" disabled={!stripe}>
          Register card
        </button>
      </form>
      {loading ? <MySpinner /> : <div />}
    </div>
  );
};

const stripePromise = loadStripe(setting.stripe_public_key);

export const StripeForm = (props: any) => (
  <Elements stripe={stripePromise}>
    <CheckoutForm {...props} />
  </Elements>
);
