import * as React from 'react';
import {
  Create,
  SimpleForm,
  TextField,
  TextInput,
  BooleanInput,
  NumberInput,
  SelectInput,
  DateTimeInput,
  ReferenceArrayInput,
  SelectArrayInput,
  required,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import usePromise from 'react-promise';
import { getGroupId } from '../../dataProvider/graphql/utils';
import { getWateringTypeOptions } from '../../utils/api';
import { TimeInput } from '../../component/TimeInput';
import { keyChoices } from '../../utils/util';

const WateringCreate = (props: any) => {
  const classes = useStyles();
  const { value, loading } = usePromise<string>(getGroupId);
  if (loading || !value) return null;
  const wateringTypeOptions = getWateringTypeOptions();
  return (
    <Create {...props} className={classes.root}>
      <SimpleForm>
        <TextInput
          source="group_id"
          defaultValue={value}
          disabled={true}
          validate={required()}
        />
        <ReferenceArrayInput
          source="cultivation_id"
          reference="cultivations"
          label="Cultivation"
          validate={required()}>
          <SelectInput optionText="id" />
        </ReferenceArrayInput>
        <TextInput source="display_name" validate={required()} />
        <ReferenceArrayInput
          label="Devices"
          source="ref_add_devices"
          reference="devices"
          fieldKey="id">
          <SelectArrayInput optionText="id" allowEmpty emptyValue={[]} />
        </ReferenceArrayInput>
        <SelectInput
          source="watering_type"
          choices={wateringTypeOptions}
          validate={required()}
        />
        <BooleanInput
          source="enabled"
          defaultValue={true}
          validate={required()}
        />
        <NumberInput
          source="span_min"
          defaultValue={3000}
          validate={required()}
        />
        <NumberInput
          source="span_max"
          defaultValue={900000}
          validate={required()}
        />
        <TimeInput source="period_start_time" validate={required()} />
        <TimeInput source="period_end_time" validate={required()} />
        <NumberInput source="fixed_span" defaultValue={5000} />
        <NumberInput source="every" defaultValue={1} />
        <SelectInput source="target_metric" choices={keyChoices} />
        <NumberInput source="target_value" />
        <NumberInput source="kp" defaultValue={2000} />
        <NumberInput source="ki" defaultValue={0} />
        <NumberInput source="kd" defaultValue={2000} />
      </SimpleForm>
    </Create>
  );
};

export default WateringCreate;

const useStyles = makeStyles({
  root: { width: 600, margin: 'auto' },
  spacer: { height: 20 },
});
