import * as React from 'react';
import {
  Create,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  SimpleFormIterator,
  ArrayInput,
  required,
  SelectInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import usePromise from 'react-promise';
import { getGroupId } from '../../dataProvider/graphql/utils';
import { PrefixTextInput } from '../../component/PrefixTextInput';
import { TimezoneInput } from '../../component/TimezoneInput';

const DatasetCreate = (props: any) => {
  const classes = useStyles();
  const { value, loading } = usePromise<string>(getGroupId);
  if (loading || !value) return null;
  const prefix = value + '_';
  const keyChoices = Array.from({ length: 10 }, (x, i) => i).map((i) => {
    return { id: 'v' + i, name: 'v' + i };
  });
  return (
    <Create {...props} className={classes.root}>
      <SimpleForm>
        <TextInput source="group_id" defaultValue={value} disabled={true} />
        <ReferenceArrayInput
          source="cultivation_id"
          reference="cultivations"
          label="Cultivation">
          <SelectInput optionText="id" />
        </ReferenceArrayInput>
        <PrefixTextInput source="id" validate={required()} prefix={prefix} />
        <ReferenceArrayInput
          label="Devices"
          source="ref_add_devices"
          reference="devices"
          fieldKey="id">
          <SelectArrayInput optionText="id" allowEmpty emptyValue={null} />
        </ReferenceArrayInput>
        <TimezoneInput source="timezone" />
        <TextInput source="description" multiline={true} />
        <ArrayInput source="column_map">
          <SimpleFormIterator>
            <SelectInput source="key" choices={keyChoices} label="Data key" />
            <TextInput source="name" label="Column name" />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

export default DatasetCreate;

const useStyles = makeStyles({
  root: { width: 600, margin: 'auto' },
  spacer: { height: 20 },
});
