import * as React from 'react';
import {
  Create,
  SimpleForm,
  DateTimeInput,
  TextInput,
  ReferenceInput,
  SelectArrayInput,
  required,
  SelectInput,
  ReferenceArrayInput,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import usePromise from 'react-promise';
import { getGroupId } from '../../dataProvider/graphql/utils';
import { SelectFilterInput } from '../../component/SelectFilterInput';

const FieldNoteCreate = (props: any) => {
  const classes = useStyles();
  const { value, loading } = usePromise<string>(getGroupId);
  const [selectedCultivationId, setCultivationId] = useState(null);
  if (loading || !value) return null;
  const onSelect = (e) => {
    setCultivationId(e.target.value);
  };
  const filter = selectedCultivationId
    ? { group_id: value, cultivation_id: selectedCultivationId }
    : {};
  const optionText = (r) => {
    return `${r.display_name || ''} | ${r.work_type}`;
  };
  return (
    <Create {...props} className={classes.root}>
      <SimpleForm>
        <TextInput source="group_id" defaultValue={value} disabled={true} />
        <ReferenceInput
          source="cultivation_id"
          reference="cultivations"
          label="Cultivation"
          validate={required()}>
          <SelectInput optionText="id" onClick={onSelect} />
        </ReferenceInput>
        <ReferenceInput
          source="field_work_id"
          reference="fieldWorks"
          label="Field Work">
          <SelectFilterInput optionText={optionText} filter={filter} />
        </ReferenceInput>
        <DateTimeInput source="note_date_time" defaultValue={new Date()} />
        <RichTextInput source="note" />
      </SimpleForm>
    </Create>
  );
};

export default FieldNoteCreate;

const useStyles = makeStyles({
  root: { width: 600, margin: 'auto' },
  spacer: { height: 20 },
});
