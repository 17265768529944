import Amplify, { API, Auth } from 'aws-amplify';
import awsconfig from '../aws-exports';
import setting from '../setting';
import { getGroupId, getUserId } from './graphql/utils';

const API_NAME = 'rest';
Amplify.configure({
  // OPTIONAL - if your API requires authentication
  Auth: {
    // REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: awsconfig.aws_cognito_identity_pool_id,
    // REQUIRED - Amazon Cognito Region
    region: awsconfig.aws_cognito_region,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: awsconfig.aws_user_pools_id,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: awsconfig.aws_user_pools_web_client_id,
  },
  API: {
    endpoints: [
      {
        name: API_NAME,
        endpoint: setting.api_endpoint,
      },
    ],
  },
});

async function getInit(): Promise<object> {
  return {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
      // Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
}

export const createUserResource = async (
  resource: string,
  params: any
): Promise<any> => {
  const init = await getInit();
  const userId = await getUserId();
  return await API.put(
    API_NAME,
    `/users/${userId}/${resource}/${params.data.id}`,
    init
  );
};

export const deleteUserResource = async (
  resource: string,
  params: any
): Promise<any> => {
  const init = await getInit();
  const userId = await getUserId();
  return await API.del(
    API_NAME,
    `/users/${userId}/${resource}/${params.id}`,
    init
  );
};

export const createGroupResource = async (
  resource: string,
  params: any
): Promise<any> => {
  const init = await getInit();
  const groupId = await getGroupId();
  return await API.put(
    API_NAME,
    `/groups/${groupId}/${resource}/${params.data.id}`,
    init
  );
};

export const deleteGroupResource = async (
  resource: string,
  params: any
): Promise<any> => {
  const init = await getInit();
  const groupId = await getGroupId();
  return await API.del(
    API_NAME,
    `/groups/${groupId}/${resource}/${params.id}`,
    init
  );
};

export const deleteManyDevices = async (
  resource: string,
  params: any
): Promise<any> => {
  const init = await getInit();
  const groupId = await getGroupId();
  for (const id of params.ids) {
    await API.del(API_NAME, `/groups/${groupId}/${resource}/${id}`, init);
  }
  return null;
};

export const publishClientSecret = async (): Promise<string> => {
  const init = await getInit();
  const userId = await getUserId();
  const res = await API.post(API_NAME, `/users/${userId}/client-secret`, {
    ...init,
  });
  console.info(`${JSON.stringify(res)}`);
  return res.client_secret;
};

export const regiserPaymentInfo = async (setupIntent: object): Promise<any> => {
  const init = await getInit();
  const userId = await getUserId();
  return await API.post(API_NAME, `/users/${userId}/payment-info`, {
    ...init,
    body: {
      setupIntent,
    },
  });
};

/**
 * https://docs.amplify.aws/lib/restapi/fetch/q/platform/js#get-requests
 * @param datasetId
 * @param start in milliseconds
 * @param end in milliseconds
 */
export const querySensorData = async (
  datasetId: string,
  start: number,
  end: number
): Promise<any> => {
  const init = await getInit();
  const groupId = await getGroupId();
  init['queryStringParameters'] = {
    dataset_id: datasetId,
    start: start,
    end: end,
  };
  return await API.get(API_NAME, `/groups/${groupId}/sensorData`, init);
};

export const queryWateringHistory = async (
  wateringId: string,
  start: number,
  end: number
): Promise<any> => {
  const init = await getInit();
  const groupId = await getGroupId();
  init['queryStringParameters'] = {
    watering_id: wateringId,
    start: start,
    end: end,
  };
  return await API.get(API_NAME, `/groups/${groupId}/wateringHistory`, init);
};

export const postUserImage = async (body: any): Promise<any> => {
  const init = await getInit();
  const userId = await getUserId();
  return await API.post(API_NAME, `/users/${userId}/image`, { ...init, body });
};

export const getUserImage = async (queryParams: any): Promise<any> => {
  const init = await getInit();
  const queryStringParameters = queryParams;
  const userId = await getUserId();
  return await API.get(API_NAME, `/users/${userId}/images`, {
    ...init,
    queryStringParameters,
  });
};

export const postGroupImage = async (body: any): Promise<any> => {
  const init = await getInit();
  const groupId = await getGroupId();
  return await API.post(API_NAME, `/groups/${groupId}/image`, {
    ...init,
    body,
  });
};

export const getGroupImages = async (queryParams: any): Promise<any> => {
  const init = await getInit();
  const queryStringParameters = queryParams;
  const groupId = await getGroupId();
  return await API.get(API_NAME, `/groups/${groupId}/images`, {
    ...init,
    queryStringParameters,
  });
};
