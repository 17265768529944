import * as React from 'react';
import { useState, useEffect } from 'react';
import { Admin, Resource, DataProvider } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';

// AWS
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import { buildAuthProvider } from 'react-admin-amplify';
import {
  AmplifyAuthenticator,
  AmplifyAuthContainer,
  AmplifyConfirmSignUp,
  AmplifySignIn,
  AmplifyForgotPassword,
} from '@aws-amplify/ui-react';

// Stripe
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Google analytics
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';

import './App.css';
import themeReducer from './themeReducer';
import { Layout, SignUp } from './layout';
import { Dashboard } from './dashboard';
import customRoutes from './routes';
import englishMessages from './i18n/en';

// schemas
// cultivations
import cultivations from './resources/cultivations';
import growings from './resources/growings';
import fieldWorks from './resources/fieldWorks';
import fieldNotes from './resources/fieldNotes';
// control
import waterings from './resources/waterings';
import devices from './resources/devices';
import datasets from './resources/datasets';
// groups
import groups from './resources/groups';
import users from './resources/users';
import lineUsers from './resources/lineUsers';

import dataProviderFactory from './dataProvider';

Amplify.configure(awsconfig);
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  'pk_test_51I3hVyIwpnDb34yXKjSKCXvC7uP7fydtMFvT7rFKS2Tc6GCFy4gJe5GCB62Xpj5RmJGigkv4cewlx7gRhjEjoCRU004TVx3Uc7'
);

// Google analytics
// https://stackoverflow.com/questions/56315758/google-analytics-on-react-admin
// create the history
const history = createBrowserHistory();
// listen for a route change and record page view
history.listen((location) => {
  ReactGA.pageview(location.pathname);
});
// initialize GA
ReactGA.initialize('G-0ZR1PKB99V');
// record the first pageview because listen will not be called
ReactGA.pageview(window.location.pathname);

const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale === 'ja') {
    return import('./i18n/ja').then((messages) => messages.default);
  }

  // Always fallback on english
  return englishMessages;
}, 'en');

const App = () => {
  const [dataProvider, setDataProvider] = useState<DataProvider>();

  useEffect(() => {
    let restoreFetch;

    const fetchDataProvider = async () => {
      // restoreFetch = await fakeServerFactory(
      //     process.env.REACT_APP_DATA_PROVIDER || ''
      // );
      const dataProviderInstance = await dataProviderFactory(
        process.env.REACT_APP_DATA_PROVIDER || ''
      );
      setDataProvider(
        // GOTCHA: dataProviderInstance can be a function
        // @ts-ignore
        () => dataProviderInstance
      );
    };

    fetchDataProvider();

    // return restoreFetch;
  }, []);

  if (!dataProvider) {
    return (
      <div className="loader-container">
        <div className="loader">Loading...</div>
      </div>
    );
  }

  return (
    <div>
      <AmplifyAuthContainer>
        <AmplifyAuthenticator>
          <SignUp />
          <AmplifyConfirmSignUp slot="confirm-sign-up" usernameAlias="email" />
          <AmplifySignIn slot="sign-in" usernameAlias="email" />
          <AmplifyForgotPassword slot="forgot-password" usernameAlias="email" />
          <Elements stripe={stripePromise}>
            <Admin
              title=""
              dataProvider={dataProvider}
              customReducers={{ theme: themeReducer }}
              customRoutes={customRoutes}
              // authProvider={authProvider}
              // authProvider={buildAuthProvider({ authGroups: ["admin"] })}
              authProvider={buildAuthProvider()}
              // dashboard={Dashboard}
              // loginPage={Login}
              layout={Layout}
              i18nProvider={i18nProvider}
              history={history}>
              {/*Schema名との命名規則：resource.charAt(0).toUpperCase() + resource.slice(1, -1)*/}
              <Resource name="cultivations" {...cultivations} />
              <Resource name="fieldWorks" {...fieldWorks} />
              <Resource name="fieldNotes" {...fieldNotes} />
              <Resource name="growings" {...growings} />
              {/*<Resource name="controlPlanes" {...controlPlanes}/>*/}
              <Resource name="waterings" {...waterings} />
              <Resource name="devices" {...devices} />
              <Resource name="datasets" {...datasets} />
              <Resource name="groups" {...groups} />
              <Resource name="users" {...users} />
              <Resource name="lineUsers" {...lineUsers} />
            </Admin>
          </Elements>
        </AmplifyAuthenticator>
      </AmplifyAuthContainer>
      <footer>
        © All rights reserved by enforesters. &nbsp;
        <a href="http://localhost:1313/business/morimoritor/">
          Terms and conditions
        </a>
      </footer>
    </div>
  );
};

export default App;
