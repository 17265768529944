import DatasetList from './DatasetList';
import DatasetEdit from './DatasetEdit';
import { TableChart } from '@material-ui/icons';
import DatasetCreate from './DatasetCreate';

export default {
  list: DatasetList,
  edit: DatasetEdit,
  create: DatasetCreate,
  icon: TableChart,
};
