import { SelectInput } from 'react-admin';
import React from 'react';

export const SelectFilterInput = (props: any) => {
  if (!props.choices) return <SelectInput {...props} />;
  const filter = props.filter ? props.filter : {};
  const filteredChoices = props.choices.filter((c) => {
    const matches = Object.entries(filter).map(([k, v]) => {
      return c[k] === v;
    });
    return matches.every((b) => b);
  });
  // console.log(`choices: ${JSON.stringify(filteredChoices)}`)
  return <SelectInput {...props} choices={filteredChoices} />;
};
