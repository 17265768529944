import * as React from 'react';
import { Create, SimpleForm, TextInput, required } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import usePromise from 'react-promise';
import { getGroupId } from '../../dataProvider/graphql/utils';

const DeviceCreate = (props: any) => {
  const classes = useStyles();
  const { value, loading } = usePromise<string>(getGroupId);
  if (loading || !value) return null;
  // 裏で作るので最小限
  return (
    <Create {...props} className={classes.root}>
      <SimpleForm>
        <TextInput source="group_id" defaultValue={value} disabled={true} />
        <TextInput source="id" validate={required()} />
      </SimpleForm>
    </Create>
  );
};

export default DeviceCreate;

const useStyles = makeStyles({
  root: { width: 600, margin: 'auto' },
  spacer: { height: 20 },
});
