import * as React from 'react';
import { FC } from 'react';
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  DateField,
  FieldProps,
} from 'react-admin';
import { Save } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { getSecretFile } from '../../utils/secret';
import usePromise from 'react-promise';
import { getGroupId } from '../../dataProvider/graphql/utils';

function downloadSecretFile(data: string) {
  // Creating the blob file and its url
  const blob = new Blob([data], { type: 'text/plain' });
  const url = window.URL.createObjectURL(blob);
  // Creating the hyperlink and auto click it to start the download
  const link = document.createElement('a');
  link.href = url;
  link.download = 'secret.h';
  link.click();
}

const DownloadField: FC<FieldProps> = ({ record }) => {
  const { value, loading } = usePromise<string>(getGroupId);
  if (loading || !value) return null;
  if (
    !record ||
    !record.id ||
    !record.endpoint ||
    !record.certificate ||
    !record.private_key
  )
    return null;
  const download = () => {
    const data = getSecretFile(
      value,
      String(record.id),
      record.endpoint,
      record.certificate,
      record.private_key
    );
    downloadSecretFile(data);
  };
  if (!record) return null;
  return (
    <IconButton color="inherit" onClick={download}>
      <Save />
    </IconButton>
  );
};

const DeviceList: FC<ListProps> = (props) => (
  <List {...props} perPage={25} title="Watering Groups">
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <DownloadField label="Download Secret" />
      <DateField source="createdAt" />
      <TextField source="device_type" />
      <TextField source="description" />
    </Datagrid>
  </List>
);

export default DeviceList;
