import * as React from 'react';
import {
  Edit,
  SimpleForm,
  TextField,
  SelectInput,
  BooleanInput,
  TextInput,
} from 'react-admin';
import { getDeviceTypeOptions } from '../../utils/api';
import { makeStyles } from '@material-ui/core/styles';
import usePromise from 'react-promise';
import { getGroupId } from '../../dataProvider/graphql/utils';

const DeviceCreate = (props: any) => {
  const classes = useStyles();
  const { value, loading } = usePromise<string>(getGroupId);
  if (loading || !value) return null;
  return (
    <Edit {...props} className={classes.root}>
      <SimpleForm>
        <TextField source="group_id" />
        <TextField source="id" />
        <BooleanInput source="enabled" />
        <SelectInput source="device_type" choices={getDeviceTypeOptions()} />
        <TextInput source="description" />
      </SimpleForm>
    </Edit>
  );
};

export default DeviceCreate;

const useStyles = makeStyles({
  root: { width: 600, margin: 'auto' },
  spacer: { height: 20 },
});
