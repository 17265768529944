import { API, GraphQLResult, GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import {
  CreateParams,
  CreateResult,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult,
} from 'ra-core';
import { Filter } from './Filter';
import { Pagination } from './Pagination';
import Amplify, { Auth } from 'aws-amplify';
import awsExports from '../../aws-exports';
import {
  getExtraParams,
  expandItems,
  filterItems,
  deleteRef,
  makeQueryNameSingle,
  makeQueryNamePlural,
  appendId,
  stringifyNull,
} from './utils';
import {createUserResource, deleteUserResource} from '../customRest'

Amplify.configure(awsExports);

export interface Operations {
  queries: Record<string, string>;
  mutations: Record<string, string>;
}

export interface DataProviderOptions {
  authMode?: GRAPHQL_AUTH_MODE;
}

const defaultOptions: DataProviderOptions = {
  authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
};

export class DataProvider {
  public queries: Record<string, string>;
  public mutations: Record<string, string>;
  public authMode: GRAPHQL_AUTH_MODE;

  public constructor(
    operations: Operations,
    options: DataProviderOptions = defaultOptions
  ) {
    const optionsBag = { ...defaultOptions, ...options };

    this.queries = operations.queries;
    this.mutations = operations.mutations;
    this.authMode = <GRAPHQL_AUTH_MODE>optionsBag.authMode;
  }

  public getList = async (
    resource: string,
    params: GetListParams
  ): Promise<GetListResult> => {
    const extraParams = await getExtraParams();
    // console.debug(`list extraParams: ${JSON.stringify(extraParams)}`)
    // console.debug(`list params: ${JSON.stringify(params)}`)
    // const filter = {...params.filter, ...extraParams};
    const { filter } = params;
    let queryName = Filter.getQueryName(this.queries, filter);
    // console.debug(`list filter: ${JSON.stringify(filter)}`)
    // let queryVariables = Filter.getQueryVariables(filter);
    // とりあえず無条件でgroup_idをquery variablesに突っ込む。必要ない場合は無視される模様。
    let queryVariables =
      resource !== 'groups' ? extraParams : {};
    if (filter) Object.assign(queryVariables, filter);
    console.debug(`list query variable: ${JSON.stringify(queryVariables)}`);
    if (!queryName || !queryVariables) {
      // Default list query without filter
      queryName = `list${makeQueryNamePlural(resource)}`;
    }

    const query = this.getQuery(queryName);

    if (!queryVariables) {
      // @ts-ignore
      queryVariables = {};
    }

    const { page, perPage } = params.pagination;

    // Defines a unique identifier of the query
    const querySignature = JSON.stringify({
      queryName,
      queryVariables,
      perPage,
    });
    // console.debug(`querySignature: ${querySignature}`)

    const nextToken = Pagination.getNextToken(querySignature, page);
    // console.debug(`nextToken: ${nextToken}`)

    // Checks if page requested is out of range
    if (typeof nextToken === 'undefined') {
      return {
        data: [],
        total: 0,
      }; // React admin will redirect to page 1
    }

    // Adds sorting if requested
    if (params.sort.field === queryName) {
      queryVariables['sortDirection'] = params.sort.order;
    }

    // Executes the query
    const queryData = (
      await this.graphql(query, {
        ...queryVariables,
        limit: perPage,
        nextToken,
      }).catch(e => {
        return e.data;
      })
    )[queryName];

    // Saves next token
    Pagination.saveNextToken(queryData.nextToken, querySignature, page);

    // Computes total
    let total = (page - 1) * perPage + queryData.items.length;
    if (queryData.nextToken) {
      total++; // Tells react admin that there is at least one more page
    }

    return {
      data: queryData.items
        .map((o: { [key: string]: any }) => expandItems(o))
        .filter((o) => filterItems(o, queryVariables)),
      total,
    };
  };

  public getOne = async (
    resource: string,
    params: GetOneParams
  ): Promise<GetOneResult> => {
    // when create
    // @ts-ignore
    if (!params || !params.id) return { data: { id: null } };

    // when get one
    const extraParams = await getExtraParams();
    const queryName = `get${makeQueryNameSingle(resource)}`;
    const query = this.getQuery(queryName);

    // Executes the query
    const queryData = await this.graphql(query, {
      id: params.id,
      ...extraParams,
    }).catch(e => {
      return e.data;
    });
    const data = expandItems(queryData[queryName]);
    // console.debug(`queryData: ${JSON.stringify(data)}`);

    return {
      // @ts-ignore
      data: data,
    };
  };

  public getMany = async (
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult> => {
    const extraParams = await getExtraParams();
    const queryName = `get${makeQueryNameSingle(resource)}`;
    const query = this.getQuery(queryName);

    const queriesData = [];

    // Executes the queries
    for (const id of params.ids) {
      try {
        const queryData = (await this.graphql(query, { id, ...extraParams }))[
          queryName
        ];
        // @ts-ignore
        queriesData.push(queryData);
      } catch (e) {
        console.log(e);
      }
    }

    return {
      data: queriesData,
    };
  };

  public getManyReference = async (
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult> => {
    const target = params.target.split('.');

    // Target is used to build the filter
    // It must be like: queryName.resourceID
    if (target.length !== 2) {
      throw new Error('Data provider error');
    }

    const { filter, id, pagination, sort } = params;

    if (!filter[target[0]]) {
      filter[target[0]] = {};
    }

    filter[target[0]][target[1]] = id;

    return this.getList(resource, { pagination, sort, filter });
  };

  public create = async (
    resource: string,
    params: CreateParams
  ): Promise<CreateResult> => {
    const extraParams = await getExtraParams();
    const queryName = `create${makeQueryNameSingle(resource)}`;
    const query = this.getQuery(queryName);
    const data = appendId(params.data);
    const updateData = stringifyNull(deleteRef(data));
    // Executes the query
    const queryData = (await this.graphql(query, { input: updateData }))[
      queryName
    ];

    // あまりきれいでないが、多対多のものについては、関連テーブルの作成もごりっと。。
    await this.addRelations(resource, data, extraParams);

    return {
      data: queryData,
    };
  };

  public update = async (
    resource: string,
    params: UpdateParams
  ): Promise<UpdateResult> => {
    const extraParams = await getExtraParams();
    const queryName = `update${makeQueryNameSingle(resource)}`;
    const query = this.getQuery(queryName);

    // Removes non editable fields
    const { data } = { ...params, ...extraParams };
    delete data._deleted;
    delete data._lastChangedAt;
    delete data.createdAt;
    delete data.updatedAt;
    const updateData = deleteRef(data);

    // Executes the query
    const queryData = (await this.graphql(query, { input: updateData }))[
      queryName
    ];

    // あまりきれいでないが、多対多のものについては、関連テーブルの作成もごりっと。。
    await this.addRelations(resource, data, extraParams);

    return {
      data: queryData,
    };
  };

  // This may not work for API that uses DataStore because
  // DataStore works with a _version field that needs to be properly set
  public updateMany = async (
    resource: string,
    params: UpdateManyParams
  ): Promise<UpdateManyResult> => {
    const extraParams = await getExtraParams();
    const queryName = `update${makeQueryNameSingle(resource)}`;
    const query = this.getQuery(queryName);

    // Removes non editable fields
    const { data } = { ...params, ...extraParams };
    delete data._deleted;
    delete data._lastChangedAt;
    delete data.createdAt;
    delete data.updatedAt;
    const updateData = deleteRef(data);

    const ids = [];

    // Executes the queries
    for (const id of params.ids) {
      try {
        await this.graphql(query, { input: { ...updateData, id } });
        // @ts-ignore
        ids.push(id);
      } catch (e) {
        console.log(e);
      }
    }

    return {
      data: ids,
    };
  };

  public delete = async (
    resource: string,
    params: DeleteParams
  ): Promise<DeleteResult> => {
    const extraParams = await getExtraParams();
    const queryName = `delete${makeQueryNameSingle(resource)}`;
    const query = this.getQuery(queryName);

    const { id, previousData } = params;
    let data = {id, _version: previousData._version};
    if (resource !== 'groups') {
      data = { ...data, ...extraParams };
    }
    // console.info(JSON.stringify(data))

    // Executes the query
    const queryData = (await this.graphql(query, { input: data }))[queryName];

    await this.deleteRelations(resource, data, extraParams);

    return {
      data: queryData,
    };
  };

  public deleteMany = async (
    resource: string,
    params: DeleteManyParams
  ): Promise<DeleteManyResult> => {
    const extraParams = await getExtraParams();
    const queryName = `delete${makeQueryNameSingle(resource)}`;
    const query = this.getQuery(queryName);

    const ids = [];

    // Executes the queries
    for (const id of params.ids) {
      try {
        const data = { id, ...extraParams };
        await this.graphql(query, { input: data });
        await this.deleteRelations(resource, data, extraParams);
        // @ts-ignore
        ids.push(id);
      } catch (e) {
        console.log(e);
      }
    }

    return {
      data: ids,
    };
  };

  public getQuery(queryName: string): string {
    if (this.queries[queryName]) {
      return this.queries[queryName];
    }

    if (this.mutations[queryName]) {
      return this.mutations[queryName];
    }

    console.log(`Could not find query ${queryName}`);

    throw new Error('Data provider error');
  }

  public async graphql(
    query: string,
    variables: Record<string, unknown>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> {
    const queryResult = <GraphQLResult>await API.graphql({
      query,
      variables,
      authMode: this.authMode,
    });

    if (queryResult.errors || !queryResult.data) {
      throw new Error('Data provider error');
    }

    return queryResult.data;
  }

  public async addUserToGroup(groupId: string, userId: string) {
    const ugQueryName = `create${makeQueryNameSingle('userGroups')}`;
    const ugQuery = this.getQuery(ugQueryName);
    const ugData = {
      id: `${groupId}#${userId}`,
      group_id: groupId,
      user_id: userId,
    };
    await this.graphql(ugQuery, { input: ugData });
  }

  public async addRelations(resource: string, data: any, extraParams: any) {
    if (resource === 'groups') {
      await this.addUsersToGroup(data.id, data.ref_add_users);
    } else if (resource === 'datasets') {
      await this.addDevicesToDataset(
        extraParams.group_id,
        data.ref_add_devices,
        data.id
      );
    } else if (resource === 'waterings') {
      await this.addDevicesToWatering(
        extraParams.group_id,
        data.ref_add_devices,
        data.id
      );
    }
  }

  public async deleteRelations(resource: string, data: any, extraParams: any) {
    if (resource === 'groups') {
      // TODO: グループ配下のその他リソースをどうするかは要検討
      await this.deleteGroupFromUsers(data.id);
    } else if (resource === 'datasets') {
      await this.deleteDatasetFromDevices(extraParams.group_id, data.id);
    } else if (resource === 'waterings') {
      await this.deleteWateringFromDevices(extraParams.group_id, data.id);
    }
  }

  public async addUsersToGroup(groupId: string, users: string[]) {
    // 現在のグループを設定
    localStorage.setItem('/morimoritor/currentGroup', groupId);

    // はじめに現在の紐付けをすべてはずす
    await this.deleteGroupFromUsers(groupId).catch(() => { return; });

    // 更新内容で更新
    for (const userId of users) {
      // cognito関連
      await createUserResource('userGroups', {data: {
        id: encodeURIComponent(`${groupId}#${userId}`),
      }}).catch(e => { console.info(e); });
      await this.addUserToGroup(groupId, userId).catch(e => { console.info(e); });
    }
  }

  public async deleteGroupFromUsers(groupId: string) {
    // @ts-ignore
    const ugListQueryData = await this.getList('userGroups', {
      filter: { group_id: groupId },
      pagination: { page: 1, perPage: 10000 },
      sort: { field: 'user_id', order: 'asc' },
    });
    if (ugListQueryData) {
      for (const ugData of ugListQueryData.data) {
        const ugDeleteQueryName = `delete${makeQueryNameSingle('userGroups')}`;
        const ugDeleteQuery = this.getQuery(ugDeleteQueryName);
        await this.graphql(ugDeleteQuery, {
          input: {
            id: encodeURIComponent(ugData.id),
          },
        }).catch(e => { console.info(e); });
        await createUserResource('userGroups', {data: {
          id: ugData.id,
        }}).catch(e => { console.info(e); });
      }
    }
  }

  public async deleteDatasetFromDevices(groupId: string, datasetId: string) {
    // @ts-ignore
    const ugListQueryData = await this.getList('deviceDatasets', {
      filter: { group_id: groupId, dataset_id: datasetId },
      pagination: { page: 1, perPage: 10000 },
      sort: { field: 'device_id#dataset_id', order: 'asc' },
    });
    if (ugListQueryData) {
      for (const ugData of ugListQueryData.data) {
        console.info(
          `device-dataset: ${ugData.device_id}-${ugData.dataset_id}`
        );
        const ugDeleteQueryName = `delete${makeQueryNameSingle(
          'deviceDatasets'
        )}`;
        const ugDeleteQuery = this.getQuery(ugDeleteQueryName);
        await this.graphql(ugDeleteQuery, {
          input: {
            group_id: ugData.group_id,
            dataset_id: ugData.dataset_id,
            device_id: ugData.device_id,
          },
        });
      }
    }
  }

  public async addDeviceToDataset(
    groupId: string,
    deviceId: string,
    datasetId: string
  ) {
    const ugQueryName = `create${makeQueryNameSingle('deviceDatasets')}`;
    const ugQuery = this.getQuery(ugQueryName);
    const ugData = {
      group_id: groupId,
      device_id: deviceId,
      dataset_id: datasetId,
    };
    await this.graphql(ugQuery, { input: ugData });
  }

  public async addDevicesToDataset(
    groupId: string,
    deviceIds: string[],
    datasetId: string
  ) {
    // はじめに現在の紐付けをすべてはずす
    await this.deleteDatasetFromDevices(groupId, datasetId);

    // 更新内容で更新
    for (const deviceId of deviceIds) {
      await this.addDeviceToDataset(groupId, deviceId, datasetId);
    }
  }

  public async deleteWateringFromDevices(groupId: string, wateringId: string) {
    // @ts-ignore
    const ugListQueryData = await this.getList('deviceWaterings', {
      filter: { group_id: groupId, watering_id: wateringId },
      pagination: { page: 1, perPage: 10000 },
      sort: { field: 'device_id#watering_id', order: 'asc' },
    });
    if (ugListQueryData) {
      for (const ugData of ugListQueryData.data) {
        console.info(
          `water: ${ugData.watering_id} - device: ${ugData.device_id}`
        );
        const ugDeleteQueryName = `delete${makeQueryNameSingle(
          'deviceWaterings'
        )}`;
        const ugDeleteQuery = this.getQuery(ugDeleteQueryName);
        await this.graphql(ugDeleteQuery, {
          input: {
            group_id: ugData.group_id,
            watering_id: ugData.watering_id,
            device_id: ugData.device_id,
          },
        });
      }
    }
  }

  public async addDeviceToWatering(
    groupId: string,
    deviceId: string,
    wateringId: string
  ) {
    const ugQueryName = `create${makeQueryNameSingle('deviceWaterings')}`;
    const ugQuery = this.getQuery(ugQueryName);
    const ugData = {
      group_id: groupId,
      device_id: deviceId,
      watering_id: wateringId,
    };
    await this.graphql(ugQuery, { input: ugData });
  }

  public async addDevicesToWatering(
    groupId: string,
    deviceIds: string[],
    wateringId: string
  ) {
    // はじめに現在の紐付けをすべてはずす
    await this.deleteWateringFromDevices(groupId, wateringId).catch((e) =>
      console.error(e)
    );

    // 更新内容で更新
    for (const deviceId of deviceIds) {
      await this.addDeviceToWatering(groupId, deviceId, wateringId);
    }
  }
}
