import * as React from 'react';
import {
  Edit,
  SimpleForm,
  TextField,
  TextInput,
  DateField,
  DateInput,
  ChipField,
  ArrayField,
  ReferenceManyField,
  SingleFieldList,
  Datagrid,
  useShowController,
  FieldProps,
  SelectArrayInput,
  ReferenceArrayInput,
  SelectInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { LinkField } from '../../component/LinkField';
import { Identifier, Record } from 'ra-core';

const CultivationEdit = (props: any) => {
  const { record } = useShowController<any>(props);
  const classes = useStyles();
  const history = useHistory();
  if (!record) return null;
  if (!Array.isArray(record.ref_field_works)) return null;
  if (!Array.isArray(record.ref_field_notes)) return null;
  const jumpToFieldWork = (
    id: Identifier,
    basePath: string,
    record: Record
  ): string => {
    history.push(`/fieldWorks/${record.id}/edit`);
    return '';
  };
  const jumpToFieldNote = (
    id: Identifier,
    basePath: string,
    record: Record
  ): string => {
    history.push(`/fieldNotes/${record.id}/edit`);
    return '';
  };
  const jumpToWatering = (
    id: Identifier,
    basePath: string,
    record: Record
  ): string => {
    history.push(`/waterings/${record.id}/edit`);
    return '';
  };
  const jumpToDataset = (
    id: Identifier,
    basePath: string,
    record: Record
  ): string => {
    history.push(`/datasets/${record.id}/edit`);
    return '';
  };
  return (
    <Edit {...props} className={classes.root}>
      <SimpleForm>
        <TextField source="id" />
        <TextField label="Crop Name" source="ref_growing.name_ja" />
        <DateInput source="start_date" />

        <ArrayField label="Field Works" source="ref_field_works" fieldKey="id">
          <Datagrid rowClick={jumpToFieldWork}>
            <TextField source="display_name" />
            <TextField source="work_type" />
            <DateField source="plan_date_start" />
            <DateField source="plan_date_end" />
          </Datagrid>
        </ArrayField>
        <LinkField
          href="/fieldWorks/create"
          text="Add a new field work"
          newTab={true}
        />

        <ArrayField label="Field Notes" source="ref_field_notes" fieldKey="id">
          <Datagrid rowClick={jumpToFieldNote}>
            <DateField source="note_date_time" />
            <TextField source="note" />
          </Datagrid>
        </ArrayField>
        <LinkField
          href="/fieldNotes/create"
          text="Add a new field note"
          newTab={true}
        />

        <ArrayField label="Waterings" source="ref_waterings" fieldKey="id">
          <Datagrid rowClick={jumpToWatering}>
            <TextField source="display_name" />
          </Datagrid>
        </ArrayField>
        <LinkField
          href="/waterings/create"
          text="Add a new watering"
          newTab={true}
        />

        <ArrayField label="Datasets" source="ref_datasets" fieldKey="id">
          <Datagrid rowClick={jumpToDataset}>
            <TextField source="id" />
          </Datagrid>
        </ArrayField>
        <LinkField
          href="/datasets/create"
          text="Add a new dataset"
          newTab={true}
        />
      </SimpleForm>
    </Edit>
  );
};

export default CultivationEdit;

const useStyles = makeStyles({
  root: { width: 600, margin: 'auto' },
  spacer: { height: 20 },
});
