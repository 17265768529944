import * as React from 'react';
import { FC } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {
  useShowController,
  ReferenceField,
  TextField,
  FieldProps,
} from 'react-admin';

import { ControlPlane } from '../../types';

const GrowingShow = (props: any) => {
  const { record } = useShowController<ControlPlane>(props);
  const classes = useStyles();
  if (!record) return null;
  return null;
  // return (
  //     <Card className={classes.root}>
  //         <CardContent>
  //             <Grid container spacing={2}>
  //                 <Grid item xs={6}>
  //                     <Typography variant="h6" gutterBottom>
  //                         Control Plane
  //                     </Typography>
  //                 </Grid>
  //                 <Grid item xs={6}>
  //                     <Typography variant="h6" gutterBottom align="right">
  //                         ID {record.id}
  //                     </Typography>
  //                 </Grid>
  //             </Grid>
  //             <div className={classes.spacer}>&nbsp;</div>
  //             <Grid container spacing={2}>
  //                 <Grid item xs={6}>
  //                     <Typography variant="h6" gutterBottom align="right">
  //                         Group {record.group_id}
  //                     </Typography>
  //                 </Grid>
  //             </Grid>
  //         </CardContent>
  //     </Card>
  // );
};

export default GrowingShow;

const useStyles = makeStyles({
  root: { width: 600, margin: 'auto' },
  spacer: { height: 20 },
});
