import * as React from 'react';
import { FC } from 'react';
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  RichTextField,
  DateField,
} from 'react-admin';

const FieldWorkList: FC<ListProps> = (props) => (
  <List {...props} perPage={25} title="Field Works">
    <Datagrid rowClick="edit">
      <TextField source="cultivation_id" />
      <TextField source="display_name" />
      <TextField source="work_type" />
      <DateField source="plan_date_start" />
      <DateField source="plan_date_end" />
      <RichTextField source="plan_description" />
    </Datagrid>
  </List>
);

export default FieldWorkList;
