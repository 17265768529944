import { TranslationMessages } from 'react-admin';
import englishMessages from 'ra-language-english';

const customEnglishMessages: TranslationMessages = {
  ...englishMessages,
  pos: {
    search: 'Search',
    configuration: 'Configuration',
    language: 'Language',
    theme: {
      name: 'Theme',
      light: 'Light',
      dark: 'Dark',
    },
    dashboard: {
      dashboard: 'Dashboard',
    },
    menu: {
      field: 'Field',
      control: 'Control',
      data: 'Data',
      admin: 'Admin',
    },
  },
  data: {
    sensorData: {
      name: 'Sensor Data',
    },
    wateringHistory: {
      name: 'Watering History',
    },
    imageData: {
      name: 'Images',
    },
  },
  resources: {
    cultivations: {
      name: 'Cultivation |||| Cultivations',
      fields: {
        id: 'Cultivation Name',
        growing_id: 'Growing ID',
        watering_group_id: 'Watering Group ID',
      },
    },
    fieldWorks: {
      name: 'FieldWork |||| FieldWorks',
      fields: {
        id: 'ID',
        cultivation_id: 'Cultivation Name',
      },
    },
    fieldNotes: {
      name: 'FieldNote |||| FieldNotes',
      fields: {
        id: 'ID',
      },
    },
    growings: {
      name: 'Growing |||| Growings',
      fields: {
        id: 'ID',
      },
    },
    devices: {
      name: 'Device |||| Devices',
      fields: {
        id: 'ID',
      },
    },
    waterings: {
      name: 'Watering |||| Waterings',
      fields: {
        id: 'ID',
      },
    },
    datasets: {
      name: 'Dataset |||| Datasets',
      fields: {
        id: 'ID',
      },
    },
    groups: {
      name: 'Group |||| Groups',
      fields: {
        id: 'Group ID',
        display_name: 'Group Name',
        plan: 'Plan',
      },
    },
    users: {
      name: 'User |||| Users',
      fields: {
        id: 'User ID',
        profile: 'Profile',
      },
    },
  },
};

export default customEnglishMessages;
