import * as React from 'react';
import {
  Create,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
  required,
  DateInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import usePromise from 'react-promise';
import { getGroupId } from '../../dataProvider/graphql/utils';

const CultivationCreate = (props: any) => {
  const classes = useStyles();
  const { value, loading } = usePromise<string>(getGroupId);
  if (loading || !value) return null;
  return (
    <Create {...props} className={classes.root}>
      <SimpleForm>
        <TextInput source="group_id" defaultValue={value} disabled={true} />
        <TextInput source="id" validate={required()} />
        <DateInput source="start_date" />
        <ReferenceArrayInput
          source="growing_id"
          reference="growings"
          label="Growing"
          validate={required()}
          perPage={1000}
          sort={{ field: 'lifeform', order: 'ASC' }}>
          <SelectInput optionText="name_ja" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};

export default CultivationCreate;

const useStyles = makeStyles({
  root: { width: 600, margin: 'auto' },
  spacer: { height: 20 },
});
