import * as React from 'react';
import { FC } from 'react';
import { List, ListProps, Datagrid, TextField } from 'react-admin';

const DatasetList: FC<ListProps> = (props) => (
  <List {...props} perPage={25} title="Watering Groups">
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="cultivation_id" />
      <TextField source="description" />
    </Datagrid>
  </List>
);

export default DatasetList;
