import * as React from 'react';
import { FC } from 'react';
import { List, ListProps, Datagrid, TextField, DateField } from 'react-admin';

const GroupList: FC<ListProps> = (props) => (
  <List {...props} perPage={25} title="Groups" bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="display_name" />
      <TextField source="ref_owner.username" />
      <TextField source="plan" />
      <DateField source="createdAt" />
    </Datagrid>
  </List>
);

export default GroupList;
