import * as React from 'react';
import { FC } from 'react';
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
} from 'react-admin';

const WateringList: FC<ListProps> = (props) => (
  <List {...props} perPage={25} title="Watering Groups">
    <Datagrid rowClick="edit">
      <TextField source="display_name" />
      <TextField source="cultivation_id" />
      <TextField source="watering_type" />
      <BooleanField source="enabled" />
    </Datagrid>
  </List>
);

export default WateringList;
