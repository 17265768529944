import * as React from 'react';
import { forwardRef } from 'react';
import { AppBar, UserMenu, MenuItemLink, useTranslate } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core/styles';
import { AmplifySignOut } from '@aws-amplify/ui-react';
import Select from 'react-select';
import usePromise from 'react-promise';

import {
  listBelongingGroups,
  getGroupId,
  getUserInfo,
  UserInfo,
} from '../dataProvider/graphql/utils';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
});

const ConfigurationMenu = forwardRef<any, any>((props, ref) => {
  const translate = useTranslate();
  return (
    <MenuItemLink
      ref={ref}
      to="/configuration"
      primaryText={translate('pos.configuration')}
      leftIcon={<SettingsIcon />}
      onClick={props.onClick}
      sidebarIsOpen
    />
  );
});

const UserNameMenu = (props: any) => {
  const { value, loading } = usePromise<UserInfo>(getUserInfo);
  if (loading || !value) return null;
  return <p>{value.username}</p>;
};

const CustomUserMenu = (props: any) => (
  // <UserMenu {...props}>  // uncomment out for original Logout
  <UserMenu>
    <ConfigurationMenu />
    <UserNameMenu />
    <AmplifySignOut />
  </UserMenu>
);

interface Props {
  options: any;
  defaultGroupId: string;
}

class GroupSelection extends React.Component<Props> {
  state = {
    selectedOption: null,
  };

  constructor(props: Props) {
    super(props);
    // @ts-ignore
    this.state.selectedOption = {
      label: props.defaultGroupId,
      value: props.defaultGroupId,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (selectedOption: any, actionMeta: any) => {
    if (!actionMeta.action || actionMeta.action !== 'select-option') return;
    if (!selectedOption) return;
    localStorage.setItem('/morimoritor/currentGroup', selectedOption.value);
    this.setState({ selectedOption });
  };

  render() {
    const { selectedOption } = this.state;
    return (
      <div>
        <span aria-setsize={100}>Select your group</span>
        <Select
          options={this.props.options}
          onChange={this.handleChange}
          value={selectedOption}
          menuShouldScrollIntoView={true}
        />
      </div>
    );
  }
}

const CustomAppBar = (props: any) => {
  const classes = useStyles();
  // @ts-ignore
  const groupsResult = usePromise<string[]>(listBelongingGroups);
  const { value, loading } = usePromise<string>(getGroupId);
  if (groupsResult.loading || !groupsResult.value) return null;
  if (loading || !value) return null;
  const options = groupsResult.value.map((group) => {
    return { value: group, label: group };
  });
  return (
    <AppBar {...props} elevation={1} userMenu={<CustomUserMenu />}>
      <span className={classes.spacer} />
      <GroupSelection {...{ options, defaultGroupId: value }} />
    </AppBar>
  );
};

export default CustomAppBar;
