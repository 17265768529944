import * as React from 'react';
import { useState } from 'react';
import usePromise from 'react-promise';
import {
  Edit,
  TextField,
  TextInput,
  DateField,
  ChipField,
  ArrayField,
  SingleFieldList,
  SimpleForm,
  useShowController,
  SelectInput,
  ReferenceInput,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { makeStyles } from '@material-ui/core/styles';
import { PhotoInput } from '../../component/PhotoUpload';
import { StripeForm, CheckoutForm } from '../../component/StripeForm';
import { MultiPhotoField } from '../../component/MultiPhotoField';
import { getGroupId, getUserId } from '../../dataProvider/graphql/utils';

const UserEdit = (props: any) => {
  const { record } = useShowController<any>(props);
  const classes = useStyles();
  const [images, setImages] = useState('');
  const groupId = usePromise<string>(getGroupId);
  const userId = usePromise<string>(getUserId);
  const onChangeImages = (data) => {
    setImages(data);
  };
  if (groupId.loading || !groupId.value || userId.loading || !userId.value)
    return null;
  if (!record) return null;
  if (!Array.isArray(record.ref_groups)) return null;
  return (
    <div>
      <Edit {...props} className={classes.root}>
        <SimpleForm>
          <TextField source="id" />
          <TextInput source="nickname" />
          <ReferenceInput
            label="Line user"
            source="line_user_id"
            reference="lineUsers"
            fieldKey="id">
            <SelectInput optionText="line_user_name" defaultValue="default" />
          </ReferenceInput>
          <DateField label="Last update" source="updatedAt" />
          <ArrayField label="Group" source="ref_groups">
            <SingleFieldList>
              <ChipField label="Group ID" source="group_id" />
            </SingleFieldList>
          </ArrayField>
          <RichTextInput source="profile" />
          <PhotoInput
            source="image_key"
            resource="users"
            onUpload={(e) => {
              onChangeImages(e);
            }}
          />
          <MultiPhotoField
            resource="users"
            // @ts-ignore
            groupId={groupId.value}
            userId={userId.value}
            reload={images}
          />
          <TextField label="Registered card" source="last4" />
          <StripeForm client_secret={record.client_secret} />
        </SimpleForm>
      </Edit>
      {/* <Elements stripe={stripePromise}>
                <StripeForm />
            </Elements> */}
    </div>
  );
};

export default UserEdit;

const useStyles = makeStyles({
  root: { width: 600, margin: 'auto' },
  spacer: { height: 20 },
});
