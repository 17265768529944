import { SelectInput } from 'react-admin';
import React from 'react';

function appendZero(x: number): string {
  if (x < 10) return '0' + String(Math.round(x));
  return String(Math.round(x));
}

export function appendTimeZero(x: number): string {
  const i = Math.round(x);
  if (0 <= i && i < 10) return '+0' + String(i);
  if (-10 < i && i < 0) return '-0' + String(Math.abs(i));
  if (10 <= i) return '+' + String(i);
  return String(x);
}

const every = 30;
const timeArr = Array.from(
  {
    length: (24 * 60) / every,
  },
  (v, i) => {
    const h = Math.floor((i * every) / 60);
    const m = i * every - h * 60;
    const offset = -new Date().getTimezoneOffset() / 60;
    const tz = appendTimeZero(offset);
    return appendZero(h) + ':' + appendZero(m) + tz + ':00';
  }
);

const timeOptions = timeArr.map((t) => {
  return { id: t, name: t };
});

export const TimeInput = (props: any) => {
  return <SelectInput {...props} choices={timeOptions} />;
};
