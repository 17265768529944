import * as React from 'react';
import {
  Create,
  SimpleForm,
  TextField,
  TextInput,
  DateInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  required,
  DateField,
  RichTextField,
  Datagrid,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { makeStyles } from '@material-ui/core/styles';
import { getWorkTypeOptions } from '../../utils/api';
import usePromise from 'react-promise';
import { getGroupId } from '../../dataProvider/graphql/utils';

const FieldWorkCreate = (props: any) => {
  const { value, loading } = usePromise<string>(getGroupId);
  const classes = useStyles();
  if (loading || !value) return null;
  const workTypeOptions = getWorkTypeOptions();
  return (
    <Create {...props} className={classes.root}>
      <SimpleForm>
        <TextInput source="group_id" defaultValue={value} disabled={true} />
        <ReferenceArrayInput
          source="cultivation_id"
          reference="cultivations"
          validate={required()}>
          <SelectInput optionText="id" />
        </ReferenceArrayInput>
        <TextInput source="display_name" />
        <SelectInput source="work_type" choices={workTypeOptions} />
        <DateInput source="plan_date_start" />
        <DateInput source="plan_date_end" />
        <RichTextInput source="plan_description" />
      </SimpleForm>
    </Create>
  );
};

export default FieldWorkCreate;

const useStyles = makeStyles({
  root: { width: 600, margin: 'auto' },
  spacer: { height: 20 },
});
