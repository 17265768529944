/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'ap-northeast-1',
  aws_cognito_identity_pool_id:
    'ap-northeast-1:830c3d3c-9847-4450-8594-cfef885dcde0',
  aws_cognito_region: 'ap-northeast-1',
  aws_user_pools_id: 'ap-northeast-1_zFJ5hFc1F',
  aws_user_pools_web_client_id: '2vb139043bjeb9dl7jdcu2k4io',
  oauth: {},
  aws_appsync_graphqlEndpoint:
    'https://bodznp6bc5hxzpwu3ldccsyevm.appsync-api.ap-northeast-1.amazonaws.com/graphql',
  aws_appsync_region: 'ap-northeast-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_user_files_s3_bucket:
    'morimoritorbcb45f1b106e4936ae01da3361a1d756155558-prod',
  aws_user_files_s3_bucket_region: 'ap-northeast-1',
};

export default awsmobile;
