import * as React from 'react';
import { FieldProps, InputProps } from 'react-admin';
import { FC } from 'react';
import usePromise from 'react-promise';
import Resizer from 'react-image-file-resizer';
import { getUserInfo, UserInfo } from '../dataProvider/graphql/utils';
import { getDataProvider } from '../dataProvider/combined';
import { postUserImage, postGroupImage } from '../dataProvider/customRest';

/**
 * https://www.npmjs.com/package/react-image-file-resizer
 * @param file File
 * @returns
 */
const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      400,
      400,
      'JPEG',
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      // base64, blob or file.
      'file'
    );
  });

interface Props {
  id: string;
  resource: 'users' | 'fieldWorks';
  userInfo: UserInfo;
  groupId: string | undefined;
  onUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

class ImageUpload extends React.Component<Props> {
  dataProvider: any;

  constructor(props: Props) {
    super(props);
    this.dataProvider = getDataProvider(props.resource);
  }

  generateUploadKey(file: File): string {
    if (this.props.resource == 'users')
      return `images/thumbnail/users/${this.props.userInfo.username}/${file.name}`;
    else {
      if (!this.props.groupId) throw Error('failed to get groupId for storage');
      return `images/thumbnail/groups/${this.props.groupId}/${this.props.resource}/${this.props.id}/${file.name}`;
    }
  }

  async onChange(e: React.ChangeEvent<HTMLInputElement>) {
    // console.log(`props: ${JSON.stringify(this.props)}`)
    if (!e.target.files) return null;
    const file = await resizeFile(e.target.files[0]);
    // @ts-ignore
    const uploadKey = this.generateUploadKey(file);

    const formData = new FormData();
    // @ts-ignore
    formData.append('file', file);
    formData.append('uploadKey', uploadKey);
    if (this.props.resource === 'users') {
      await postUserImage(formData);
    } else {
      await postGroupImage(formData);
    }
    this.props.onUpload(e);
  }

  render() {
    return (
      <div>
        <h3>Photos</h3>
        <input
          type="file"
          accept="image/*"
          onChange={(e) => this.onChange(e)}
        />
      </div>
    );
  }
}

export const PhotoInput: FC<InputProps> = ({
  record,
  resource,
  groupId,
  onUpload,
}) => {
  const { value, loading } = usePromise<UserInfo>(getUserInfo);
  const onChangeImages = (data) => {
    // console.log('PhotoInput');
    onUpload(data);
  };
  if (loading || !value) return null;
  return record ? (
    // @ts-ignore
    <ImageUpload
      {...{
        ...record,
        userInfo: value,
        resource,
        groupId,
        onUpload: (e) => onChangeImages(e),
      }}
    />
  ) : null;
};
