import React from 'react';
import Loader from 'react-loader-spinner';

export const MySpinner = (props: any) => {
  return (
    <div>
      <br />
      <br />
      <Loader type="Oval" color="#589507" height="20%" width="70%" />
    </div>
  );
};
