import * as React from 'react';
import { useState } from 'react';
import {
  Edit,
  SimpleForm,
  TextField,
  TextInput,
  SelectInput,
  DateField,
  DateInput,
  ChipField,
  ArrayField,
  ReferenceManyField,
  SingleFieldList,
  Datagrid,
  useShowController,
  FieldProps,
  SelectArrayInput,
  ReferenceArrayInput,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { makeStyles } from '@material-ui/core/styles';
import { PhotoInput } from '../../component/PhotoUpload';
import { MultiPhotoField } from '../../component/MultiPhotoField';
import usePromise from 'react-promise';
import { getGroupId } from '../../dataProvider/graphql/utils';

const FieldWorkEdit = (props: any) => {
  const { record } = useShowController<any>(props);
  const { value, loading } = usePromise<string>(getGroupId);
  const classes = useStyles();
  const [images, setImages] = useState('');
  const onChangeImages = (data) => {
    setImages(data);
  };
  if (loading || !value) return null;
  if (!record) return null;
  // if (!Array.isArray(record.ref_users)) return null;
  return (
    <Edit {...props} className={classes.root}>
      <SimpleForm>
        <TextField source="cultivation_id" />
        <TextInput source="display_name" />
        <DateInput source="plan_date_start" />
        <DateInput source="plan_date_end" />
        <RichTextInput source="plan_description" />
        <PhotoInput
          source="image_key"
          resource="fieldWorks"
          groupId={value}
          onUpload={(e) => {
            onChangeImages(e);
          }}
        />
        <MultiPhotoField
          resource="fieldWorks"
          // @ts-ignore
          groupId={value}
          reload={images}
        />
      </SimpleForm>
    </Edit>
  );
};

export default FieldWorkEdit;

const useStyles = makeStyles({
  root: { width: 600, margin: 'auto' },
  spacer: { height: 20 },
});
