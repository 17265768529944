import * as React from 'react';
import { FC } from 'react';
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  BooleanField,
  DateField,
  ReferenceField,
  NumberField,
  Filter,
  FilterProps,
  DateInput,
} from 'react-admin';

const LineUserList: FC<ListProps> = (props) => (
  <List>
    <Datagrid>
      <TextField source="id" />
      <BooleanField source="enabled" />
      <TextField source="line_user_name" />
    </Datagrid>
  </List>
);

export default LineUserList;
