import { stringify } from 'query-string';
import {
  GET_LIST,
  GET_ONE,
  CREATE,
  UPDATE,
  DELETE,
  GET_MANY,
  GET_MANY_REFERENCE,
  UPDATE_MANY,
  DELETE_MANY,
  CreateParams,
} from 'react-admin';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import { buildDataProvider } from './graphql';
import { DataProvider } from 'react-admin';
import {
  createGroupResource,
  createUserResource,
  deleteGroupResource,
  deleteManyDevices,
  deleteUserResource,
} from './customRest';
import { getExtraParams } from './graphql/utils';
import { formatDate } from '../utils/util';
const graphqlProvider = buildDataProvider({ queries, mutations });
// const graphqlProvider = buildGraphqlProvider();

const dataProviders = [
  { dataProvider: graphqlProvider, resources: ['default'] },
];

export const getDataProvider = (resource: string): DataProvider => {
  const dpMap = dataProviders.find((dp) => dp.resources.includes(resource));
  // @ts-ignore
  return dpMap !== undefined ? dpMap.dataProvider : graphqlProvider;
};

export const createDefaultFieldWorks = async (
  resource: string,
  params: any
): Promise<any> => {
  const dp = getDataProvider(resource);
  const { group_id } = await getExtraParams();
  const today = formatDate(new Date());
  await dp.create('fieldWorks', {
    data: {
      group_id,
      cultivation_id: params.data.id,
      work_type: 'OBSERVATION',
      plan_date_start: today,
    },
  } as CreateParams);
  await dp.create('fieldWorks', {
    data: {
      group_id,
      cultivation_id: params.data.id,
      work_type: 'HARVEST',
    },
  } as CreateParams);
};

/**
 * Maps react-admin queries to my REST API
 *
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param params
 * @returns {Promise} the Promise for a data response
 */
export default async (type: string, resource: string, params: any) => {
  const dp = getDataProvider(resource);
  const groupResources = ['devices'];
  const userResources = ['groups'];
  switch (type) {
    case GET_LIST:
      return dp.getList(resource, params);
    case GET_ONE:
      return dp.getOne(resource, params);
    case CREATE:
      if (groupResources.includes(resource)) {
        await createGroupResource(resource, params);
        // const res = await dp.create(resource, params);
        // return res;
        return {data: {id: params.data.id}};
      }
      if (userResources.includes(resource)) {
        await createUserResource(resource, params);
        // group作成の際に実際には作られているのに、レスポンスが失敗なのでハンドリング
        const res = await dp.create(resource, params).catch(e => {
          console.info(e);
          return {data: {id: params.data.id}};
        });
        return res;
      }
      if (resource === 'cultivations') {
        await createDefaultFieldWorks(resource, params);
      }
      return dp.create(resource, params);
    case UPDATE:
      return dp.update(resource, params);
    case UPDATE_MANY:
      return dp.updateMany(resource, params);
    case DELETE:
      if (groupResources.includes(resource)) {
        const res = await dp.delete(resource, params);
        await deleteGroupResource(resource, params);
        return res;
      }
      if (userResources.includes(resource)) {
        const res = await dp.delete(resource, params).catch(e => {
          console.info(e);
          return {data: {id: params.id}};
        });
        await deleteUserResource(resource, params);
        return res;
      }
      return dp.delete(resource, params);
    case DELETE_MANY:
      if (resource === 'devices') {
        const res = await dp.deleteMany(resource, params);
        await deleteManyDevices(resource, params);
        return res;
      }
      return dp.deleteMany(resource, params);
    case GET_MANY:
      return dp.getMany(resource, params);
    case GET_MANY_REFERENCE:
      return dp.getManyReference(resource, params);
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }
};
