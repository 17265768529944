/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      display_name
      owner_user_id
      ref_owner {
        id
        line_user_id
        email
        username
        sub
        ref_line_user {
          id
          enabled
          line_user_name
          profile
          createdAt
          updatedAt
        }
        nickname
        profile
        image_key
        stripe_customer_id
        last4
        active_subscription_items
        ref_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      plan
      ref_users {
        items {
          id
          group_id
          user_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $id: ID
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGroups(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        display_name
        owner_user_id
        ref_owner {
          id
          line_user_id
          email
          username
          sub
          nickname
          profile
          image_key
          stripe_customer_id
          last4
          active_subscription_items
          createdAt
          updatedAt
        }
        plan
        ref_users {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      line_user_id
      email
      username
      sub
      ref_line_user {
        id
        enabled
        line_user_name
        profile
        createdAt
        updatedAt
      }
      nickname
      profile
      image_key
      stripe_customer_id
      last4
      active_subscription_items
      ref_groups {
        items {
          id
          group_id
          user_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $id: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        line_user_id
        email
        username
        sub
        ref_line_user {
          id
          enabled
          line_user_name
          profile
          createdAt
          updatedAt
        }
        nickname
        profile
        image_key
        stripe_customer_id
        last4
        active_subscription_items
        ref_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserGroup = /* GraphQL */ `
  query GetUserGroup($id: ID!) {
    getUserGroup(id: $id) {
      id
      group_id
      user_id
      ref_user {
        id
        line_user_id
        email
        username
        sub
        ref_line_user {
          id
          enabled
          line_user_name
          profile
          createdAt
          updatedAt
        }
        nickname
        profile
        image_key
        stripe_customer_id
        last4
        active_subscription_items
        ref_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      ref_group {
        id
        display_name
        owner_user_id
        ref_owner {
          id
          line_user_id
          email
          username
          sub
          nickname
          profile
          image_key
          stripe_customer_id
          last4
          active_subscription_items
          createdAt
          updatedAt
        }
        plan
        ref_users {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUserGroups = /* GraphQL */ `
  query ListUserGroups(
    $id: ID
    $filter: ModelUserGroupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserGroups(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        group_id
        user_id
        ref_user {
          id
          line_user_id
          email
          username
          sub
          nickname
          profile
          image_key
          stripe_customer_id
          last4
          active_subscription_items
          createdAt
          updatedAt
        }
        ref_group {
          id
          display_name
          owner_user_id
          plan
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLineUser = /* GraphQL */ `
  query GetLineUser($id: ID!) {
    getLineUser(id: $id) {
      id
      enabled
      line_user_name
      profile
      createdAt
      updatedAt
    }
  }
`;
export const listLineUsers = /* GraphQL */ `
  query ListLineUsers(
    $id: ID
    $filter: ModelLineUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLineUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        enabled
        line_user_name
        profile
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGrowing = /* GraphQL */ `
  query GetGrowing($id: ID!) {
    getGrowing(id: $id) {
      id
      lifeform
      family
      species
      name_ja
      family_ja
      link_ja
      name_en
      link_en
      description
      createdAt
      updatedAt
    }
  }
`;
export const listGrowings = /* GraphQL */ `
  query ListGrowings(
    $id: ID
    $filter: ModelGrowingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGrowings(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        lifeform
        family
        species
        name_ja
        family_ja
        link_ja
        name_en
        link_en
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFieldNote = /* GraphQL */ `
  query GetFieldNote($group_id: ID!, $id: ID!) {
    getFieldNote(group_id: $group_id, id: $id) {
      group_id
      id
      note_date_time
      note
      image_key
      cultivation_id
      ref_cultivation {
        group_id
        ref_group {
          id
          display_name
          owner_user_id
          plan
          createdAt
          updatedAt
        }
        id
        description
        start_date
        growing_id
        ref_growing {
          id
          lifeform
          family
          species
          name_ja
          family_ja
          link_ja
          name_en
          link_en
          description
          createdAt
          updatedAt
        }
        ref_waterings {
          nextToken
        }
        ref_field_works {
          nextToken
        }
        ref_datasets {
          nextToken
        }
        ref_field_notes {
          nextToken
        }
        createdAt
        updatedAt
      }
      field_work_id
      ref_field_work {
        group_id
        id
        work_type
        display_name
        cultivation_id
        ref_cultivation {
          group_id
          id
          description
          start_date
          growing_id
          createdAt
          updatedAt
        }
        plan_date_start
        plan_date_end
        plan_description
        amount
        unit
        ref_field_notes {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFieldNotes = /* GraphQL */ `
  query ListFieldNotes(
    $group_id: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelFieldNoteFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFieldNotes(
      group_id: $group_id
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        group_id
        id
        note_date_time
        note
        image_key
        cultivation_id
        ref_cultivation {
          group_id
          id
          description
          start_date
          growing_id
          createdAt
          updatedAt
        }
        field_work_id
        ref_field_work {
          group_id
          id
          work_type
          display_name
          cultivation_id
          plan_date_start
          plan_date_end
          plan_description
          amount
          unit
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFieldWork = /* GraphQL */ `
  query GetFieldWork($group_id: ID!, $id: ID!) {
    getFieldWork(group_id: $group_id, id: $id) {
      group_id
      id
      work_type
      display_name
      cultivation_id
      ref_cultivation {
        group_id
        ref_group {
          id
          display_name
          owner_user_id
          plan
          createdAt
          updatedAt
        }
        id
        description
        start_date
        growing_id
        ref_growing {
          id
          lifeform
          family
          species
          name_ja
          family_ja
          link_ja
          name_en
          link_en
          description
          createdAt
          updatedAt
        }
        ref_waterings {
          nextToken
        }
        ref_field_works {
          nextToken
        }
        ref_datasets {
          nextToken
        }
        ref_field_notes {
          nextToken
        }
        createdAt
        updatedAt
      }
      plan_date_start
      plan_date_end
      plan_description
      amount
      unit
      ref_field_notes {
        items {
          group_id
          id
          note_date_time
          note
          image_key
          cultivation_id
          field_work_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFieldWorks = /* GraphQL */ `
  query ListFieldWorks(
    $group_id: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelFieldWorkFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFieldWorks(
      group_id: $group_id
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        group_id
        id
        work_type
        display_name
        cultivation_id
        ref_cultivation {
          group_id
          id
          description
          start_date
          growing_id
          createdAt
          updatedAt
        }
        plan_date_start
        plan_date_end
        plan_description
        amount
        unit
        ref_field_notes {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCultivation = /* GraphQL */ `
  query GetCultivation($group_id: ID!, $id: ID!) {
    getCultivation(group_id: $group_id, id: $id) {
      group_id
      ref_group {
        id
        display_name
        owner_user_id
        ref_owner {
          id
          line_user_id
          email
          username
          sub
          nickname
          profile
          image_key
          stripe_customer_id
          last4
          active_subscription_items
          createdAt
          updatedAt
        }
        plan
        ref_users {
          nextToken
        }
        createdAt
        updatedAt
      }
      id
      description
      start_date
      growing_id
      ref_growing {
        id
        lifeform
        family
        species
        name_ja
        family_ja
        link_ja
        name_en
        link_en
        description
        createdAt
        updatedAt
      }
      ref_waterings {
        items {
          group_id
          id
          display_name
          watering_type
          enabled
          span_min
          span_max
          period_start_time
          period_end_time
          fixed_span
          every
          target_metric
          target_value
          kp
          ki
          kd
          _last_time
          _last_output
          _last_input
          cultivation_id
          createdAt
          updatedAt
        }
        nextToken
      }
      ref_field_works {
        items {
          group_id
          id
          work_type
          display_name
          cultivation_id
          plan_date_start
          plan_date_end
          plan_description
          amount
          unit
          createdAt
          updatedAt
        }
        nextToken
      }
      ref_datasets {
        items {
          group_id
          id
          data_type
          timezone
          description
          cultivation_id
          createdAt
          updatedAt
        }
        nextToken
      }
      ref_field_notes {
        items {
          group_id
          id
          note_date_time
          note
          image_key
          cultivation_id
          field_work_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCultivations = /* GraphQL */ `
  query ListCultivations(
    $group_id: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelCultivationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCultivations(
      group_id: $group_id
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        group_id
        ref_group {
          id
          display_name
          owner_user_id
          plan
          createdAt
          updatedAt
        }
        id
        description
        start_date
        growing_id
        ref_growing {
          id
          lifeform
          family
          species
          name_ja
          family_ja
          link_ja
          name_en
          link_en
          description
          createdAt
          updatedAt
        }
        ref_waterings {
          nextToken
        }
        ref_field_works {
          nextToken
        }
        ref_datasets {
          nextToken
        }
        ref_field_notes {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWatering = /* GraphQL */ `
  query GetWatering($group_id: ID!, $id: ID!) {
    getWatering(group_id: $group_id, id: $id) {
      group_id
      id
      display_name
      watering_type
      enabled
      span_min
      span_max
      period_start_time
      period_end_time
      fixed_span
      every
      target_metric
      target_value
      kp
      ki
      kd
      _last_time
      _last_output
      _last_input
      cultivation_id
      ref_cultivation {
        group_id
        ref_group {
          id
          display_name
          owner_user_id
          plan
          createdAt
          updatedAt
        }
        id
        description
        start_date
        growing_id
        ref_growing {
          id
          lifeform
          family
          species
          name_ja
          family_ja
          link_ja
          name_en
          link_en
          description
          createdAt
          updatedAt
        }
        ref_waterings {
          nextToken
        }
        ref_field_works {
          nextToken
        }
        ref_datasets {
          nextToken
        }
        ref_field_notes {
          nextToken
        }
        createdAt
        updatedAt
      }
      ref_devices {
        items {
          group_id
          device_id
          watering_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listWaterings = /* GraphQL */ `
  query ListWaterings(
    $group_id: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelWateringFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWaterings(
      group_id: $group_id
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        group_id
        id
        display_name
        watering_type
        enabled
        span_min
        span_max
        period_start_time
        period_end_time
        fixed_span
        every
        target_metric
        target_value
        kp
        ki
        kd
        _last_time
        _last_output
        _last_input
        cultivation_id
        ref_cultivation {
          group_id
          id
          description
          start_date
          growing_id
          createdAt
          updatedAt
        }
        ref_devices {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDataset = /* GraphQL */ `
  query GetDataset($group_id: ID!, $id: ID!) {
    getDataset(group_id: $group_id, id: $id) {
      group_id
      id
      data_type
      timezone
      column_map {
        key
        name
      }
      description
      cultivation_id
      ref_cultivation {
        group_id
        ref_group {
          id
          display_name
          owner_user_id
          plan
          createdAt
          updatedAt
        }
        id
        description
        start_date
        growing_id
        ref_growing {
          id
          lifeform
          family
          species
          name_ja
          family_ja
          link_ja
          name_en
          link_en
          description
          createdAt
          updatedAt
        }
        ref_waterings {
          nextToken
        }
        ref_field_works {
          nextToken
        }
        ref_datasets {
          nextToken
        }
        ref_field_notes {
          nextToken
        }
        createdAt
        updatedAt
      }
      ref_devices {
        items {
          group_id
          device_id
          dataset_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listDatasets = /* GraphQL */ `
  query ListDatasets(
    $group_id: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelDatasetFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDatasets(
      group_id: $group_id
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        group_id
        id
        data_type
        timezone
        column_map {
          key
          name
        }
        description
        cultivation_id
        ref_cultivation {
          group_id
          id
          description
          start_date
          growing_id
          createdAt
          updatedAt
        }
        ref_devices {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDevice = /* GraphQL */ `
  query GetDevice($group_id: ID!, $id: ID!) {
    getDevice(group_id: $group_id, id: $id) {
      group_id
      id
      display_name
      device_type
      description
      enabled
      certificate
      private_key
      endpoint
      createdAt
      updatedAt
    }
  }
`;
export const listDevices = /* GraphQL */ `
  query ListDevices(
    $group_id: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDevices(
      group_id: $group_id
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        group_id
        id
        display_name
        device_type
        description
        enabled
        certificate
        private_key
        endpoint
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDeviceDataset = /* GraphQL */ `
  query GetDeviceDataset($group_id: ID!, $device_id: ID!, $dataset_id: ID!) {
    getDeviceDataset(
      group_id: $group_id
      device_id: $device_id
      dataset_id: $dataset_id
    ) {
      group_id
      device_id
      dataset_id
      ref_device {
        group_id
        id
        display_name
        device_type
        description
        enabled
        certificate
        private_key
        endpoint
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listDeviceDatasets = /* GraphQL */ `
  query ListDeviceDatasets(
    $group_id: ID
    $device_idDataset_id: ModelDeviceDatasetPrimaryCompositeKeyConditionInput
    $filter: ModelDeviceDatasetFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDeviceDatasets(
      group_id: $group_id
      device_idDataset_id: $device_idDataset_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        group_id
        device_id
        dataset_id
        ref_device {
          group_id
          id
          display_name
          device_type
          description
          enabled
          certificate
          private_key
          endpoint
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDeviceWatering = /* GraphQL */ `
  query GetDeviceWatering($group_id: ID!, $device_id: ID!, $watering_id: ID!) {
    getDeviceWatering(
      group_id: $group_id
      device_id: $device_id
      watering_id: $watering_id
    ) {
      group_id
      device_id
      watering_id
      ref_device {
        group_id
        id
        display_name
        device_type
        description
        enabled
        certificate
        private_key
        endpoint
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listDeviceWaterings = /* GraphQL */ `
  query ListDeviceWaterings(
    $group_id: ID
    $device_idWatering_id: ModelDeviceWateringPrimaryCompositeKeyConditionInput
    $filter: ModelDeviceWateringFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDeviceWaterings(
      group_id: $group_id
      device_idWatering_id: $device_idWatering_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        group_id
        device_id
        watering_id
        ref_device {
          group_id
          id
          display_name
          device_type
          description
          enabled
          certificate
          private_key
          endpoint
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSensorData = /* GraphQL */ `
  query GetSensorData($dataset_id: ID!, $keytime: Int!) {
    getSensorData(dataset_id: $dataset_id, keytime: $keytime) {
      group_id
      dataset_id
      keytime
      ttl
      year
      month
      date
      hour
      datetime
      v0
      v1
      v2
      v3
      v4
      v5
      v6
      v7
      v8
      v9
      createdAt
      updatedAt
    }
  }
`;
export const listSensorDatas = /* GraphQL */ `
  query ListSensorDatas(
    $dataset_id: ID
    $keytime: ModelIntKeyConditionInput
    $filter: ModelSensorDataFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSensorDatas(
      dataset_id: $dataset_id
      keytime: $keytime
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        group_id
        dataset_id
        keytime
        ttl
        year
        month
        date
        hour
        datetime
        v0
        v1
        v2
        v3
        v4
        v5
        v6
        v7
        v8
        v9
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWateringHistory = /* GraphQL */ `
  query GetWateringHistory($watering_id: ID!, $keytime: Int!) {
    getWateringHistory(watering_id: $watering_id, keytime: $keytime) {
      group_id
      watering_id
      keytime
      ttl
      year
      month
      date
      hour
      datetime
      span
      createdAt
      updatedAt
    }
  }
`;
export const listWateringHistorys = /* GraphQL */ `
  query ListWateringHistorys(
    $watering_id: ID
    $keytime: ModelIntKeyConditionInput
    $filter: ModelWateringHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWateringHistorys(
      watering_id: $watering_id
      keytime: $keytime
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        group_id
        watering_id
        keytime
        ttl
        year
        month
        date
        hour
        datetime
        span
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getControlPlane = /* GraphQL */ `
  query GetControlPlane($group_id: ID!, $id: ID!) {
    getControlPlane(group_id: $group_id, id: $id) {
      group_id
      ref_group {
        id
        display_name
        owner_user_id
        ref_owner {
          id
          line_user_id
          email
          username
          sub
          nickname
          profile
          image_key
          stripe_customer_id
          last4
          active_subscription_items
          createdAt
          updatedAt
        }
        plan
        ref_users {
          nextToken
        }
        createdAt
        updatedAt
      }
      id
      display_name
      status
      ssm_activation_code
      ssm_activation_id
      info
      createdAt
      updatedAt
    }
  }
`;
export const listControlPlanes = /* GraphQL */ `
  query ListControlPlanes(
    $group_id: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelControlPlaneFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listControlPlanes(
      group_id: $group_id
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        group_id
        ref_group {
          id
          display_name
          owner_user_id
          plan
          createdAt
          updatedAt
        }
        id
        display_name
        status
        ssm_activation_code
        ssm_activation_id
        info
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUsage = /* GraphQL */ `
  query GetUsage($group_id: ID!, $price_id: ID!, $keytime: Int!) {
    getUsage(group_id: $group_id, price_id: $price_id, keytime: $keytime) {
      group_id
      price_id
      keytime
      year
      month
      date
      datetime
      volume
      createdAt
      updatedAt
    }
  }
`;
export const listUsages = /* GraphQL */ `
  query ListUsages(
    $group_id: ID
    $price_idKeytime: ModelUsagePrimaryCompositeKeyConditionInput
    $filter: ModelUsageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsages(
      group_id: $group_id
      price_idKeytime: $price_idKeytime
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        group_id
        price_id
        keytime
        year
        month
        date
        datetime
        volume
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
