import * as React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  ChipField,
  ArrayField,
  SingleFieldList,
  Datagrid,
  RichTextField,
  useShowController,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import { SinglePhotoField } from '../../component/SinglePhotoField';

const UserShow = (props: any) => {
  const { record } = useShowController<any>(props);
  const classes = useStyles();
  if (!record) return null;
  if (!Array.isArray(record.ref_groups)) return null;
  return (
    <Show {...props} className={classes.root}>
      <SimpleShowLayout>
        <SinglePhotoField />
        <TextField source="id" />
        <TextField source="nickname" />
        <DateField label="Created date" source="createdAt" />
        <ArrayField source="ref_groups">
          <SingleFieldList>
            <ChipField label="Group ID" source="group_id" />
          </SingleFieldList>
        </ArrayField>
        <TextField label="Line user" source="ref_line_user.line_user_name" />
        <RichTextField source="profile" />
      </SimpleShowLayout>
    </Show>
  );
};

export default UserShow;

const useStyles = makeStyles({
  root: { width: 600, margin: 'auto' },
  spacer: { height: 20 },
});
