import * as React from 'react';
import {
  Edit,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  SimpleFormIterator,
  ArrayInput,
  required,
  SelectInput,
  SingleFieldList,
  ChipField,
  ArrayField,
  useShowController,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import usePromise from 'react-promise';
import { getGroupId } from '../../dataProvider/graphql/utils';
import { TimezoneInput } from '../../component/TimezoneInput';
import { keyChoices } from '../../utils/util';

const DatasetEdit = (props: any) => {
  const { record } = useShowController<any>(props);
  const classes = useStyles();
  const { value, loading } = usePromise<string>(getGroupId);
  if (loading || !value) return null;
  if (!record) return null;
  if (!Array.isArray(record.ref_devices)) return null;
  const deviceIds = record.ref_devices.map((d) => d.device_id);
  return (
    <Edit {...props} className={classes.root}>
      <SimpleForm>
        <TextField source="group_id" />
        <TextField source="cultivation_id" />
        <TextField source="id" />
        <ReferenceArrayInput
          label="Devices"
          source="ref_add_devices"
          reference="devices"
          fieldKey="id">
          <SelectArrayInput
            optionText="id"
            allowEmpty
            emptyValue={[]}
            defaultValue={deviceIds}
          />
        </ReferenceArrayInput>
        <TimezoneInput source="timezone" />
        <TextInput source="description" multiline={true} />
        <ArrayInput source="column_map">
          <SimpleFormIterator>
            <SelectInput source="key" choices={keyChoices} label="Data key" />
            <TextInput source="name" label="Column name" />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default DatasetEdit;

const useStyles = makeStyles({
  root: { width: 600, margin: 'auto' },
  spacer: { height: 20 },
});
