import * as React from 'react';
import {
  Edit,
  SimpleForm,
  TextField,
  TextInput,
  BooleanInput,
  NumberInput,
  SelectInput,
  DateTimeInput,
  ReferenceArrayInput,
  SelectArrayInput,
  required,
  useShowController,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import usePromise from 'react-promise';
import { getGroupId } from '../../dataProvider/graphql/utils';
import { getWateringTypeOptions } from '../../utils/api';
import { TimeInput } from '../../component/TimeInput';
import { keyChoices } from '../../utils/util';

const WateringEdit = (props: any) => {
  const { record } = useShowController<any>(props);
  const classes = useStyles();
  const { value, loading } = usePromise<string>(getGroupId);
  if (loading || !value) return null;
  if (!record) return null;
  if (!Array.isArray(record.ref_devices)) return null;
  const deviceIds = record.ref_devices.map((d) => d.device_id);
  const wateringTypeOptions = getWateringTypeOptions();
  return (
    <Edit {...props} className={classes.root}>
      <SimpleForm>
        <TextField source="group_id" />
        <TextField source="cultivation_id" />
        <TextInput source="display_name" validate={required()} />
        <ReferenceArrayInput
          label="Devices"
          source="ref_add_devices"
          reference="devices"
          fieldKey="id">
          <SelectArrayInput
            optionText="id"
            allowEmpty
            emptyValue={[]}
            defaultValue={deviceIds}
          />
        </ReferenceArrayInput>
        <SelectInput
          source="watering_type"
          choices={wateringTypeOptions}
          validate={required()}
        />
        <BooleanInput
          source="enabled"
          defaultValue={true}
          validate={required()}
        />
        <NumberInput
          source="span_min"
          defaultValue={3000}
          validate={required()}
        />
        <NumberInput
          source="span_max"
          defaultValue={900000}
          validate={required()}
        />
        <TimeInput source="period_start_time" validate={required()} />
        <TimeInput source="period_end_time" validate={required()} />
        <NumberInput source="fixed_span" defaultValue={5000} />
        <NumberInput source="every" defaultValue={1} />
        <SelectInput source="target_metric" choices={keyChoices} />
        <NumberInput source="target_value" />
        <NumberInput source="kp" defaultValue={2000} />
        <NumberInput source="ki" defaultValue={0} />
        <NumberInput source="kd" defaultValue={2000} />
      </SimpleForm>
    </Edit>
  );
};

export default WateringEdit;

const useStyles = makeStyles({
  root: { width: 600, margin: 'auto' },
  spacer: { height: 20 },
});
