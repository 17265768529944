/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      id
      display_name
      owner_user_id
      ref_owner {
        id
        line_user_id
        email
        username
        sub
        ref_line_user {
          id
          enabled
          line_user_name
          profile
          createdAt
          updatedAt
        }
        nickname
        profile
        image_key
        stripe_customer_id
        last4
        active_subscription_items
        ref_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      plan
      ref_users {
        items {
          id
          group_id
          user_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
      display_name
      owner_user_id
      ref_owner {
        id
        line_user_id
        email
        username
        sub
        ref_line_user {
          id
          enabled
          line_user_name
          profile
          createdAt
          updatedAt
        }
        nickname
        profile
        image_key
        stripe_customer_id
        last4
        active_subscription_items
        ref_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      plan
      ref_users {
        items {
          id
          group_id
          user_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      display_name
      owner_user_id
      ref_owner {
        id
        line_user_id
        email
        username
        sub
        ref_line_user {
          id
          enabled
          line_user_name
          profile
          createdAt
          updatedAt
        }
        nickname
        profile
        image_key
        stripe_customer_id
        last4
        active_subscription_items
        ref_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      plan
      ref_users {
        items {
          id
          group_id
          user_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      line_user_id
      email
      username
      sub
      ref_line_user {
        id
        enabled
        line_user_name
        profile
        createdAt
        updatedAt
      }
      nickname
      profile
      image_key
      stripe_customer_id
      last4
      active_subscription_items
      ref_groups {
        items {
          id
          group_id
          user_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      line_user_id
      email
      username
      sub
      ref_line_user {
        id
        enabled
        line_user_name
        profile
        createdAt
        updatedAt
      }
      nickname
      profile
      image_key
      stripe_customer_id
      last4
      active_subscription_items
      ref_groups {
        items {
          id
          group_id
          user_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      line_user_id
      email
      username
      sub
      ref_line_user {
        id
        enabled
        line_user_name
        profile
        createdAt
        updatedAt
      }
      nickname
      profile
      image_key
      stripe_customer_id
      last4
      active_subscription_items
      ref_groups {
        items {
          id
          group_id
          user_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUserGroup = /* GraphQL */ `
  mutation CreateUserGroup(
    $input: CreateUserGroupInput!
    $condition: ModelUserGroupConditionInput
  ) {
    createUserGroup(input: $input, condition: $condition) {
      id
      group_id
      user_id
      ref_user {
        id
        line_user_id
        email
        username
        sub
        ref_line_user {
          id
          enabled
          line_user_name
          profile
          createdAt
          updatedAt
        }
        nickname
        profile
        image_key
        stripe_customer_id
        last4
        active_subscription_items
        ref_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      ref_group {
        id
        display_name
        owner_user_id
        ref_owner {
          id
          line_user_id
          email
          username
          sub
          nickname
          profile
          image_key
          stripe_customer_id
          last4
          active_subscription_items
          createdAt
          updatedAt
        }
        plan
        ref_users {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUserGroup = /* GraphQL */ `
  mutation UpdateUserGroup(
    $input: UpdateUserGroupInput!
    $condition: ModelUserGroupConditionInput
  ) {
    updateUserGroup(input: $input, condition: $condition) {
      id
      group_id
      user_id
      ref_user {
        id
        line_user_id
        email
        username
        sub
        ref_line_user {
          id
          enabled
          line_user_name
          profile
          createdAt
          updatedAt
        }
        nickname
        profile
        image_key
        stripe_customer_id
        last4
        active_subscription_items
        ref_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      ref_group {
        id
        display_name
        owner_user_id
        ref_owner {
          id
          line_user_id
          email
          username
          sub
          nickname
          profile
          image_key
          stripe_customer_id
          last4
          active_subscription_items
          createdAt
          updatedAt
        }
        plan
        ref_users {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserGroup = /* GraphQL */ `
  mutation DeleteUserGroup(
    $input: DeleteUserGroupInput!
    $condition: ModelUserGroupConditionInput
  ) {
    deleteUserGroup(input: $input, condition: $condition) {
      id
      group_id
      user_id
      ref_user {
        id
        line_user_id
        email
        username
        sub
        ref_line_user {
          id
          enabled
          line_user_name
          profile
          createdAt
          updatedAt
        }
        nickname
        profile
        image_key
        stripe_customer_id
        last4
        active_subscription_items
        ref_groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      ref_group {
        id
        display_name
        owner_user_id
        ref_owner {
          id
          line_user_id
          email
          username
          sub
          nickname
          profile
          image_key
          stripe_customer_id
          last4
          active_subscription_items
          createdAt
          updatedAt
        }
        plan
        ref_users {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createLineUser = /* GraphQL */ `
  mutation CreateLineUser(
    $input: CreateLineUserInput!
    $condition: ModelLineUserConditionInput
  ) {
    createLineUser(input: $input, condition: $condition) {
      id
      enabled
      line_user_name
      profile
      createdAt
      updatedAt
    }
  }
`;
export const updateLineUser = /* GraphQL */ `
  mutation UpdateLineUser(
    $input: UpdateLineUserInput!
    $condition: ModelLineUserConditionInput
  ) {
    updateLineUser(input: $input, condition: $condition) {
      id
      enabled
      line_user_name
      profile
      createdAt
      updatedAt
    }
  }
`;
export const deleteLineUser = /* GraphQL */ `
  mutation DeleteLineUser(
    $input: DeleteLineUserInput!
    $condition: ModelLineUserConditionInput
  ) {
    deleteLineUser(input: $input, condition: $condition) {
      id
      enabled
      line_user_name
      profile
      createdAt
      updatedAt
    }
  }
`;
export const createGrowing = /* GraphQL */ `
  mutation CreateGrowing(
    $input: CreateGrowingInput!
    $condition: ModelGrowingConditionInput
  ) {
    createGrowing(input: $input, condition: $condition) {
      id
      lifeform
      family
      species
      name_ja
      family_ja
      link_ja
      name_en
      link_en
      description
      createdAt
      updatedAt
    }
  }
`;
export const updateGrowing = /* GraphQL */ `
  mutation UpdateGrowing(
    $input: UpdateGrowingInput!
    $condition: ModelGrowingConditionInput
  ) {
    updateGrowing(input: $input, condition: $condition) {
      id
      lifeform
      family
      species
      name_ja
      family_ja
      link_ja
      name_en
      link_en
      description
      createdAt
      updatedAt
    }
  }
`;
export const deleteGrowing = /* GraphQL */ `
  mutation DeleteGrowing(
    $input: DeleteGrowingInput!
    $condition: ModelGrowingConditionInput
  ) {
    deleteGrowing(input: $input, condition: $condition) {
      id
      lifeform
      family
      species
      name_ja
      family_ja
      link_ja
      name_en
      link_en
      description
      createdAt
      updatedAt
    }
  }
`;
export const createFieldNote = /* GraphQL */ `
  mutation CreateFieldNote(
    $input: CreateFieldNoteInput!
    $condition: ModelFieldNoteConditionInput
  ) {
    createFieldNote(input: $input, condition: $condition) {
      group_id
      id
      note_date_time
      note
      image_key
      cultivation_id
      ref_cultivation {
        group_id
        ref_group {
          id
          display_name
          owner_user_id
          plan
          createdAt
          updatedAt
        }
        id
        description
        start_date
        growing_id
        ref_growing {
          id
          lifeform
          family
          species
          name_ja
          family_ja
          link_ja
          name_en
          link_en
          description
          createdAt
          updatedAt
        }
        ref_waterings {
          nextToken
        }
        ref_field_works {
          nextToken
        }
        ref_datasets {
          nextToken
        }
        ref_field_notes {
          nextToken
        }
        createdAt
        updatedAt
      }
      field_work_id
      ref_field_work {
        group_id
        id
        work_type
        display_name
        cultivation_id
        ref_cultivation {
          group_id
          id
          description
          start_date
          growing_id
          createdAt
          updatedAt
        }
        plan_date_start
        plan_date_end
        plan_description
        amount
        unit
        ref_field_notes {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFieldNote = /* GraphQL */ `
  mutation UpdateFieldNote(
    $input: UpdateFieldNoteInput!
    $condition: ModelFieldNoteConditionInput
  ) {
    updateFieldNote(input: $input, condition: $condition) {
      group_id
      id
      note_date_time
      note
      image_key
      cultivation_id
      ref_cultivation {
        group_id
        ref_group {
          id
          display_name
          owner_user_id
          plan
          createdAt
          updatedAt
        }
        id
        description
        start_date
        growing_id
        ref_growing {
          id
          lifeform
          family
          species
          name_ja
          family_ja
          link_ja
          name_en
          link_en
          description
          createdAt
          updatedAt
        }
        ref_waterings {
          nextToken
        }
        ref_field_works {
          nextToken
        }
        ref_datasets {
          nextToken
        }
        ref_field_notes {
          nextToken
        }
        createdAt
        updatedAt
      }
      field_work_id
      ref_field_work {
        group_id
        id
        work_type
        display_name
        cultivation_id
        ref_cultivation {
          group_id
          id
          description
          start_date
          growing_id
          createdAt
          updatedAt
        }
        plan_date_start
        plan_date_end
        plan_description
        amount
        unit
        ref_field_notes {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFieldNote = /* GraphQL */ `
  mutation DeleteFieldNote(
    $input: DeleteFieldNoteInput!
    $condition: ModelFieldNoteConditionInput
  ) {
    deleteFieldNote(input: $input, condition: $condition) {
      group_id
      id
      note_date_time
      note
      image_key
      cultivation_id
      ref_cultivation {
        group_id
        ref_group {
          id
          display_name
          owner_user_id
          plan
          createdAt
          updatedAt
        }
        id
        description
        start_date
        growing_id
        ref_growing {
          id
          lifeform
          family
          species
          name_ja
          family_ja
          link_ja
          name_en
          link_en
          description
          createdAt
          updatedAt
        }
        ref_waterings {
          nextToken
        }
        ref_field_works {
          nextToken
        }
        ref_datasets {
          nextToken
        }
        ref_field_notes {
          nextToken
        }
        createdAt
        updatedAt
      }
      field_work_id
      ref_field_work {
        group_id
        id
        work_type
        display_name
        cultivation_id
        ref_cultivation {
          group_id
          id
          description
          start_date
          growing_id
          createdAt
          updatedAt
        }
        plan_date_start
        plan_date_end
        plan_description
        amount
        unit
        ref_field_notes {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createFieldWork = /* GraphQL */ `
  mutation CreateFieldWork(
    $input: CreateFieldWorkInput!
    $condition: ModelFieldWorkConditionInput
  ) {
    createFieldWork(input: $input, condition: $condition) {
      group_id
      id
      work_type
      display_name
      cultivation_id
      ref_cultivation {
        group_id
        ref_group {
          id
          display_name
          owner_user_id
          plan
          createdAt
          updatedAt
        }
        id
        description
        start_date
        growing_id
        ref_growing {
          id
          lifeform
          family
          species
          name_ja
          family_ja
          link_ja
          name_en
          link_en
          description
          createdAt
          updatedAt
        }
        ref_waterings {
          nextToken
        }
        ref_field_works {
          nextToken
        }
        ref_datasets {
          nextToken
        }
        ref_field_notes {
          nextToken
        }
        createdAt
        updatedAt
      }
      plan_date_start
      plan_date_end
      plan_description
      amount
      unit
      ref_field_notes {
        items {
          group_id
          id
          note_date_time
          note
          image_key
          cultivation_id
          field_work_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFieldWork = /* GraphQL */ `
  mutation UpdateFieldWork(
    $input: UpdateFieldWorkInput!
    $condition: ModelFieldWorkConditionInput
  ) {
    updateFieldWork(input: $input, condition: $condition) {
      group_id
      id
      work_type
      display_name
      cultivation_id
      ref_cultivation {
        group_id
        ref_group {
          id
          display_name
          owner_user_id
          plan
          createdAt
          updatedAt
        }
        id
        description
        start_date
        growing_id
        ref_growing {
          id
          lifeform
          family
          species
          name_ja
          family_ja
          link_ja
          name_en
          link_en
          description
          createdAt
          updatedAt
        }
        ref_waterings {
          nextToken
        }
        ref_field_works {
          nextToken
        }
        ref_datasets {
          nextToken
        }
        ref_field_notes {
          nextToken
        }
        createdAt
        updatedAt
      }
      plan_date_start
      plan_date_end
      plan_description
      amount
      unit
      ref_field_notes {
        items {
          group_id
          id
          note_date_time
          note
          image_key
          cultivation_id
          field_work_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFieldWork = /* GraphQL */ `
  mutation DeleteFieldWork(
    $input: DeleteFieldWorkInput!
    $condition: ModelFieldWorkConditionInput
  ) {
    deleteFieldWork(input: $input, condition: $condition) {
      group_id
      id
      work_type
      display_name
      cultivation_id
      ref_cultivation {
        group_id
        ref_group {
          id
          display_name
          owner_user_id
          plan
          createdAt
          updatedAt
        }
        id
        description
        start_date
        growing_id
        ref_growing {
          id
          lifeform
          family
          species
          name_ja
          family_ja
          link_ja
          name_en
          link_en
          description
          createdAt
          updatedAt
        }
        ref_waterings {
          nextToken
        }
        ref_field_works {
          nextToken
        }
        ref_datasets {
          nextToken
        }
        ref_field_notes {
          nextToken
        }
        createdAt
        updatedAt
      }
      plan_date_start
      plan_date_end
      plan_description
      amount
      unit
      ref_field_notes {
        items {
          group_id
          id
          note_date_time
          note
          image_key
          cultivation_id
          field_work_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCultivation = /* GraphQL */ `
  mutation CreateCultivation(
    $input: CreateCultivationInput!
    $condition: ModelCultivationConditionInput
  ) {
    createCultivation(input: $input, condition: $condition) {
      group_id
      ref_group {
        id
        display_name
        owner_user_id
        ref_owner {
          id
          line_user_id
          email
          username
          sub
          nickname
          profile
          image_key
          stripe_customer_id
          last4
          active_subscription_items
          createdAt
          updatedAt
        }
        plan
        ref_users {
          nextToken
        }
        createdAt
        updatedAt
      }
      id
      description
      start_date
      growing_id
      ref_growing {
        id
        lifeform
        family
        species
        name_ja
        family_ja
        link_ja
        name_en
        link_en
        description
        createdAt
        updatedAt
      }
      ref_waterings {
        items {
          group_id
          id
          display_name
          watering_type
          enabled
          span_min
          span_max
          period_start_time
          period_end_time
          fixed_span
          every
          target_metric
          target_value
          kp
          ki
          kd
          _last_time
          _last_output
          _last_input
          cultivation_id
          createdAt
          updatedAt
        }
        nextToken
      }
      ref_field_works {
        items {
          group_id
          id
          work_type
          display_name
          cultivation_id
          plan_date_start
          plan_date_end
          plan_description
          amount
          unit
          createdAt
          updatedAt
        }
        nextToken
      }
      ref_datasets {
        items {
          group_id
          id
          data_type
          timezone
          description
          cultivation_id
          createdAt
          updatedAt
        }
        nextToken
      }
      ref_field_notes {
        items {
          group_id
          id
          note_date_time
          note
          image_key
          cultivation_id
          field_work_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCultivation = /* GraphQL */ `
  mutation UpdateCultivation(
    $input: UpdateCultivationInput!
    $condition: ModelCultivationConditionInput
  ) {
    updateCultivation(input: $input, condition: $condition) {
      group_id
      ref_group {
        id
        display_name
        owner_user_id
        ref_owner {
          id
          line_user_id
          email
          username
          sub
          nickname
          profile
          image_key
          stripe_customer_id
          last4
          active_subscription_items
          createdAt
          updatedAt
        }
        plan
        ref_users {
          nextToken
        }
        createdAt
        updatedAt
      }
      id
      description
      start_date
      growing_id
      ref_growing {
        id
        lifeform
        family
        species
        name_ja
        family_ja
        link_ja
        name_en
        link_en
        description
        createdAt
        updatedAt
      }
      ref_waterings {
        items {
          group_id
          id
          display_name
          watering_type
          enabled
          span_min
          span_max
          period_start_time
          period_end_time
          fixed_span
          every
          target_metric
          target_value
          kp
          ki
          kd
          _last_time
          _last_output
          _last_input
          cultivation_id
          createdAt
          updatedAt
        }
        nextToken
      }
      ref_field_works {
        items {
          group_id
          id
          work_type
          display_name
          cultivation_id
          plan_date_start
          plan_date_end
          plan_description
          amount
          unit
          createdAt
          updatedAt
        }
        nextToken
      }
      ref_datasets {
        items {
          group_id
          id
          data_type
          timezone
          description
          cultivation_id
          createdAt
          updatedAt
        }
        nextToken
      }
      ref_field_notes {
        items {
          group_id
          id
          note_date_time
          note
          image_key
          cultivation_id
          field_work_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCultivation = /* GraphQL */ `
  mutation DeleteCultivation(
    $input: DeleteCultivationInput!
    $condition: ModelCultivationConditionInput
  ) {
    deleteCultivation(input: $input, condition: $condition) {
      group_id
      ref_group {
        id
        display_name
        owner_user_id
        ref_owner {
          id
          line_user_id
          email
          username
          sub
          nickname
          profile
          image_key
          stripe_customer_id
          last4
          active_subscription_items
          createdAt
          updatedAt
        }
        plan
        ref_users {
          nextToken
        }
        createdAt
        updatedAt
      }
      id
      description
      start_date
      growing_id
      ref_growing {
        id
        lifeform
        family
        species
        name_ja
        family_ja
        link_ja
        name_en
        link_en
        description
        createdAt
        updatedAt
      }
      ref_waterings {
        items {
          group_id
          id
          display_name
          watering_type
          enabled
          span_min
          span_max
          period_start_time
          period_end_time
          fixed_span
          every
          target_metric
          target_value
          kp
          ki
          kd
          _last_time
          _last_output
          _last_input
          cultivation_id
          createdAt
          updatedAt
        }
        nextToken
      }
      ref_field_works {
        items {
          group_id
          id
          work_type
          display_name
          cultivation_id
          plan_date_start
          plan_date_end
          plan_description
          amount
          unit
          createdAt
          updatedAt
        }
        nextToken
      }
      ref_datasets {
        items {
          group_id
          id
          data_type
          timezone
          description
          cultivation_id
          createdAt
          updatedAt
        }
        nextToken
      }
      ref_field_notes {
        items {
          group_id
          id
          note_date_time
          note
          image_key
          cultivation_id
          field_work_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createWatering = /* GraphQL */ `
  mutation CreateWatering(
    $input: CreateWateringInput!
    $condition: ModelWateringConditionInput
  ) {
    createWatering(input: $input, condition: $condition) {
      group_id
      id
      display_name
      watering_type
      enabled
      span_min
      span_max
      period_start_time
      period_end_time
      fixed_span
      every
      target_metric
      target_value
      kp
      ki
      kd
      _last_time
      _last_output
      _last_input
      cultivation_id
      ref_cultivation {
        group_id
        ref_group {
          id
          display_name
          owner_user_id
          plan
          createdAt
          updatedAt
        }
        id
        description
        start_date
        growing_id
        ref_growing {
          id
          lifeform
          family
          species
          name_ja
          family_ja
          link_ja
          name_en
          link_en
          description
          createdAt
          updatedAt
        }
        ref_waterings {
          nextToken
        }
        ref_field_works {
          nextToken
        }
        ref_datasets {
          nextToken
        }
        ref_field_notes {
          nextToken
        }
        createdAt
        updatedAt
      }
      ref_devices {
        items {
          group_id
          device_id
          watering_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateWatering = /* GraphQL */ `
  mutation UpdateWatering(
    $input: UpdateWateringInput!
    $condition: ModelWateringConditionInput
  ) {
    updateWatering(input: $input, condition: $condition) {
      group_id
      id
      display_name
      watering_type
      enabled
      span_min
      span_max
      period_start_time
      period_end_time
      fixed_span
      every
      target_metric
      target_value
      kp
      ki
      kd
      _last_time
      _last_output
      _last_input
      cultivation_id
      ref_cultivation {
        group_id
        ref_group {
          id
          display_name
          owner_user_id
          plan
          createdAt
          updatedAt
        }
        id
        description
        start_date
        growing_id
        ref_growing {
          id
          lifeform
          family
          species
          name_ja
          family_ja
          link_ja
          name_en
          link_en
          description
          createdAt
          updatedAt
        }
        ref_waterings {
          nextToken
        }
        ref_field_works {
          nextToken
        }
        ref_datasets {
          nextToken
        }
        ref_field_notes {
          nextToken
        }
        createdAt
        updatedAt
      }
      ref_devices {
        items {
          group_id
          device_id
          watering_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteWatering = /* GraphQL */ `
  mutation DeleteWatering(
    $input: DeleteWateringInput!
    $condition: ModelWateringConditionInput
  ) {
    deleteWatering(input: $input, condition: $condition) {
      group_id
      id
      display_name
      watering_type
      enabled
      span_min
      span_max
      period_start_time
      period_end_time
      fixed_span
      every
      target_metric
      target_value
      kp
      ki
      kd
      _last_time
      _last_output
      _last_input
      cultivation_id
      ref_cultivation {
        group_id
        ref_group {
          id
          display_name
          owner_user_id
          plan
          createdAt
          updatedAt
        }
        id
        description
        start_date
        growing_id
        ref_growing {
          id
          lifeform
          family
          species
          name_ja
          family_ja
          link_ja
          name_en
          link_en
          description
          createdAt
          updatedAt
        }
        ref_waterings {
          nextToken
        }
        ref_field_works {
          nextToken
        }
        ref_datasets {
          nextToken
        }
        ref_field_notes {
          nextToken
        }
        createdAt
        updatedAt
      }
      ref_devices {
        items {
          group_id
          device_id
          watering_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createDataset = /* GraphQL */ `
  mutation CreateDataset(
    $input: CreateDatasetInput!
    $condition: ModelDatasetConditionInput
  ) {
    createDataset(input: $input, condition: $condition) {
      group_id
      id
      data_type
      timezone
      column_map {
        key
        name
      }
      description
      cultivation_id
      ref_cultivation {
        group_id
        ref_group {
          id
          display_name
          owner_user_id
          plan
          createdAt
          updatedAt
        }
        id
        description
        start_date
        growing_id
        ref_growing {
          id
          lifeform
          family
          species
          name_ja
          family_ja
          link_ja
          name_en
          link_en
          description
          createdAt
          updatedAt
        }
        ref_waterings {
          nextToken
        }
        ref_field_works {
          nextToken
        }
        ref_datasets {
          nextToken
        }
        ref_field_notes {
          nextToken
        }
        createdAt
        updatedAt
      }
      ref_devices {
        items {
          group_id
          device_id
          dataset_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateDataset = /* GraphQL */ `
  mutation UpdateDataset(
    $input: UpdateDatasetInput!
    $condition: ModelDatasetConditionInput
  ) {
    updateDataset(input: $input, condition: $condition) {
      group_id
      id
      data_type
      timezone
      column_map {
        key
        name
      }
      description
      cultivation_id
      ref_cultivation {
        group_id
        ref_group {
          id
          display_name
          owner_user_id
          plan
          createdAt
          updatedAt
        }
        id
        description
        start_date
        growing_id
        ref_growing {
          id
          lifeform
          family
          species
          name_ja
          family_ja
          link_ja
          name_en
          link_en
          description
          createdAt
          updatedAt
        }
        ref_waterings {
          nextToken
        }
        ref_field_works {
          nextToken
        }
        ref_datasets {
          nextToken
        }
        ref_field_notes {
          nextToken
        }
        createdAt
        updatedAt
      }
      ref_devices {
        items {
          group_id
          device_id
          dataset_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteDataset = /* GraphQL */ `
  mutation DeleteDataset(
    $input: DeleteDatasetInput!
    $condition: ModelDatasetConditionInput
  ) {
    deleteDataset(input: $input, condition: $condition) {
      group_id
      id
      data_type
      timezone
      column_map {
        key
        name
      }
      description
      cultivation_id
      ref_cultivation {
        group_id
        ref_group {
          id
          display_name
          owner_user_id
          plan
          createdAt
          updatedAt
        }
        id
        description
        start_date
        growing_id
        ref_growing {
          id
          lifeform
          family
          species
          name_ja
          family_ja
          link_ja
          name_en
          link_en
          description
          createdAt
          updatedAt
        }
        ref_waterings {
          nextToken
        }
        ref_field_works {
          nextToken
        }
        ref_datasets {
          nextToken
        }
        ref_field_notes {
          nextToken
        }
        createdAt
        updatedAt
      }
      ref_devices {
        items {
          group_id
          device_id
          dataset_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createDevice = /* GraphQL */ `
  mutation CreateDevice(
    $input: CreateDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    createDevice(input: $input, condition: $condition) {
      group_id
      id
      display_name
      device_type
      description
      enabled
      certificate
      private_key
      endpoint
      createdAt
      updatedAt
    }
  }
`;
export const updateDevice = /* GraphQL */ `
  mutation UpdateDevice(
    $input: UpdateDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    updateDevice(input: $input, condition: $condition) {
      group_id
      id
      display_name
      device_type
      description
      enabled
      certificate
      private_key
      endpoint
      createdAt
      updatedAt
    }
  }
`;
export const deleteDevice = /* GraphQL */ `
  mutation DeleteDevice(
    $input: DeleteDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    deleteDevice(input: $input, condition: $condition) {
      group_id
      id
      display_name
      device_type
      description
      enabled
      certificate
      private_key
      endpoint
      createdAt
      updatedAt
    }
  }
`;
export const createDeviceDataset = /* GraphQL */ `
  mutation CreateDeviceDataset(
    $input: CreateDeviceDatasetInput!
    $condition: ModelDeviceDatasetConditionInput
  ) {
    createDeviceDataset(input: $input, condition: $condition) {
      group_id
      device_id
      dataset_id
      ref_device {
        group_id
        id
        display_name
        device_type
        description
        enabled
        certificate
        private_key
        endpoint
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateDeviceDataset = /* GraphQL */ `
  mutation UpdateDeviceDataset(
    $input: UpdateDeviceDatasetInput!
    $condition: ModelDeviceDatasetConditionInput
  ) {
    updateDeviceDataset(input: $input, condition: $condition) {
      group_id
      device_id
      dataset_id
      ref_device {
        group_id
        id
        display_name
        device_type
        description
        enabled
        certificate
        private_key
        endpoint
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteDeviceDataset = /* GraphQL */ `
  mutation DeleteDeviceDataset(
    $input: DeleteDeviceDatasetInput!
    $condition: ModelDeviceDatasetConditionInput
  ) {
    deleteDeviceDataset(input: $input, condition: $condition) {
      group_id
      device_id
      dataset_id
      ref_device {
        group_id
        id
        display_name
        device_type
        description
        enabled
        certificate
        private_key
        endpoint
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createDeviceWatering = /* GraphQL */ `
  mutation CreateDeviceWatering(
    $input: CreateDeviceWateringInput!
    $condition: ModelDeviceWateringConditionInput
  ) {
    createDeviceWatering(input: $input, condition: $condition) {
      group_id
      device_id
      watering_id
      ref_device {
        group_id
        id
        display_name
        device_type
        description
        enabled
        certificate
        private_key
        endpoint
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateDeviceWatering = /* GraphQL */ `
  mutation UpdateDeviceWatering(
    $input: UpdateDeviceWateringInput!
    $condition: ModelDeviceWateringConditionInput
  ) {
    updateDeviceWatering(input: $input, condition: $condition) {
      group_id
      device_id
      watering_id
      ref_device {
        group_id
        id
        display_name
        device_type
        description
        enabled
        certificate
        private_key
        endpoint
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteDeviceWatering = /* GraphQL */ `
  mutation DeleteDeviceWatering(
    $input: DeleteDeviceWateringInput!
    $condition: ModelDeviceWateringConditionInput
  ) {
    deleteDeviceWatering(input: $input, condition: $condition) {
      group_id
      device_id
      watering_id
      ref_device {
        group_id
        id
        display_name
        device_type
        description
        enabled
        certificate
        private_key
        endpoint
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSensorData = /* GraphQL */ `
  mutation CreateSensorData(
    $input: CreateSensorDataInput!
    $condition: ModelSensorDataConditionInput
  ) {
    createSensorData(input: $input, condition: $condition) {
      group_id
      dataset_id
      keytime
      ttl
      year
      month
      date
      hour
      datetime
      v0
      v1
      v2
      v3
      v4
      v5
      v6
      v7
      v8
      v9
      createdAt
      updatedAt
    }
  }
`;
export const updateSensorData = /* GraphQL */ `
  mutation UpdateSensorData(
    $input: UpdateSensorDataInput!
    $condition: ModelSensorDataConditionInput
  ) {
    updateSensorData(input: $input, condition: $condition) {
      group_id
      dataset_id
      keytime
      ttl
      year
      month
      date
      hour
      datetime
      v0
      v1
      v2
      v3
      v4
      v5
      v6
      v7
      v8
      v9
      createdAt
      updatedAt
    }
  }
`;
export const deleteSensorData = /* GraphQL */ `
  mutation DeleteSensorData(
    $input: DeleteSensorDataInput!
    $condition: ModelSensorDataConditionInput
  ) {
    deleteSensorData(input: $input, condition: $condition) {
      group_id
      dataset_id
      keytime
      ttl
      year
      month
      date
      hour
      datetime
      v0
      v1
      v2
      v3
      v4
      v5
      v6
      v7
      v8
      v9
      createdAt
      updatedAt
    }
  }
`;
export const createWateringHistory = /* GraphQL */ `
  mutation CreateWateringHistory(
    $input: CreateWateringHistoryInput!
    $condition: ModelWateringHistoryConditionInput
  ) {
    createWateringHistory(input: $input, condition: $condition) {
      group_id
      watering_id
      keytime
      ttl
      year
      month
      date
      hour
      datetime
      span
      createdAt
      updatedAt
    }
  }
`;
export const updateWateringHistory = /* GraphQL */ `
  mutation UpdateWateringHistory(
    $input: UpdateWateringHistoryInput!
    $condition: ModelWateringHistoryConditionInput
  ) {
    updateWateringHistory(input: $input, condition: $condition) {
      group_id
      watering_id
      keytime
      ttl
      year
      month
      date
      hour
      datetime
      span
      createdAt
      updatedAt
    }
  }
`;
export const deleteWateringHistory = /* GraphQL */ `
  mutation DeleteWateringHistory(
    $input: DeleteWateringHistoryInput!
    $condition: ModelWateringHistoryConditionInput
  ) {
    deleteWateringHistory(input: $input, condition: $condition) {
      group_id
      watering_id
      keytime
      ttl
      year
      month
      date
      hour
      datetime
      span
      createdAt
      updatedAt
    }
  }
`;
export const createControlPlane = /* GraphQL */ `
  mutation CreateControlPlane(
    $input: CreateControlPlaneInput!
    $condition: ModelControlPlaneConditionInput
  ) {
    createControlPlane(input: $input, condition: $condition) {
      group_id
      ref_group {
        id
        display_name
        owner_user_id
        ref_owner {
          id
          line_user_id
          email
          username
          sub
          nickname
          profile
          image_key
          stripe_customer_id
          last4
          active_subscription_items
          createdAt
          updatedAt
        }
        plan
        ref_users {
          nextToken
        }
        createdAt
        updatedAt
      }
      id
      display_name
      status
      ssm_activation_code
      ssm_activation_id
      info
      createdAt
      updatedAt
    }
  }
`;
export const updateControlPlane = /* GraphQL */ `
  mutation UpdateControlPlane(
    $input: UpdateControlPlaneInput!
    $condition: ModelControlPlaneConditionInput
  ) {
    updateControlPlane(input: $input, condition: $condition) {
      group_id
      ref_group {
        id
        display_name
        owner_user_id
        ref_owner {
          id
          line_user_id
          email
          username
          sub
          nickname
          profile
          image_key
          stripe_customer_id
          last4
          active_subscription_items
          createdAt
          updatedAt
        }
        plan
        ref_users {
          nextToken
        }
        createdAt
        updatedAt
      }
      id
      display_name
      status
      ssm_activation_code
      ssm_activation_id
      info
      createdAt
      updatedAt
    }
  }
`;
export const deleteControlPlane = /* GraphQL */ `
  mutation DeleteControlPlane(
    $input: DeleteControlPlaneInput!
    $condition: ModelControlPlaneConditionInput
  ) {
    deleteControlPlane(input: $input, condition: $condition) {
      group_id
      ref_group {
        id
        display_name
        owner_user_id
        ref_owner {
          id
          line_user_id
          email
          username
          sub
          nickname
          profile
          image_key
          stripe_customer_id
          last4
          active_subscription_items
          createdAt
          updatedAt
        }
        plan
        ref_users {
          nextToken
        }
        createdAt
        updatedAt
      }
      id
      display_name
      status
      ssm_activation_code
      ssm_activation_id
      info
      createdAt
      updatedAt
    }
  }
`;
export const createUsage = /* GraphQL */ `
  mutation CreateUsage(
    $input: CreateUsageInput!
    $condition: ModelUsageConditionInput
  ) {
    createUsage(input: $input, condition: $condition) {
      group_id
      price_id
      keytime
      year
      month
      date
      datetime
      volume
      createdAt
      updatedAt
    }
  }
`;
export const updateUsage = /* GraphQL */ `
  mutation UpdateUsage(
    $input: UpdateUsageInput!
    $condition: ModelUsageConditionInput
  ) {
    updateUsage(input: $input, condition: $condition) {
      group_id
      price_id
      keytime
      year
      month
      date
      datetime
      volume
      createdAt
      updatedAt
    }
  }
`;
export const deleteUsage = /* GraphQL */ `
  mutation DeleteUsage(
    $input: DeleteUsageInput!
    $condition: ModelUsageConditionInput
  ) {
    deleteUsage(input: $input, condition: $condition) {
      group_id
      price_id
      keytime
      year
      month
      date
      datetime
      volume
      createdAt
      updatedAt
    }
  }
`;
