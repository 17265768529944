import WateringList from './WateringList';
import WateringEdit from './WateringEdit';
import { LocalDrink } from '@material-ui/icons';
import WateringCreate from './WateringCreate';

export default {
  list: WateringList,
  edit: WateringEdit,
  create: WateringCreate,
  icon: LocalDrink,
};
