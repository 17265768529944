import * as React from 'react';
import { useTranslate, useLocale, useSetLocale, Title } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import 'moment-duration-format';
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import { ja } from 'date-fns/locale';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { format } from 'd3-format';
import moment from 'moment';
import _ from 'underscore';
// @ts-ignore
import { TimeSeries, TimeRange, avg, percentile, median } from 'pondjs';

import {
  ChartContainer,
  ChartRow,
  Charts,
  YAxis,
  LineChart,
  Baseline,
  Resizable,
  AreaChart,
  styler,
  Brush,
  LabelAxis,
  ValueAxis,
  BarChart,
  Legend,
} from 'react-timeseries-charts';
import { getDataProvider } from '../dataProvider/combined';
import { queryWateringHistory } from '../dataProvider/customRest';
import Select from 'react-select';

// React Timeseries Charts
// http://software.es.net/react-timeseries-charts/#/guide/intro

const baselineStyles = {
  base: {
    stroke: 'steelblue',
    opacity: 0.5,
    width: 0.2,
  },
};

// d3 formatter to display the base with one decimal place
const baseFormat = format('.2f');

class WateringHistory extends React.Component<any, any> {
  constructor(props) {
    super(props);

    const pathExists = this.props.match.params.wateringId !== undefined;
    console.info(`pathExists: ${pathExists}`);
    const wateringId = pathExists ? this.props.match.params.wateringId : null;
    const selectedWatering = pathExists
      ? {
          label: wateringId,
          value: wateringId,
        }
      : {};
    const now = new Date();
    const selectionRange = {
      startDate: addDays(now, -7),
      endDate: now,
      key: 'selectionRange',
    };
    const waterings = [];

    // @ts-ignore
    const initialRange = new TimeRange([75 * 60 * 1000, 125 * 60 * 1000]);

    // Storage for all the data dataSetting
    const maxColumnNum = 10;
    const dataSetting = Object.fromEntries(
      _.range(maxColumnNum).map((i) => {
        return [`v${i}`, {}];
      })
    );

    this.state = {
      selectedWatering,
      waterings,
      selectionRange,
      ready: false,
      loading: false,
      mode: 'dataSetting',
      dataSetting,
      series: null,
      columnNames: [],
      tracker: null,
      timerange: initialRange,
      brushrange: initialRange,
      style: null,
    };
    this.handleSelectWatering = this.handleSelectWatering.bind(this);
    this.handleSelectDateRange = this.handleSelectDateRange.bind(this);
  }

  async getWaterings(): Promise<Array<object>> {
    const dp = getDataProvider('waterings');
    const res = await dp.getList('waterings', {
      filter: null,
      pagination: { page: 1, perPage: 10000 },
      sort: { field: 'id', order: 'asc' },
    });
    return res.data;
  }

  async updateWateringHistory(wateringId: string, start: number, end: number) {
    if (wateringId === null) return;
    queryWateringHistory(wateringId, start, end)
      .then((sensorData) => {
        // @ts-ignore
        const { dataSetting, waterings } = this.state;
        const colors = ['#003f5c', '#58508d', '#bc5090', '#ff6361', '#ffa600'];
        const watering = waterings.find((ds) => ds.id === wateringId);
        const columnNames = sensorData.columns.filter((c) => c !== 'time');
        const style = styler(
          columnNames.map((c, i) => {
            return { key: c, color: colors[i % colors.length] };
          })
        );
        const series = new TimeSeries(sensorData);

        // Make the TimeSeries here from the points collected above
        for (let columnName of columnNames) {
          // Some simple statistics for each channel
          dataSetting[columnName] = {
            // @ts-ignore
            avg: series.avg(columnName),
            // @ts-ignore
            max: series.max(columnName),
            // @ts-ignore
            min: series.min(columnName),
            show: true,
            units: '',
            label: 'Watering time (sec)',
          };
        }

        // Min and max time constraints for pan/zoom, along with the smallest timerange
        // the user can zoom into. These are passed into the ChartContainers when we come to
        // rendering.
        // @ts-ignore
        const timerange = series.range();
        if (timerange) {
          const minTime = timerange.begin();
          const maxTime = timerange.end();
          const minDuration = 10 * 60 * 1000;
          this.setState({
            ready: true,
            dataSetting,
            minTime,
            maxTime,
            minDuration,
            style,
            series,
            timerange,
            brushrange: timerange,
            columnNames,
          });
        } else {
          alert('No data found');
          this.setState({ ready: false, dataSetting });
        }
      })
      .catch((e) => console.error(e))
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  getDefaultStartEnd() {
    const end = new Date().getTime();
    const start = end - 24 * 60 * 60 * 1000;
    return { start, end };
  }

  getCurrentStartEnd() {
    const { selectionRange } = this.state;
    const start = Date.parse(selectionRange.startDate);
    const end = Date.parse(selectionRange.endDate);
    return { start, end };
  }

  async componentDidMount() {
    const waterings = await this.getWaterings();
    // console.info(`waterings: ${JSON.stringify(waterings)}`)
    this.setState({ waterings });
    // const {start, end} = this.getDefaultStartEnd();
    // await this.updateWateringHistory(start, end);
  }

  handleTrackerChanged = (t) => {
    this.setState({ tracker: t });
  };

  // Handles when the brush changes the timerange
  handleTimeRangeChange = (timerange) => {
    if (timerange) {
      this.setState({ timerange, brushrange: timerange });
    } else {
      this.setState({ timerange: null, brushrange: null });
    }
  };

  handleChartResize = (width) => {
    this.setState({ width });
  };

  handleActiveChange = (columnName) => {
    const dataSetting = this.state.dataSetting;
    dataSetting[columnName].show = !dataSetting[columnName].show;
    this.setState({ dataSetting });
  };

  renderChart = () => {
    if (this.state.mode === 'dataSetting') {
      return this.renderBarChart();
    }
    return <div>No chart</div>;
  };

  renderBarChart = () => {
    const {
      timerange,
      columnNames,
      dataSetting,
      maxTime,
      minTime,
      minDuration,
      series,
    } = this.state;
    const rows: any[] = [];

    for (let columnName of columnNames) {
      const charts: any[] = [];
      let displaySeries = series;
      // Get the value at the current tracker position for the ValueAxis
      let value = '--';
      if (this.state.tracker) {
        const approx =
          (+this.state.tracker - +timerange.begin()) /
          (+timerange.end() - +timerange.begin());
        const ii = Math.floor(approx * series.size());
        const i = series.bisect(new Date(this.state.tracker), ii);
        const v = i < series.size() ? series.at(i).get(columnName) : null;
        if (v) {
          // @ts-ignore
          value = baseFormat(v);
        }
      }

      rows.push(
        <ChartRow
          height="100"
          visible={dataSetting[columnName].show}
          key={`row-${columnName}`}>
          <YAxis
            id="traffic"
            label="Traffic In (B)"
            min={0}
            max={10000}
            width="70"
          />
          <Charts>
            <BarChart
              axis="traffic"
              style={this.state.style}
              columns={['in']}
              series={displaySeries}
              // info={infoValues}
              infoTimeFormat={(index) =>
                moment(index.begin()).format("Do MMM 'YY")
              }
              highlighted={this.state.highlight}
              onHighlightChange={(highlight) => this.setState({ highlight })}
              selected={this.state.selection}
              onSelectionChange={(selection) => this.setState({ selection })}
            />
          </Charts>
        </ChartRow>
      );
    }

    return (
      <ChartContainer
        timeRange={this.state.timerange}
        format="%H:%M"
        showGrid={true}
        // enablePanZoom
        maxTime={maxTime}
        minTime={minTime}
        minDuration={minDuration}
        trackerPosition={this.state.tracker}
        onTimeRangeChanged={this.handleTimeRangeChange}
        onChartResize={(width) => this.handleChartResize(width)}
        onTrackerChanged={this.handleTrackerChanged}>
        {rows}
      </ChartContainer>
    );
  };

  renderMode = () => {
    const linkStyle = {
      fontWeight: 600,
      color: 'grey',
      cursor: 'default',
    };

    const linkStyleActive = {
      color: 'steelblue',
      cursor: 'pointer',
    };

    return (
      <div className="col-md-6" style={{ fontSize: 14, color: '#777' }}>
        <span
          style={
            this.state.mode !== 'dataSetting' ? linkStyleActive : linkStyle
          }
          onClick={() => this.setState({ mode: 'dataSetting' })}>
          Time-series sensor data
        </span>
      </div>
    );
  };

  renderDataSelection = () => {
    const { selectedWatering, waterings, selectionRange } = this.state;
    const wateringOptions = waterings.map((d) => ({
      label: d.display_name,
      value: d.id,
    }));
    // console.info(`wateringOptions: ${JSON.stringify(wateringOptions)}`)
    return (
      <div>
        <span>Select watering</span>
        <Select
          options={wateringOptions}
          onChange={this.handleSelectWatering}
          value={selectedWatering}
          menuShouldScrollIntoView={true}
        />
        <DateRangePicker
          ranges={[selectionRange]}
          onChange={this.handleSelectDateRange}
          moveRangeOnFirstSelection={false}
          locale={ja}
        />
      </div>
    );
  };

  /**
   * ranges: {"selection":{"startDate":"2020-12-14T15:00:00.000Z","endDate":"2020-12-14T15:00:00.000Z","key":"selection"}}
   * @param ranges
   */
  handleSelectDateRange = async (ranges) => {
    const { selectedWatering } = this.state;
    const wateringId = selectedWatering.value;
    const start = Date.parse(ranges.selectionRange.startDate);
    const end = Date.parse(ranges.selectionRange.endDate);
    // console.info(`newRange: ${JSON.stringify(ranges.selectionRange)}`)
    this.setState({ selectionRange: ranges.selectionRange });
    if (wateringId !== undefined && start < end) {
      console.info(`wateringId: ${wateringId}, start: ${start}, end: ${end}`);
      this.setState({ loading: true });
      await this.updateWateringHistory(wateringId, start, end);
    }
  };

  handleSelectWatering = async (selectedOption: any, actionMeta: any) => {
    if (!actionMeta.action || actionMeta.action !== 'select-option') return;
    if (!selectedOption) return;
    this.setState({ selectedWatering: selectedOption });
    const wateringId = selectedOption.value;
    const { start, end } = this.getCurrentStartEnd();
    this.setState({ loading: true });
    await this.updateWateringHistory(wateringId, start, end);
  };

  render() {
    const { ready, loading, dataSetting, columnNames } = this.state;
    // handling
    if (!ready && !loading) {
      return <div>{this.renderDataSelection()}</div>;
    } else if (!ready && loading) {
      return (
        <div>
          {this.renderDataSelection()}
          <div>
            <div className="loader">Loading...</div>
          </div>
        </div>
      );
    }
    const chartStyle = {
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#DDD',
      paddingTop: 10,
      marginBottom: 10,
    };

    // Generate the legend
    const legend = columnNames.map((columnName) => ({
      key: columnName,
      label: dataSetting[columnName].label,
      disabled: !dataSetting[columnName].show,
    }));

    return (
      <div>
        {this.renderDataSelection()}
        <div className="row">{this.renderMode()}</div>
        <div className="row">
          <div className="col-md-12">
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Legend
              type={this.state.mode === 'rollup' ? 'swatch' : 'line'}
              style={this.state.style}
              categories={legend}
              onSelectionChange={this.handleActiveChange}
            />
          </div>

          <div className="col-md-6">
            {this.state.tracker
              ? // @ts-ignore
                `${new Date(this.state.tracker).toISOString()}`
              : '-:--:--'}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12" style={chartStyle}>
            <Resizable>
              {ready ? this.renderChart() : <div>Loading.....</div>}
            </Resizable>
          </div>
        </div>
      </div>
    );
  }
}

export default WateringHistory;
