import { DataProvider as DataProviderInterface } from 'ra-core';
import { DataProvider, DataProviderOptions, Operations } from './DataProvider';

export function buildDataProvider(
  operations: Operations,
  options?: DataProviderOptions
): DataProviderInterface {
  const dataProvider = new DataProvider(operations, options);
  return {
    // @ts-ignore
    getList: dataProvider.getList,
    // @ts-ignore
    getOne: dataProvider.getOne,
    // @ts-ignore
    getMany: dataProvider.getMany,
    // @ts-ignore
    getManyReference: dataProvider.getManyReference,
    // @ts-ignore
    create: dataProvider.create,
    // @ts-ignore
    update: dataProvider.update,
    updateMany: dataProvider.updateMany,
    // @ts-ignore
    delete: dataProvider.delete,
    deleteMany: dataProvider.deleteMany,
  };
}
