import * as React from 'react';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import {
  FilterHdrTwoTone,
  HouseTwoTone,
  DataUsageTwoTone,
  SupervisorAccountTwoTone,
  LeakAdd,
  History,
  PhotoLibrary,
} from '@material-ui/icons';
import { useMediaQuery, Theme } from '@material-ui/core';
import { useTranslate, DashboardMenuItem, MenuItemLink } from 'react-admin';

// resources
// cultivations
import cultivations from '../resources/cultivations';
import growings from '../resources/growings';
import fieldWorks from '../resources/fieldWorks';
import fieldNotes from '../resources/fieldNotes';
// control
import waterings from '../resources/waterings';
import devices from '../resources/devices';
import datasets from '../resources/datasets';
// groups
import groups from '../resources/groups';
import users from '../resources/users';

// data
// import

import SubMenu from './SubMenu';
import { AppState } from '../types';

type MenuName =
  | 'menuControlManagement'
  | 'menuAdminManagement'
  | 'menuDataManagement'
  | 'menuFieldManagement';

interface Props {
  dense: boolean;
  logout: () => void;
  onMenuClick: () => void;
}

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
  const [state, setState] = useState({
    menuFieldManagement: false,
    menuControlManagement: false,
    menuDataManagement: false,
    menuAdminManagement: false,
  });
  const translate = useTranslate();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs')
  );
  const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
  useSelector((state: AppState) => state.theme); // force rerender on theme change

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <div>
      <br />{' '}
      {/*<DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />*/}
      {/*フィールド管理*/}
      <SubMenu
        handleToggle={() => handleToggle('menuFieldManagement')}
        isOpen={state.menuFieldManagement}
        sidebarIsOpen={open}
        name="pos.menu.field"
        icon={<FilterHdrTwoTone />}
        dense={dense}>
        <MenuItemLink
          to={`/cultivations`}
          primaryText={translate(`resources.cultivations.name`, {
            smart_count: 2,
          })}
          leftIcon={<cultivations.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/fieldWorks`}
          primaryText={translate(`resources.fieldWorks.name`, {
            smart_count: 2,
          })}
          leftIcon={<fieldWorks.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/fieldNotes`}
          primaryText={translate(`resources.fieldNotes.name`, {
            smart_count: 2,
          })}
          leftIcon={<fieldNotes.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/growings`}
          primaryText={translate(`resources.growings.name`, {
            smart_count: 2,
          })}
          leftIcon={<growings.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
      {/*制御管理*/}
      <SubMenu
        handleToggle={() => handleToggle('menuControlManagement')}
        isOpen={state.menuControlManagement}
        sidebarIsOpen={open}
        name="pos.menu.control"
        icon={<HouseTwoTone />}
        dense={dense}>
        <MenuItemLink
          to={`/waterings`}
          primaryText={translate(`resources.waterings.name`, {
            smart_count: 2,
          })}
          leftIcon={<waterings.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/datasets`}
          primaryText={translate(`resources.datasets.name`, {
            smart_count: 2,
          })}
          leftIcon={<datasets.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/devices`}
          primaryText={translate(`resources.devices.name`, {
            smart_count: 2,
          })}
          leftIcon={<devices.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
      {/*データ管理*/}
      <SubMenu
        handleToggle={() => handleToggle('menuDataManagement')}
        isOpen={state.menuDataManagement}
        sidebarIsOpen={open}
        name="pos.menu.data"
        icon={<DataUsageTwoTone />}
        dense={dense}>
        <MenuItemLink
          to={`/data/sensorData`}
          primaryText={translate(`data.sensorData.name`, {
            smart_count: 2,
          })}
          leftIcon={<LeakAdd />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/data/wateringHistory`}
          primaryText={translate(`data.wateringHistory.name`, {
            smart_count: 2,
          })}
          leftIcon={<History />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/data/imageData`}
          primaryText={translate(`data.imageData.name`, {
            smart_count: 2,
          })}
          leftIcon={<PhotoLibrary />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
      {/*グループ管理*/}
      <SubMenu
        handleToggle={() => handleToggle('menuAdminManagement')}
        isOpen={state.menuAdminManagement}
        sidebarIsOpen={open}
        name="pos.menu.admin"
        icon={<SupervisorAccountTwoTone />}
        dense={dense}>
        <MenuItemLink
          to={`/groups`}
          primaryText={translate(`resources.groups.name`, {
            smart_count: 2,
          })}
          leftIcon={<groups.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/users`}
          primaryText={translate(`resources.users.name`, {
            smart_count: 2,
          })}
          leftIcon={<users.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
      {isXSmall && (
        <MenuItemLink
          to="/configuration"
          primaryText={translate('pos.configuration')}
          leftIcon={<SettingsIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}
      {isXSmall && logout}
    </div>
  );
};

export default Menu;
