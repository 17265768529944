import { WorkType, WateringType, DeviceType } from '../API';

/**
 * for select-input
 * https://marmelab.com/react-admin/Inputs.html#selectinput
 */
export function getWorkTypeOptions(): Array<{ id: string; name: string }> {
  return Object.entries(WorkType).map((o) => {
    return { id: o[0], name: o[1] };
  });
}

export function getWateringTypeOptions(): Array<{ id: string; name: string }> {
  return Object.entries(WateringType).map((o) => {
    return { id: o[0], name: o[1] };
  });
}

export function getDeviceTypeOptions(): Array<{ id: string; name: string }> {
  return Object.entries(DeviceType).map((o) => {
    return { id: o[0], name: o[1] };
  });
}
