export const keyChoices = Array.from({ length: 10 }, (x, i) => i).map((i) => {
  return { id: 'v' + i, name: 'v' + i };
});

export function formatDate(dt: Date) {
  const y = dt.getFullYear();
  const m = ('00' + (dt.getMonth() + 1)).slice(-2);
  const d = ('00' + dt.getDate()).slice(-2);
  return y + '-' + m + '-' + d;
}
