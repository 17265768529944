import UserList from './UserList';
import UserShow from './UserShow';
import { Person } from '@material-ui/icons';
import UserEdit from './UserEdit';

export default {
  list: UserList,
  show: UserShow,
  edit: UserEdit,
  icon: Person,
};
