import { v4 as uuidv4 } from 'uuid';
import Amplify, { Auth } from 'aws-amplify';
import awsExports from '../../aws-exports';

Amplify.configure(awsExports);

export interface UserInfo {
  username: string;
  userPoolId: string;
  clientId: string;
  endpoint: string;
  sub: string;
  groups: Array<string>;
  roles: Array<string>;
  phone_number: string;
  email: string;
}

export async function getUserInfo(): Promise<UserInfo> {
  const userInfo = await Auth.currentUserPoolUser();
  const userAttrs = userInfo.signInUserSession.idToken.payload;
  return {
    username: userInfo.username,
    userPoolId: userInfo.pool.userPoolId,
    clientId: userInfo.pool.clientId,
    endpoint: userInfo.client.endpoint,
    sub: userAttrs.sub,
    groups: userAttrs['cognito:groups'],
    roles: userAttrs['cognito:roles'],
    phone_number: userAttrs.phone_number,
    email: userAttrs.email,
  } as UserInfo;
}

export async function listBelongingGroups(): Promise<Array<string>> {
  const userInfo = await getUserInfo();
  const groups = userInfo.groups;
  return !groups ? [] : groups;
}

export function findGroupId(groups: Array<string>): string {
  const currentGroup = localStorage.getItem('/morimoritor/currentGroup');
  if (currentGroup && groups.find((g) => g === currentGroup))
    return currentGroup;
  return groups[0];
}

export async function getGroupId(): Promise<string> {
  const groups = await listBelongingGroups();
  if (!groups || groups.length === 0) return 'public';
  return findGroupId(groups);
}

export async function getUserId(): Promise<string> {
  const userInfo = await getUserInfo();
  return userInfo.username;
}

export async function getExtraParams(): Promise<{ group_id: string }> {
  const groupId = await getGroupId();
  return { group_id: groupId };
}

export function expandItems(record: { [key: string]: any }): {
  [key: string]: any;
} {
  return Object.fromEntries(
    Object.entries(record).map(([k, v]) => {
      return typeof v === 'object' && v !== null && 'items' in v
        ? [k, v.items]
        : [k, v];
    })
  );
}

export function filterItems(
  record: { [key: string]: any },
  queryVariables: object
): boolean {
  if (!queryVariables || queryVariables === {}) return true;
  const a = Object.entries(queryVariables).map(([k, v]) => {
    if (!record[k]) return true;
    return v === record[k];
  });
  // console.info(`q ${JSON.stringify(queryVariables)}`)
  // console.info(`a: ${JSON.stringify(a)}`)
  return a.every((b) => b);
}

export function deleteRef(record: { [key: string]: any }): {
  [key: string]: any;
} {
  return Object.fromEntries(
    Object.entries(record).filter(([k, v]) => {
      return !k.startsWith('ref_');
    })
  );
}

export function makeQueryNameSingle(resource: string): string {
  return resource.charAt(0).toUpperCase() + resource.slice(1, -1);
}

export function makeQueryNamePlural(resource: string): string {
  return resource.charAt(0).toUpperCase() + resource.slice(1);
}

export function appendId(data: object): object {
  // @ts-ignore
  if (!data.id) {
    const id = uuidv4();
    return { ...data, id };
  } else {
    return data;
  }
}

/**
 * 若干トリッキーだが、allowEmptyを指定したものについて、nullが入るので、それを文字列化する。
 * amplifyでconnectionはるためにはID!であるひつようがあり、nullが入れられないため
 * @param data
 */
export function stringifyNull(data: object): object {
  return Object.fromEntries(
    Object.entries(data).map(([k, v]) => {
      if (v === null) return [k, 'null'];
      return [k, v];
    })
  );
}
