import * as React from 'react';
import { FieldProps } from 'react-admin';
import { AmplifyS3Image } from '@aws-amplify/ui-react';
import { AccessLevel } from '@aws-amplify/ui-components';
import { FC } from 'react';
import awsconfig from '../aws-exports';
import Amplify, { Storage } from 'aws-amplify';

Amplify.configure(awsconfig);
Storage.configure({ level: AccessLevel.Protected });

export const SinglePhotoField: FC<FieldProps> = ({ record }) => {
  // console.info(`record: ${JSON.stringify(record)}`)
  if (!record || !record.image_key) return null;
  return (
    <AmplifyS3Image imgKey={record.image_key} level={AccessLevel.Protected} />
  );
};
