import FieldNoteList from './FieldNoteList';
import FieldNoteEdit from './FieldNoteEdit';
import { Notes } from '@material-ui/icons';
import FieldNoteCreate from './FieldNoteCreate';

export default {
  list: FieldNoteList,
  edit: FieldNoteEdit,
  create: FieldNoteCreate,
  icon: Notes,
};
