import * as React from 'react';
import { FC } from 'react';
import { List, ListProps, Datagrid, TextField, DateField } from 'react-admin';

const CultivationList: FC<ListProps> = (props) => (
  <List {...props} perPage={25} title="Cultivations">
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <DateField source="start_date" />
      <TextField source="ref_growing.name_ja" label="和名" />
      <TextField source="ref_growing.family_ja" label="科名" />
    </Datagrid>
  </List>
);

export default CultivationList;
