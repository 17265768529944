import * as React from 'react';
import { useTranslate, useLocale, useSetLocale, Title } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { MySpinner } from '../component/MySpinner';

const useStyles = makeStyles({
  label: { width: '10em', display: 'inline-block' },
  button: { margin: '1em' },
});

const ImageData = () => {
  const classes = useStyles();
  return <MySpinner />;
};

export default ImageData;
