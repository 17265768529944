import DeviceList from './DeviceList';
import DeviceEdit from './DeviceEdit';
import { Devices } from '@material-ui/icons';
import DeviceCreate from './DeviceCreate';

export default {
  list: DeviceList,
  edit: DeviceEdit,
  create: DeviceCreate,
  icon: Devices,
};
